import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

// Icons
import DevicesIcon from '@mui/icons-material/Devices';
import WifiIcon from '@mui/icons-material/Wifi';
import SimCardIcon from '@mui/icons-material/SimCard';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import MapIcon from '@mui/icons-material/Map';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import TableChartIcon from '@mui/icons-material/TableChart';

import axios from '../../utils/axios.utils';

// Chart.js / react-chartjs-2
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  PointElement,
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  PointElement,
);

interface CompanyDistribution {
  company_name: string;
  device_count: number;
}

export interface EdgelinkDashboardData {
  timestamp: string;
  total_devices: number;
  device_check_in_distribution: {
    [range: string]: number;
  };
  subscription_status: {
    active: number;
    inactive: number;
    expiring_this_year: number;
    expiring_next_year: number;
  };
  connectivity: {
    cellular: number;
    wifi: number;
  };
  alerts: {
    critical: number;
    warning: number;
    info: number;
    most_common_issue: string;
  };
  geographical_distribution: {
    [state: string]: number;
  };
  company_distribution: {
    secondary?: CompanyDistribution[];
    tertiary?: CompanyDistribution[];
  };
  device_type_distribution: {
    [type: string]: number;
  };
  empty_status_distribution: {
    not_empty: number;
    empty: number;
  };
  battery_distribution?: {
    [bin: string]: number;
  };
  recent_checkin_trend?: { date: string; count: number }[];
}

const EdgelinkDashboardPage: React.FC = () => {
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState<EdgelinkDashboardData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch dashboard data on mount using axios
  useEffect(() => {
    axios
      .get<any, any>('/api/user/edgelink/companies/0/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        if (response.data.success) {
          setDashboardData(response.data.results);
        } else {
          console.error('Unexpected response structure:', response.data);
          setError(response.data.message || 'Unexpected response structure');
        }
      })
      .catch((error) => {
        console.error('Error fetching dashboard data:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }
  if (!dashboardData) return null;

  // Utility to format the timestamp nicely
  const formatDate = (ts: string) => {
    if (!ts) return '';
    const date = new Date(ts);
    return date.toLocaleString(undefined, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  // 1) Device Check-In Distribution (Line Chart)
  const checkInDist = dashboardData.device_check_in_distribution || {};
  const checkInLabels = Object.keys(checkInDist);
  const checkInValues = Object.values(checkInDist);
  const checkInData = {
    labels: checkInLabels,
    datasets: [
      {
        label: 'Device Check-Ins',
        data: checkInValues,
        borderColor: 'rgba(54, 162, 235, 0.8)',
        backgroundColor: 'rgba(54, 162, 235, 0.3)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        fill: true,
      },
    ],
  };
  const checkInOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: 'bottom' as const } },
    scales: { y: { beginAtZero: true, stepSize: 5 } },
  };

  // 2) Subscription Status (Doughnut)
  const subStatus = dashboardData.subscription_status || {
    active: 0,
    inactive: 0,
    expiring_this_year: 0,
    expiring_next_year: 0,
  };
  const subLabels = ['Active', 'Inactive', 'Expiring This Year', 'Expiring Next Year'];
  const subValues = [subStatus.active, subStatus.inactive, subStatus.expiring_this_year, subStatus.expiring_next_year];
  const subData = {
    labels: subLabels,
    datasets: [
      {
        data: subValues,
        backgroundColor: [
          'rgba(75, 192, 192, 0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(153, 102, 255, 0.7)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: 'bottom' as const } },
  };

  // 3) Empty Status Distribution (Doughnut)
  const emptyStatus = dashboardData.empty_status_distribution || { not_empty: 0, empty: 0 };
  const emptyLabels = ['Not Empty', 'Empty'];
  const emptyValues = [emptyStatus.not_empty, emptyStatus.empty];
  const emptyData = {
    labels: emptyLabels,
    datasets: [
      {
        data: emptyValues,
        backgroundColor: ['rgba(75, 192, 192, 0.7)', 'rgba(255, 99, 132, 0.7)'],
      },
    ],
  };

  // 4) Device Type Distribution (Doughnut)
  const deviceTypeDist = dashboardData.device_type_distribution || {};
  const deviceTypeLabels = Object.keys(deviceTypeDist);
  const deviceTypeValues = Object.values(deviceTypeDist);
  const deviceTypeData = {
    labels: deviceTypeLabels,
    datasets: [
      {
        data: deviceTypeValues,
        backgroundColor: ['rgba(54, 162, 235, 0.7)', 'rgba(255, 99, 132, 0.7)'],
      },
    ],
  };

  // 5) Battery Distribution (Doughnut) – if no data, fallback to "No Data"
  const batteryDist = dashboardData.battery_distribution || {};
  const batteryLabels = Object.keys(batteryDist).length > 0 ? Object.keys(batteryDist) : ['No Data'];
  const batteryValues = Object.keys(batteryDist).length > 0 ? Object.values(batteryDist) : [0];
  const batteryData = {
    labels: batteryLabels,
    datasets: [
      {
        data: batteryValues,
        backgroundColor: [
          'rgba(255, 159, 64, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(255, 205, 86, 0.7)',
          'rgba(201, 203, 207, 0.7)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // 6) Company Distribution – Secondary & Tertiary
  const secondaryDist: CompanyDistribution[] = dashboardData.company_distribution?.secondary || [];
  const tertiaryDist: CompanyDistribution[] = dashboardData.company_distribution?.tertiary || [];
  const secondaryLabels = secondaryDist.map((c) => c.company_name);
  const secondaryValues = secondaryDist.map((c) => c.device_count);
  const secondaryData = {
    labels: secondaryLabels,
    datasets: [
      {
        label: 'Secondary Distribution',
        data: secondaryValues,
        backgroundColor: 'rgba(153, 102, 255, 0.7)',
      },
    ],
  };
  const tertiaryLabels = tertiaryDist.map((c) => c.company_name);
  const tertiaryValues = tertiaryDist.map((c) => c.device_count);
  const tertiaryData = {
    labels: tertiaryLabels,
    datasets: [
      {
        label: 'Tertiary Distribution',
        data: tertiaryValues,
        backgroundColor: 'rgba(255, 159, 64, 0.7)',
      },
    ],
  };
  const companyOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: 'bottom' as const } },
    scales: { x: { beginAtZero: true, stepSize: 5 } },
  };

  // 7) Geographical Distribution (Horizontal Bar)
  const geoDist = dashboardData.geographical_distribution || {};
  const stateLabels = Object.keys(geoDist);
  const stateValues = Object.values(geoDist);
  const geoData = {
    labels: stateLabels,
    datasets: [
      {
        label: 'Devices per State',
        data: stateValues,
        backgroundColor: 'rgba(255, 159, 64, 0.7)',
      },
    ],
  };
  const geoOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: 'bottom' as const } },
    scales: { x: { beginAtZero: true, stepSize: 5 } },
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      {/* HEADER: Title */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Box>
          <Typography variant="h1" gutterBottom>
            EdgeLink Dashboard
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ mb: 3 }} />

      {/* Top Summary Row – three cards extend full width */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {/* Total Devices */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              borderLeft: '6px solid #1976d2',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: 150,
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <DevicesIcon fontSize="large" color="primary" />
                <Box>
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    Total Devices
                  </Typography>
                  <Typography variant="h5">{dashboardData.total_devices}</Typography>
                </Box>
              </Stack>
              {/* Replaced Button with three header icons */}
              <Stack direction="row" spacing={2} sx={{ alignSelf: 'flex-start' }}>
                <Tooltip title="Map View">
                  <IconButton onClick={() => history.push('/edgelink/devices/map')}>
                    <MapIcon fontSize="medium" color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Card View">
                  <IconButton onClick={() => history.push('/edgelink/devices/cards')}>
                    <ViewAgendaIcon fontSize="medium" color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Table View">
                  <IconButton onClick={() => history.push('/edgelink/devices/table')}>
                    <TableChartIcon fontSize="medium" color="primary" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        </Grid>

        {/* Connectivity */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, borderLeft: '6px solid #0288d1', borderRadius: 2, minHeight: 150 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SimCardIcon fontSize="large" sx={{ color: '#0288d1' }} />
              <Box>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  Cellular
                </Typography>
                <Typography variant="h6">{dashboardData.connectivity.cellular}</Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <WifiIcon fontSize="large" sx={{ color: '#0288d1' }} />
              <Box>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  WiFi
                </Typography>
                <Typography variant="h6">{dashboardData.connectivity.wifi}</Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>

        {/* 3) Alerts
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 2, borderLeft: '6px solid #d32f2f', borderRadius: 2, minHeight: 150 }}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Alerts
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
              <ErrorOutlineIcon sx={{ color: '#d32f2f' }} />
              <Typography variant="body2">
                <strong>Critical:</strong> {dashboardData.alerts.critical}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
              <WarningAmberIcon sx={{ color: '#ed6c02' }} />
              <Typography variant="body2">
                <strong>Warning:</strong> {dashboardData.alerts.warning}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
              <InfoOutlinedIcon sx={{ color: '#0288d1' }} />
              <Typography variant="body2">
                <strong>Info:</strong> {dashboardData.alerts.info}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <BatteryAlertIcon color="error" />
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                Most Common: {dashboardData.alerts.most_common_issue}
              </Typography>
            </Stack>
          </Paper>
        </Grid> */}

        {/* 4) Subscriptions (from primary devices) */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, borderLeft: '6px solid #7b1fa2', borderRadius: 2, minHeight: 150 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <CardMembershipIcon sx={{ color: '#7b1fa2' }} fontSize="large" />
              <Box>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                  Subscriptions
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">
                <strong>Active:</strong> {dashboardData.subscription_status.active}
              </Typography>
              <Typography variant="body2">
                <strong>Inactive:</strong> {dashboardData.subscription_status.inactive}
              </Typography>
              <Typography variant="body2">
                <strong>Expiring This Year:</strong> {dashboardData.subscription_status.expiring_this_year}
              </Typography>
              <Typography variant="body2">
                <strong>Expiring Next Year:</strong> {dashboardData.subscription_status.expiring_next_year}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Row with line chart (Device Check-In Distribution) + Geographical Distribution side by side */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {/* Device Check-In Distribution (Line Chart) */}
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Device Check-In Distribution
              </Typography>
              <Box height={300}>
                <Line data={checkInData} options={checkInOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Geographical Distribution (Bar Chart) */}
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Geographical Distribution
              </Typography>
              <Box height={300}>
                <Bar data={geoData} options={geoOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Row with two doughnut charts: Battery Distribution and Empty Status Distribution */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Battery Distribution
              </Typography>
              <Box height={300} display="flex" justifyContent="center" alignItems="center">
                <Doughnut data={batteryData} options={doughnutOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Empty Status Distribution
              </Typography>
              <Box height={300} display="flex" justifyContent="center" alignItems="center">
                <Doughnut data={emptyData} options={doughnutOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Row with two doughnut charts: Device Type Distribution and Subscription Status */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Device Type Distribution
              </Typography>
              <Box height={300} display="flex" justifyContent="center" alignItems="center">
                <Doughnut data={deviceTypeData} options={doughnutOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Subscription Status
              </Typography>
              <Box height={300} display="flex" justifyContent="center" alignItems="center">
                <Doughnut data={subData} options={doughnutOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Row with Secondary + Tertiary company distributions side by side */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Secondary Company Distribution
              </Typography>
              <Box height={300}>
                <Bar data={secondaryData} options={companyOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={4} sx={{ borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Tertiary Company Distribution
              </Typography>
              <Box height={300}>
                <Bar data={tertiaryData} options={companyOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EdgelinkDashboardPage;
