import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Grid,
  Stack,
  Tooltip,
  Paper,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../../utils/axios.utils';

// Shared Components (same directory)
import GenericFormDialog from '../../sharedComponents/GenericFormDialog';

// Form (one directory back, in "forms")
import UserFormContent from '../forms/UserFormContent';

interface CompanyUser {
  edgelink_user_id: number;
  email: string;
  full_name: string;
  phone_number: string;
  edgelink_role: number | null;
  status: boolean;
  created_by: string | null;
  created_at: string | null;
  updated_by: string | null;
}

interface UserFormData {
  email: string;
  full_name: string;
  phone_number: string;
  edgelink_role: number | null;
  status: boolean;
}

const CompanyUsersPage: React.FC = () => {
  const [users, setUsers] = useState<CompanyUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  // Dialog states
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  // Holds the user currently being edited
  const [selectedUser, setSelectedUser] = useState<CompanyUser | null>(null);

  // Form data for both create & update
  const [formData, setFormData] = useState<UserFormData>({
    email: '',
    full_name: '',
    phone_number: '',
    edgelink_role: null,
    status: true,
  });

  // Response messages for dialogs
  const [createResponseMessage, setCreateResponseMessage] = useState<string>('');
  const [editResponseMessage, setEditResponseMessage] = useState<string>('');

  // ----------------------------------
  // Fetch all users in the company
  // ----------------------------------
  const fetchUsers = () => {
    setLoading(true);
    axios
      .get<any, any>('/api/user/edgelink/companies/0/users/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        if (response.data && response.data.results) {
          setUsers(response.data.results);
        } else {
          setError('Failed to fetch users');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching users:', err);
        setError('Error fetching users');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // ----------------------------------
  // Create User Handlers
  // ----------------------------------
  const handleCreateOpen = () => {
    // Reset form and response message
    setFormData({
      email: '',
      full_name: '',
      phone_number: '',
      edgelink_role: null,
      status: true,
    });
    setCreateResponseMessage('');
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleCreateSave = () => {
    axios
      .post<any, any>('/api/user/edgelink/companies/0/users/', formData, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then(() => {
        fetchUsers();
        setOpenCreate(false);
        setCreateResponseMessage('');
      })
      .catch((err) => {
        console.error('Error creating user:', err);
        setCreateResponseMessage(err.response?.data?.message || 'Error creating user.');
      });
  };

  // ----------------------------------
  // Update User Handlers
  // ----------------------------------
  const handleEditOpen = (user: CompanyUser) => {
    setSelectedUser(user);
    setFormData({
      email: user.email,
      full_name: user.full_name,
      phone_number: user.phone_number || '',
      edgelink_role: user.edgelink_role,
      status: user.status,
    });
    setEditResponseMessage('');
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedUser(null);
  };

  const handleEditSave = () => {
    if (!selectedUser) return;
    axios
      .put<any, any>(`/api/user/edgelink/companies/0/users/${selectedUser.edgelink_user_id}/`, formData, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then(() => {
        fetchUsers();
        setOpenEdit(false);
        setSelectedUser(null);
        setEditResponseMessage('');
      })
      .catch((err) => {
        console.error('Error updating user:', err);
        setEditResponseMessage(err.response?.data?.message || 'Error updating user.');
      });
  };

  // ----------------------------------
  // Delete User Handler
  // ----------------------------------
  const handleDeleteUser = (user: CompanyUser) => {
    if (!window.confirm(`Are you sure you want to delete user: ${user.full_name}?`)) {
      return;
    }
    axios
      .delete<any, any>(`/api/user/edgelink/companies/0/users/${user.edgelink_user_id}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then(() => {
        fetchUsers();
      })
      .catch((err) => {
        console.error('Error deleting user:', err);
      });
  };

  // ----------------------------------
  // Render
  // ----------------------------------
  if (loading) {
    return <Typography>Loading company users...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
        Company Users
      </Typography>

      <Card sx={{ boxShadow: 3, borderRadius: 2, mb: 4 }}>
        <CardHeader
          title={
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              All Users
            </Typography>
          }
          subheader={
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Manage all users associated with this company
            </Typography>
          }
          sx={{ backgroundColor: (theme) => theme.palette.grey[200], py: 2, px: 3 }}
          action={
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateOpen}>
              Create User
            </Button>
          }
        />
        <CardContent>
          {users.length === 0 ? (
            <Typography>No users found.</Typography>
          ) : (
            <Grid container spacing={2}>
              {users.map((user) => (
                <Grid item xs={12} md={6} lg={4} key={user.edgelink_user_id}>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      height: '100%',
                      position: 'relative',
                      transition: 'box-shadow 0.3s ease',
                      '&:hover': { boxShadow: 4 },
                    }}
                  >
                    <Stack spacing={1} mb={2}>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {user.full_name}
                      </Typography>
                      <Divider />
                      <Typography variant="body2">
                        <strong>Email:</strong> {user.email}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Phone:</strong> {user.phone_number || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Status:</strong> {user.status ? 'Active' : 'Inactive'}
                      </Typography>
                      {user.edgelink_role && (
                        <Typography variant="body2">
                          <strong>Role ID:</strong> {user.edgelink_role}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Edit User">
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() => handleEditOpen(user)}
                        >
                          Edit
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete User">
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleDeleteUser(user)}
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>

      {/* Create User Dialog */}
      <GenericFormDialog open={openCreate} onClose={handleCreateClose} title="Create New User">
        <UserFormContent
          formData={formData}
          setFormData={setFormData}
          onSave={handleCreateSave}
          onCancel={handleCreateClose}
          responseMessage={createResponseMessage}
        />
      </GenericFormDialog>

      {/* Update User Dialog */}
      <GenericFormDialog open={openEdit} onClose={handleEditClose} title="Edit User">
        <UserFormContent
          formData={formData}
          setFormData={setFormData}
          onSave={handleEditSave}
          onCancel={handleEditClose}
          responseMessage={editResponseMessage}
        />
      </GenericFormDialog>
    </Box>
  );
};

export default CompanyUsersPage;
