// databridge/partners/forms/PartnerForm.tsx

import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  Autocomplete,
  Checkbox,
  CircularProgress,
  IconButton,
  DialogContentText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import axios from '../../../utils/axios.utils';
import { Company, Machine, ExternalApiPartner } from '../../models/dataBridgeModels';

interface PartnerFormProps {
  open: boolean;
  onClose: (reloadNeeded?: boolean) => void;
  partner: ExternalApiPartner | null;
}

/** Response returned by the API right after creating a partner */
interface PartnerCreationResponse {
  access_key: string;
  access_secret: string;
  email_address: string;
  external_api_partner_id: number;
  global_api_partner_id: string;
  name: string;
  user_id: number;
}

/** Request payload you send when creating or updating a partner */
interface PartnerUpsertRequest {
  name: string;
  description: string;
  enabled: boolean;
  company_id: number | null;
  machine_ids: number[];
}

/** Dialog that shows the newly created credentials. */
function PartnerKeysDialog({
  open,
  data,
  onClose,
}: {
  open: boolean;
  data: PartnerCreationResponse | null;
  onClose: () => void;
}) {
  if (!data) return null;

  // The raw multi-line text to display (and copy) in its own text field.
  const keysPopupText = `
global_api_partner_id: ${data.global_api_partner_id}
user_id: ${data.user_id}
external_api_partner_id: ${data.external_api_partner_id}
email_address: ${data.email_address}
access_key: ${data.access_key}
access_secret: ${data.access_secret}
  `.trim();

  const handleCopy = () => {
    navigator.clipboard.writeText(keysPopupText);
  };

  const handleDialogClose = (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => {
    // We only allow manual close by the "Close" button
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      // Only close from the "Close" button
      onClose={handleDialogClose}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Partner Credentials</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          This is the only time these keys can be viewed. Please securely store them before closing this dialog.
        </Typography>

        <TextField
          label="Credentials"
          multiline
          fullWidth
          margin="normal"
          value={keysPopupText}
          InputProps={{ readOnly: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopy}>Copy to Clipboard</Button>
        <Button
          variant="contained"
          onClick={() => {
            // Fire our special close event with a distinct reason
            handleDialogClose({}, 'closeButtonClick');
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function PartnerForm({ open, onClose, partner }: PartnerFormProps) {
  const isEditMode = Boolean(partner);

  // fields
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [enabled, setEnabled] = useState(true);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedMachines, setSelectedMachines] = useState<Machine[]>([]);
  const [error, setError] = useState('');

  // timestamps
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');

  // loading indicator
  const [loading, setLoading] = useState(false);

  // if creation succeeds, hold that data to show the partner keys
  const [creationResponse, setCreationResponse] = useState<PartnerCreationResponse | null>(null);

  // For Delete Confirmation
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);

      // Fetch companies
      axios
        .get<Company[]>('api/admin/companies/', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then((res) => setCompanies(res.data || []))
        .catch((err) => console.error('Failed to fetch companies', err))
        .finally(() => setLoading(false));
    }
  }, [open]);

  useEffect(() => {
    if (isEditMode && partner) {
      setName(partner.name);
      setDescription(partner.description);
      setEnabled(partner.enabled);

      setSelectedMachines(
        (partner.machines || []).map((machine) => ({
          ...machine,
          status: machine.status !== undefined ? machine.status : true,
        })),
      );

      if (partner.company) {
        setSelectedCompany({
          company_id: partner.company.company_id,
          name: partner.company.name,
          status: true,
          associated_machines: [],
        });
      }

      // Timestamps
      setCreatedAt(partner.created_at ? new Date(partner.created_at).toLocaleString() : '');
      setUpdatedAt(partner.updated_at ? new Date(partner.updated_at).toLocaleString() : '');
      setUpdatedBy(partner.last_modified_by || partner.updated_by || '');
    } else {
      // reset for create
      setName('');
      setDescription('');
      setEnabled(true);
      setSelectedMachines([]);
      setSelectedCompany(null);
      setCreatedAt('');
      setUpdatedAt('');
      setUpdatedBy('');
    }

    setError('');
    // Reset creationResponse if user re-opens or toggles mode
    setCreationResponse(null);
  }, [isEditMode, partner]);

  const actualCompany = companies.find((c) => c.company_id === selectedCompany?.company_id);
  const companyMachines = actualCompany?.associated_machines?.filter((m) => m.status) || [];

  const handleMachinesChange = (_event: any, newValue: Machine[]) => {
    setSelectedMachines(newValue);
  };

  const handleSave = () => {
    setError('');

    // Our request payload
    const payload: PartnerUpsertRequest = {
      name,
      description,
      enabled,
      company_id: actualCompany ? actualCompany.company_id : null,
      machine_ids: selectedMachines.map((m) => m.machine_id),
    };

    if (isEditMode && partner) {
      axios
        .put(`api/external_api_partner/${partner.external_api_partner_id}/`, payload, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then(() => onClose(true))
        .catch((err) => {
          console.error(err);
          setError(err.response?.data?.message || 'Failed to save partner.');
        });
    } else {
      axios
        .post<PartnerUpsertRequest, AxiosResponse<PartnerCreationResponse>>('api/external_api_partner/', payload, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then((res) => {
          setCreationResponse(res.data);
        })
        .catch((err) => {
          console.error(err);
          setError(err.response?.data?.message || 'Failed to create partner.');
        });
    }
  };

  // Handle "close" attempts. We only allow our custom button to do so.
  const handleDialogClose = (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    onClose(false);
  };

  // Handle Delete
  const handleDelete = async () => {
    if (!partner) return;
    setDeleteInProgress(true);
    setError('');

    try {
      // Example: Soft Delete or normal Delete
      await axios.delete(`api/external_api_partner/${partner.external_api_partner_id}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setShowDeleteConfirm(false);
      setDeleteInProgress(false);
      onClose(true);
    } catch (err: any) {
      console.error('[PartnerForm] Error deleting partner:', err);
      setError(`Error deleting partner: ${err?.response?.data?.detail || err.message || 'Unknown error'}`);
      setDeleteInProgress(false);
    }
  };

  if (!open) return null;

  return (
    <>
      <Dialog
        open={open}
        // Only close from the "Cancel"/"Save"/"Delete" buttons
        onClose={handleDialogClose}
        disableEscapeKeyDown
        maxWidth="md" // make it a bit wider
        fullWidth
      >
        {/* TOP BAR: Title + (Delete Icon if editing) + "Enabled" Switch */}
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} pt={2}>
          <Box display="flex" alignItems="center">
            <DialogTitle sx={{ p: 0 }}>
              {isEditMode ? 'Edit External API Partner' : 'Create External API Partner'}
            </DialogTitle>
            {isEditMode && partner && (
              <IconButton
                size="medium"
                color="error"
                onClick={() => setShowDeleteConfirm(true)}
                title="Delete Partner"
                sx={{
                  opacity: 0.8,
                  '&:hover': { opacity: 1 },
                  bgcolor: 'rgba(255,230,230,0.3)',
                  ml: 2,
                }}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>

          <FormControlLabel
            control={<Switch checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />}
            label="Enabled"
          />
        </Box>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent dividers>
              {error && (
                <Typography color="error" mb={2}>
                  {error}
                </Typography>
              )}

              <TextField
                label="Partner Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
              />

              {/*
                  We moved "Enabled" to the top-right, so no switch here
              */}

              <Autocomplete
                options={companies.filter((c) => c.status)}
                getOptionLabel={(option) => option.name}
                value={actualCompany || null}
                onChange={(_e, newValue) => {
                  setSelectedCompany(newValue);
                  setSelectedMachines([]);
                }}
                renderInput={(params) => <TextField {...params} label="Select Company" margin="normal" fullWidth />}
                sx={{
                  marginTop: 2,
                  '& .MuiOutlinedInput-input': {
                    padding: '14.5px 4px 14.5px 5px',
                  },
                }}
              />

              <Autocomplete
                multiple
                options={companyMachines}
                getOptionLabel={(option) => option.name}
                value={selectedMachines || []}
                onChange={handleMachinesChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name} (SN: {option.serial_number})
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Select Machines" placeholder="Machines" margin="normal" fullWidth />
                )}
              />
            </DialogContent>

            <Box px={3} py={2}>
              {createdAt && (
                <Typography variant="caption" display="block">
                  Created At: {createdAt}
                </Typography>
              )}
              {updatedAt && (
                <Typography variant="caption" display="block">
                  Updated At: {updatedAt}
                </Typography>
              )}
              {updatedBy && (
                <Typography variant="caption" display="block">
                  Last Modified By: {updatedBy}
                </Typography>
              )}
            </Box>

            <DialogActions>
              <Button onClick={() => handleDialogClose({}, 'closeButtonClick')}>Cancel</Button>
              <Button onClick={handleSave} variant="contained">
                {isEditMode ? 'Save Changes' : 'Create Partner'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Show the special "keys" dialog if we have a creationResponse */}
      <PartnerKeysDialog
        open={Boolean(creationResponse)}
        data={creationResponse}
        onClose={() => {
          setCreationResponse(null);
          onClose(true);
        }}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <DialogTitle color="error">Confirm Partner Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will delete (soft or hard, depending on API) the partner record. All associated references may be
            affected.
            <br />
            <br />
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained" disabled={deleteInProgress}>
            {deleteInProgress ? 'Deleting...' : 'Confirm Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
