// NewMfrRecipeForm.tsx
import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from '../../dataTable/DataTable/DataTable';
import { useMfrRecipesNewContext } from '../contexts/MfrRecipesNewContext';
import axios from '../../utils/axios.utils';

const NewMfrRecipeForm: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [formValues, setFormValues] = useState({
    global_recipe_id: '',
    global_mfr_id: '',
    liquid_recipe_name: '',
    liquid_recipe_desc: '',
    crop: null,
    crop_id: '',
    recipe_volume_max: '',
    recipe_volume_recommended: '',
    seed_cnt_per_scale_uow: '',
    unit_size_cnt: '',
    locked: false,
    active: true,
  });

  const [newRecipeItem, setNewRecipeItem] = useState({
    mfr_liquid: '',
    liquid_name: '',
    liquid_rate: '',
    liquid_rate_type_id: '',
  });

  const { globalMfrs, crops, fetchCrops, fetchManufacturers } = useMfrRecipesNewContext();
  const [recipeItems, setRecipeItems] = useState<any[]>([]);
  const [mfrLiquids, setMfrLiquids] = useState<any[]>([]);
  const [liquidRateTypes, setLiquidRateTypes] = useState<any[]>([]);
  const [errorMessages, setErrorMessages] = useState<any>(null);

  useEffect(() => {
    fetchManufacturers();
    fetchCrops();
  }, []);

  useEffect(() => {
    const fetchMfrLiquids = async () => {
      try {
        const response = await axios.get('/api/admin/autotreat_mfr/mfr_liquids/', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        });
        setMfrLiquids(response.data);
      } catch (error) {
        console.error('Error fetching MFR liquids:', error);
      }
    };

    const fetchLiquidRateTypes = async () => {
      try {
        const response = await axios.get('/api/admin/autotreat_mfr/liquid_rate_types/', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        });
        setLiquidRateTypes(response.data);
      } catch (error) {
        console.error('Error fetching liquid rate types:', error);
      }
    };

    fetchMfrLiquids();
    fetchLiquidRateTypes();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRecipeItemChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setNewRecipeItem({
      ...newRecipeItem,
      [name as string]: value,
    });
  };

  const handleRecipeItemSelectChange = (name: string, value: string) => {
    setNewRecipeItem({
      ...newRecipeItem,
      [name]: value,
    });
  };

  const handleAddRecipeItem = () => {
    const selectedLiquid = mfrLiquids.find((l) => l.global_liquid_id === newRecipeItem.mfr_liquid);

    if (newRecipeItem.mfr_liquid && newRecipeItem.liquid_rate && newRecipeItem.liquid_rate_type_id) {
      const newItem = {
        autotreat_mfr_recipe_item_cloud_id: recipeItems.length + 1,
        global_recipe_id: formValues.global_recipe_id,
        global_liquid_id: newRecipeItem.mfr_liquid,
        liquid_rate_type_id: newRecipeItem.liquid_rate_type_id,
        liquid_name: selectedLiquid ? selectedLiquid.liquid_name : '',
        liquid_rate: newRecipeItem.liquid_rate,
        liquid_rate_type:
          liquidRateTypes.find((type) => type.liquid_rate_type_id === newRecipeItem.liquid_rate_type_id)
            ?.liquid_rate_type || '',
        locked: false,
        active: true,
      };

      // Update the recipeItems state to include the new item
      setNewRecipeItem({ mfr_liquid: '', liquid_name: '', liquid_rate: '', liquid_rate_type_id: '' });
      setRecipeItems([...recipeItems, newItem]);
    } else {
      alert('Please fill out all fields before adding a recipe item.');
    }
  };

  const handleRemoveRecipeItem = (itemId: number) => {
    setRecipeItems((prevItems) => prevItems.filter((item) => item.autotreat_mfr_recipe_item_cloud_id !== itemId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Combine form data and recipe items into a single payload
      const payload = {
        ...formValues,
        recipe_items: recipeItems.map((item) => ({
          global_liquid_id: item.global_liquid_id,
          liquid_rate: item.liquid_rate,
          liquid_rate_type_id: item.liquid_rate_type_id,
          locked: item.locked || false,
          active: item.active || true,
          obsolete: false,
        })),
      };

      payload.crop_id = payload.crop.crop_id;
      delete payload.crop;

      // Send a POST request to the backend
      const response = await axios.post('/api/admin/autotreat_mfr/mfr_recipes/', payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        onSuccess();
        const baseUrl = window.location.origin;
        window.location.href = `${baseUrl}/admin/manufacturer_management?activeTab=1`;
      } else {
        console.error('Unexpected response:', response);
        alert('Failed to create recipe. Please try again.');
      }
    } catch (error: any) {
      // Check if it's a 400 response
      if (error?.response?.status === 400) {
        // The server likely returned validation errors
        const errorData = error.response.data;

        // If errorData is an object (like {"global_recipe_id": ["Some error"]})
        if (typeof errorData === 'object' && errorData !== null) {
          // Convert each field’s error array or string into a line of text
          const messages = Object.entries(errorData).map(([field, errors]) => {
            // If the value is an array of messages, join them
            // Otherwise, just convert to string
            if (Array.isArray(errors)) {
              return `${field}: ${errors.join(', ')}`;
            }
            return `${field}: ${String(errors)}`;
          });
          alert(messages.join('\n'));
        } else {
          // If it's already a string or something else, just show it
          alert(JSON.stringify(errorData));
        }

        // Optionally also setErrorMessages if you want to store them
        setErrorMessages(errorData);
      } else {
        // Some other error
        alert('Failed to create recipe. Please try again.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '70%', margin: 'auto', textAlign: 'left' }}>
      <Typography variant="h1" gutterBottom>
        Create New Recipe
      </Typography>
      <Typography
        variant="body2"
        style={{ cursor: 'pointer', color: 'lightgrey', marginBottom: '20px' }}
        onClick={() => window.history.back()}
      >
        ← Return to Recipe List
      </Typography>
      <Grid container spacing={3}>
        {/* Row 1 */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Global Recipe ID"
            value={formValues.global_recipe_id}
            name="global_recipe_id"
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            value={formValues.global_mfr_id}
            onChange={(e) => handleSelectChange('global_mfr_id', e.target.value as string)}
            name="global_mfr_id"
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Global MFR ID
            </MenuItem>
            {globalMfrs.map((mfr) => (
              <MenuItem key={mfr.global_mfr_id} value={mfr.global_mfr_id}>
                {mfr.mfr_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Liquid Recipe Name"
            value={formValues.liquid_recipe_name}
            name="liquid_recipe_name"
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Liquid Recipe Description"
            value={formValues.liquid_recipe_desc}
            name="liquid_recipe_desc"
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            value={formValues.crop?.crop_id || ''}
            onChange={(e) => {
              const selectedCrop = crops.find((crop) => crop.crop_id === e.target.value);
              handleSelectChange('crop', selectedCrop);
            }}
            name="crop"
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Crop
            </MenuItem>
            {crops.map((crop) => (
              <MenuItem key={crop.crop_id} value={crop.crop_id}>
                {crop.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Recipe Volume Max"
            value={formValues.recipe_volume_max}
            name="recipe_volume_max"
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>

        {/* Row 3 */}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Recipe Volume Recommended"
            value={formValues.recipe_volume_recommended}
            name="recipe_volume_recommended"
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Seed CNT Per Scale UOW"
            value={formValues.seed_cnt_per_scale_uow}
            name="seed_cnt_per_scale_uow"
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Unit Size CNT"
            value={formValues.unit_size_cnt}
            name="unit_size_cnt"
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>

        {/* Row 4 */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formValues.locked} onChange={handleInputChange} name="locked" />}
            label="Locked"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formValues.active} onChange={handleInputChange} name="active" />}
            label="Active"
          />
        </Grid>

        {/* Recipe Items Section */}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            Recipe Items
          </Typography>
          <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
            <Grid item xs={3}>
              <Select
                value={newRecipeItem.mfr_liquid}
                onChange={(e) => handleRecipeItemSelectChange('mfr_liquid', e.target.value as string)}
                name="mfr_liquid"
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select MFR Liquid
                </MenuItem>
                {mfrLiquids.map((liquid) => (
                  <MenuItem key={liquid.global_liquid_id} value={liquid.global_liquid_id}>
                    {liquid.liquid_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Liquid Rate"
                value={newRecipeItem.liquid_rate}
                name="liquid_rate"
                onChange={handleRecipeItemChange}
                fullWidth
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                value={newRecipeItem.liquid_rate_type_id}
                onChange={(e) => handleRecipeItemSelectChange('liquid_rate_type_id', e.target.value as string)}
                name="liquid_rate_type_id"
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Liquid Rate Type
                </MenuItem>
                {liquidRateTypes.map((type) => (
                  <MenuItem key={type.liquid_rate_type_id} value={type.liquid_rate_type_id}>
                    {type.liquid_rate_type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={handleAddRecipeItem} fullWidth>
                Add
              </Button>
            </Grid>
          </Grid>
          <DataTable
            columns={[
              { name: 'Global Liquid ID', selector: (row) => row.global_liquid_id },
              { name: 'Liquid Name', selector: (row) => row.liquid_name },
              { name: 'Liquid Rate', selector: (row) => row.liquid_rate },
              { name: 'Liquid Rate Type', selector: (row) => row.liquid_rate_type },
              {
                name: 'Actions',
                cell: (row) => (
                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => handleRemoveRecipeItem(row.autotreat_mfr_recipe_item_cloud_id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                ),
              },
            ]}
            data={recipeItems}
            pagination
          />
        </Grid>

        {/* Buttons */}
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button variant="contained" color="primary" onClick={() => window.history.back()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewMfrRecipeForm;
