// src/devices/pages/DevicesDashboardMap.tsx

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Paper,
  Tooltip,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  Divider,
} from '@mui/material';

import DevicesIcon from '@mui/icons-material/Devices';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import TableChartIcon from '@mui/icons-material/TableChart';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { GoogleMap, Marker, LoadScript, MarkerClusterer, InfoWindow } from '@react-google-maps/api';

import axios from '../../../utils/axios.utils';

// Shared components
import DeviceSearchDialog from '../components/DeviceSearchDialog';
import DeviceFilterDialog from '../components/DeviceFilterDialog';
import DeviceSidebar from '../components/DeviceSidebar';
import { DeviceResult } from '../../model';

/* ------------------------------------------------------------------
   Utility Functions
------------------------------------------------------------------ */

/**
 * Convert a battery string (e.g. "80%") to a number. Returns 0 if unknown.
 */
function getBatteryPct(str?: string | null): number {
  if (!str) return 0;
  const numeric = parseInt(str.replace('%', ''), 10);
  return isNaN(numeric) ? 0 : numeric;
}

/**
 * Returns how many days since the device was last seen.
 */
function daysSince(dateStr?: string | null): number {
  if (!dateStr) return Infinity;
  const then = new Date(dateStr).getTime();
  const now = Date.now();
  return (now - then) / (1000 * 3600 * 24);
}

/**
 * Returns true if a device has valid lat/long for plotting on the map.
 */
function isValidLatLng(device: DeviceResult): boolean {
  if (!device.gps_lat || !device.gps_long) return false;
  const lat = parseFloat(device.gps_lat);
  const lng = parseFloat(device.gps_long);
  if (isNaN(lat) || isNaN(lng)) return false;
  if (lat === 0 || lng === 0) return false;
  if (lat < -90 || lat > 90) return false;
  if (lng < -180 || lng > 180) return false;
  return true;
}

/* ------------------------------------------------------------------
   Types
------------------------------------------------------------------ */
interface DevicesResponse {
  success: boolean;
  results: DeviceResult[];
}

/* ------------------------------------------------------------------
   Main Page Component
------------------------------------------------------------------ */
export default function DevicesDashboardMap() {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Main data states
  const [devices, setDevices] = React.useState<DeviceResult[]>([]);
  const [visibleDevices, setVisibleDevices] = React.useState<DeviceResult[]>([]);

  // Drawer states
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState<DeviceResult | null>(null);

  // Search & Filter states
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [batteryBelow50, setBatteryBelow50] = React.useState(false);
  const [fillFilter, setFillFilter] = React.useState<'any' | 'full' | 'empty'>('any');
  const [timeFilter, setTimeFilter] = React.useState<'any' | '24h' | '7d' | '30d'>('any');
  const [deviceTypeFilter, setDeviceTypeFilter] = React.useState<'any' | 'seed' | 'liquid'>('any');
  const [loadedProductFilter, setLoadedProductFilter] = React.useState(false);
  const [productNameFilter, setProductNameFilter] = React.useState<string>('any');

  // Distinct product names (for filtering)
  const productNames = React.useMemo(() => {
    const names = new Set<string>();
    devices.forEach((d) => {
      if (d.product_profile?.product_name) {
        names.add(d.product_profile.product_name);
      }
    });
    return Array.from(names);
  }, [devices]);

  // "Not Plotted" popup states
  const [showNotPlottedPopup, setShowNotPlottedPopup] = React.useState(false);
  const [notPlottedSearchTerm, setNotPlottedSearchTerm] = React.useState('');

  // For cluster InfoWindow
  const [selectedCluster, setSelectedCluster] = React.useState<{
    position: google.maps.LatLngLiteral;
    devices: DeviceResult[];
  } | null>(null);
  const [clusterSearchTerm, setClusterSearchTerm] = React.useState('');

  // Reference to the Google Map
  const mapRef = React.useRef<google.maps.Map | null>(null);

  /* --------------------------------------------------
     Fetch devices on mount
  -------------------------------------------------- */
  React.useEffect(() => {
    axios
      .get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        if (Array.isArray(response.data.results)) {
          setDevices(response.data.results);
        } else {
          console.error('Unexpected response structure:', response.data);
          setDevices([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching devices:', error);
        setDevices([]);
      });
  }, []);

  /* --------------------------------------------------
     Recompute visibleDevices based on search/filters
  -------------------------------------------------- */
  React.useEffect(() => {
    let result = [...devices];
    const txt = searchTerm.trim().toLowerCase();

    // Advanced multi-field search
    if (txt) {
      result = result.filter((d) => {
        const fields: string[] = [];
        if (d.device_serial_number) fields.push(d.device_serial_number);
        if (d.microcontroller_serial) fields.push(d.microcontroller_serial);
        if (d.city) fields.push(d.city);
        if (d.state) fields.push(d.state);
        if (d.address) fields.push(d.address);
        if (d.zip_code) fields.push(d.zip_code);
        if (d.country) fields.push(d.country);
        if (d.battery_life_level) fields.push(d.battery_life_level);

        // Product info
        if (d.product_profile?.product_name) fields.push(d.product_profile.product_name);
        if (d.product_profile?.lot_number) fields.push(d.product_profile.lot_number);
        if (d.product_profile?.batch_number) fields.push(d.product_profile.batch_number);
        if (d.product_profile?.crop) fields.push(d.product_profile.crop);

        // fill_status
        if (d.fill_status) fields.push(d.fill_status);

        // Company info
        if (Array.isArray(d.companies)) {
          d.companies.forEach((company) => {
            if (company.primary_edgelink_company_name) fields.push(company.primary_edgelink_company_name);
            if (company.secondary_edgelink_company_name) fields.push(company.secondary_edgelink_company_name);
            if (company.tertiary_edgelink_company_name) fields.push(company.tertiary_edgelink_company_name);
          });
        }

        // Tank config
        if (d.tank_config?.tank_name) fields.push(d.tank_config.tank_name);

        const lowerFields = fields.map((f) => f.toLowerCase());
        return lowerFields.some((field) => field.includes(txt));
      });
    }

    if (batteryBelow50) {
      result = result.filter((d) => getBatteryPct(d.battery_life_level) < 50);
    }

    if (fillFilter === 'full') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'full');
    } else if (fillFilter === 'empty') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'empty');
    }

    if (timeFilter === '24h') {
      result = result.filter((d) => daysSince(d.last_seen_at) < 1);
    } else if (timeFilter === '7d') {
      result = result.filter((d) => daysSince(d.last_seen_at) >= 7);
    } else if (timeFilter === '30d') {
      result = result.filter((d) => daysSince(d.last_seen_at) >= 30);
    }

    if (deviceTypeFilter !== 'any') {
      result = result.filter((d) => {
        const dt = d.device_type?.device_type_name?.toLowerCase() || '';
        return dt === deviceTypeFilter;
      });
    }

    if (loadedProductFilter) {
      result = result.filter((d) => !!d.product_profile?.product_name);
    }

    if (productNameFilter !== 'any') {
      result = result.filter((d) => d.product_profile?.product_name === productNameFilter);
    }

    setVisibleDevices(result);
  }, [
    devices,
    searchTerm,
    batteryBelow50,
    fillFilter,
    timeFilter,
    deviceTypeFilter,
    loadedProductFilter,
    productNameFilter,
  ]);

  /* --------------------------------------------------
     Partition: plottable vs not plottable
  -------------------------------------------------- */
  const validDevices = React.useMemo(() => visibleDevices.filter((d) => isValidLatLng(d)), [visibleDevices]);
  const invalidDevices = React.useMemo(() => visibleDevices.filter((d) => !isValidLatLng(d)), [visibleDevices]);

  /* --------------------------------------------------
     Determine initial map center
  -------------------------------------------------- */
  const initialCenter = React.useMemo(() => {
    const firstWithCoords = devices.find((d) => isValidLatLng(d));
    if (!firstWithCoords) {
      return { lat: 39.8283, lng: -98.5795 };
    }
    return {
      lat: parseFloat(firstWithCoords.gps_lat as string),
      lng: parseFloat(firstWithCoords.gps_long as string),
    };
  }, [devices]);

  /* --------------------------------------------------
     Map & Markers
  -------------------------------------------------- */
  function onMapLoad(map: google.maps.Map) {
    mapRef.current = map;
  }

  function onMarkerClick(device: DeviceResult) {
    setSelectedDevice(device);
    setDrawerOpen(true);
  }

  function onMarkerLoad(marker: google.maps.Marker, device: DeviceResult) {
    (marker as any).myDevice = device;
  }

  /* --------------------------------------------------
     Cluster InfoWindow logic
  -------------------------------------------------- */
  const filteredClusterDevices = React.useMemo(() => {
    if (!selectedCluster) return [];
    const txt = clusterSearchTerm.trim().toLowerCase();
    if (!txt) return selectedCluster.devices;
    return selectedCluster.devices.filter((dev) => {
      const f = [
        dev.device_serial_number.toLowerCase(),
        dev.microcontroller_serial?.toLowerCase() || '',
        dev.city?.toLowerCase() || '',
        dev.state?.toLowerCase() || '',
        dev.product_profile?.product_name?.toLowerCase() || '',
        dev.battery_life_level?.toLowerCase() || '',
      ];
      return f.some((field) => field.includes(txt));
    });
  }, [selectedCluster, clusterSearchTerm]);

  function onClusterClick(cluster: any) {
    const markers = cluster.getMarkers();
    const clusterPos = cluster.getCenter()?.toJSON();
    if (!clusterPos) return;
    const devicesInCluster: DeviceResult[] = [];
    markers.forEach((marker: google.maps.Marker & { myDevice?: DeviceResult }) => {
      if (marker.myDevice) devicesInCluster.push(marker.myDevice);
    });
    setSelectedCluster({
      position: clusterPos,
      devices: devicesInCluster,
    });
    setClusterSearchTerm('');
  }

  /* --------------------------------------------------
     Not Plotted local search
  -------------------------------------------------- */
  const filteredInvalidDevices = React.useMemo(() => {
    const txt = notPlottedSearchTerm.trim().toLowerCase();
    if (!txt) return invalidDevices;
    return invalidDevices.filter((dev) => {
      const f = [
        dev.device_serial_number.toLowerCase(),
        dev.microcontroller_serial?.toLowerCase() || '',
        dev.city?.toLowerCase() || '',
        dev.state?.toLowerCase() || '',
        dev.product_profile?.product_name?.toLowerCase() || '',
      ];
      return f.some((field) => field.includes(txt));
    });
  }, [invalidDevices, notPlottedSearchTerm]);

  /* --------------------------------------------------
     "Select Device" from search or cluster
  -------------------------------------------------- */
  function handleSelectDevice(device: DeviceResult) {
    setSelectedDevice(device);
    setDrawerOpen(true);
    setSearchOpen(false);
    setFilterOpen(false);
    if (mapRef.current && device.gps_lat && device.gps_long) {
      mapRef.current.panTo({
        lat: parseFloat(device.gps_lat),
        lng: parseFloat(device.gps_long),
      });
    }
  }

  /* --------------------------------------------------
     Clear all filters
  -------------------------------------------------- */
  function handleClearFilters() {
    setBatteryBelow50(false);
    setFillFilter('any');
    setTimeFilter('any');
    setDeviceTypeFilter('any');
    setLoadedProductFilter(false);
    setProductNameFilter('any');
  }

  /* --------------------------------------------------
     Re-fetch ALL device information after an update
  -------------------------------------------------- */
  async function handleDeviceUpdated() {
    try {
      const resp = await axios.get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      if (resp.data.success && Array.isArray(resp.data.results)) {
        setDevices(resp.data.results);
        // If a device is currently selected, update it with the new info.
        if (selectedDevice) {
          const updated = resp.data.results.find((d) => d.device_serial_number === selectedDevice.device_serial_number);
          if (updated) {
            setSelectedDevice(updated);
          }
        }
      }
    } catch (err) {
      console.error('Failed to refresh device data:', err);
    }
  }

  /* --------------------------------------------------
     Effect: when selected device changes, pan the map
  -------------------------------------------------- */
  React.useEffect(() => {
    if (selectedDevice && mapRef.current && selectedDevice.gps_lat && selectedDevice.gps_long) {
      mapRef.current.panTo({
        lat: parseFloat(selectedDevice.gps_lat),
        lng: parseFloat(selectedDevice.gps_long),
      });
    }
  }, [selectedDevice]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', pb: 12 }}>
      {/* Top Bar */}
      <Paper
        elevation={3}
        sx={{
          mb: 2,
          p: 2,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'linear-gradient(to right, #203a43, #2c5364)',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <DevicesIcon sx={{ fontSize: 32, color: '#fff' }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
            EdgeLink Devices
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {!isMobile && (
            <>
              <Tooltip title="Map View">
                <IconButton onClick={() => history.push('/edgelink/devices/map')}>
                  <MapIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Card View">
                <IconButton onClick={() => history.push('/edgelink/devices/cards')}>
                  <ViewAgendaIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Table View">
                <IconButton onClick={() => history.push('/edgelink/devices/table')}>
                  <TableChartIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Filter">
            <IconButton onClick={() => setFilterOpen(true)}>
              <FilterListIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Search">
            <IconButton onClick={() => setSearchOpen(true)}>
              <SearchIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>
      {/* Map occupies all remaining space */}
      <Box sx={{ position: 'relative', flex: 1 }}>
        <LoadScript googleMapsApiKey="AIzaSyA2IxUu6GtCHq9DUTuLuLgaSoYwodgblyE">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={initialCenter}
            zoom={5}
            onLoad={onMapLoad}
          >
            <MarkerClusterer
              averageCenter
              enableRetinaIcons
              gridSize={60}
              onClick={onClusterClick}
              options={{
                zoomOnClick: false,
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
              }}
            >
              {(clusterer) =>
                validDevices.map((device) => {
                  const lat = parseFloat(device.gps_lat as string);
                  const lng = parseFloat(device.gps_long as string);
                  return (
                    <Marker
                      key={device.edgelink_device_id}
                      position={{ lat, lng }}
                      clusterer={clusterer}
                      onClick={() => onMarkerClick(device)}
                      onLoad={(marker) => onMarkerLoad(marker, device)}
                    />
                  );
                })
              }
            </MarkerClusterer>
            {/* Cluster InfoWindow */}
            {selectedCluster && (
              <InfoWindow
                position={selectedCluster.position}
                onCloseClick={() => setSelectedCluster(null)}
                options={{ headerDisabled: true }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    width: 320,
                    maxHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      p: 1.5,
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" sx={{ ml: 1 }}>
                      Search Devices
                    </Typography>
                    <IconButton size="small" onClick={() => setSelectedCluster(null)} sx={{ color: '#040404' }}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <TextField
                      label="Search by serial, city, product..."
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={clusterSearchTerm}
                      onChange={(e) => setClusterSearchTerm(e.target.value)}
                    />
                  </Box>
                  <Divider />
                  <Box sx={{ px: 2, pb: 2, maxHeight: 250, overflowY: 'auto' }}>
                    {filteredClusterDevices.length > 0 ? (
                      <List dense>
                        {filteredClusterDevices.map((dev) => (
                          <ListItem
                            key={dev.edgelink_device_id}
                            button
                            onClick={() => {
                              handleSelectDevice(dev);
                              setSelectedCluster(null);
                            }}
                            sx={{ mb: 1, borderRadius: 1 }}
                          >
                            <ListItemText
                              primary={
                                <Typography variant="body2" fontWeight="bold">
                                  Serial: {dev.device_serial_number}
                                  {dev.microcontroller_serial ? ` | ${dev.microcontroller_serial}` : ''}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Typography variant="caption" sx={{ display: 'block' }}>
                                    {dev.city
                                      ? `${dev.city}, ${dev.state || ''}`
                                      : `Lat: ${dev.gps_lat}, Lng: ${dev.gps_long}`}
                                  </Typography>
                                  <Typography variant="caption" sx={{ display: 'block' }}>
                                    {dev.product_profile?.product_name || 'No product'} | Battery:{' '}
                                    {dev.battery_life_level || 'N/A'}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        No devices match your search.
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
        {/* Device Sidebar Drawer */}
        <DeviceSidebar
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          selectedDevice={selectedDevice}
          popoutEnabled={true} // enable the popout icon
          showMap={false} // disable the map inside the DeviceContentCard
          onDeviceUpdated={handleDeviceUpdated} // re-fetch all devices after any update
        />
        {/* Reusable Search Dialog */}
        <DeviceSearchDialog
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          visibleDevices={visibleDevices}
          handleSelectDevice={handleSelectDevice}
        />
        {/* Reusable Filter Dialog */}
        <DeviceFilterDialog
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          productNames={productNames}
          batteryBelow50={batteryBelow50}
          setBatteryBelow50={setBatteryBelow50}
          fillFilter={fillFilter}
          setFillFilter={setFillFilter}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          deviceTypeFilter={deviceTypeFilter}
          setDeviceTypeFilter={setDeviceTypeFilter}
          loadedProductFilter={loadedProductFilter}
          setLoadedProductFilter={setLoadedProductFilter}
          productNameFilter={productNameFilter}
          setProductNameFilter={setProductNameFilter}
          handleClearFilters={handleClearFilters}
        />
        {/* "Not Plotted" popup */}
        {invalidDevices.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              zIndex: 9999,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {!showNotPlottedPopup && (
              <Paper
                elevation={3}
                sx={{
                  p: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={() => setShowNotPlottedPopup(true)}
              >
                <InfoOutlinedIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {invalidDevices.length} device(s) not plotted
                </Typography>
              </Paper>
            )}
            {showNotPlottedPopup && (
              <Paper
                elevation={4}
                sx={{
                  width: 320,
                  maxHeight: 400,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    p: 1.5,
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    Not Plotted Devices
                  </Typography>
                  <IconButton size="small" onClick={() => setShowNotPlottedPopup(false)} sx={{ color: '#040404' }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box sx={{ p: 2 }}>
                  <TextField
                    label="Search by serial, city, product..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={notPlottedSearchTerm}
                    onChange={(e) => setNotPlottedSearchTerm(e.target.value)}
                  />
                </Box>
                <Divider />
                <Box sx={{ px: 2, pb: 2, maxHeight: 250, overflowY: 'auto' }}>
                  {filteredInvalidDevices.length > 0 ? (
                    <List disablePadding dense>
                      {filteredInvalidDevices.map((dev) => (
                        <ListItem key={dev.edgelink_device_id} disableGutters sx={{ mb: 1 }}>
                          <ListItemButton
                            onClick={() => {
                              handleSelectDevice(dev);
                            }}
                            sx={{
                              borderRadius: 1,
                              '&:hover': {
                                backgroundColor: 'action.hover',
                              },
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography variant="body2" fontWeight="bold">
                                  Serial: {dev.device_serial_number}
                                  {dev.microcontroller_serial ? ` | ${dev.microcontroller_serial}` : ''}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Typography variant="caption" sx={{ display: 'block' }}>
                                    {dev.city ? `${dev.city}, ${dev.state || ''}` : 'No location'}
                                  </Typography>
                                  <Typography variant="caption" sx={{ display: 'block' }}>
                                    {dev.product_profile?.product_name ?? 'No product'} | Battery:{' '}
                                    {dev.battery_life_level || '0%'}
                                  </Typography>
                                  <Typography variant="caption" sx={{ display: 'block' }} color="error">
                                    Lat: {dev.gps_lat || 'N/A'}, Lng: {dev.gps_long || 'N/A'}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      No devices match your search.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
