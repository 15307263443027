/* eslint-disable react-hooks/exhaustive-deps, no-alert, no-nested-ternary, react/jsx-props-no-spreading */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ToolsMajor, DigitalMediaReceiverMajor, CustomersMajor } from '@shopify/polaris-icons';
import { Button, Grid, Stack, Switch, Typography, styled } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import AppsIcon from '@mui/icons-material/Apps';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Navigation } from '@shopify/polaris';
import Logo from '../../../assets/ksi-logo.png';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { superAdminRoutes, adminRoutes, supportAdminRoutes } from '../constants/adminRoutes';
import { autotreatMachineManagementRoutes, autoTreatAnalyticsRoutes } from '../constants/autotreatUserRoutes';
import {
  autobulkMachineManagementRoutes,
  autobulkRealTimeActivityRoutes,
  autobulkAnalyticsRoutes,
} from '../constants/autobulkUserRoutes';
import {
  allAccountManagementRoutes,
  machineAccountManagementRoutes,
  edgelinkAccountManagementRoutes,
  edgelinkDeliveryTicketRoutes,
  allAccountManagementAndNotificationRoutes,
} from '../constants/sharedUserRoutes';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth0();
  const {
    currentUserHasAdminPermission,
    currentUserHasUserPermission,
    companyManaged,
    sidebarLoading,
    loginMessage,
    modulesEnabled,
  } = React.useContext(AuthContext);

  const defaultModule = () => {
    if (window.location.href.includes('level_sensors')) {
      return 'Level Sensors';
    }
    if (window.location.href.includes('edgelink')) {
      return 'EdgeLink';
    }
    if (window.location.href.includes('autobulk')) {
      return 'AutoBulk';
    }
    if (window.location.href.includes('autotreat')) {
      return 'AutoTreat';
    }
    if (sessionStorage.getItem('selectedModule')) {
      return sessionStorage.getItem('selectedModule');
    }
    if (modulesEnabled.length > 0) {
      return modulesEnabled[0];
    }
    return '';
  };

  const [open, setOpen] = React.useState(false);
  const [selectedModule, setSelectedModule] = React.useState(defaultModule());

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (option: string) => {
    setSelectedModule(option);
    sessionStorage.setItem('selectedModule', option);

    const optionPaths: { [key: string]: string } = {
      AutoTreat: '/autotreat/analytics/transaction_summary',
      AutoBulk: '/autobulk/analytics/transaction_summary',
      'Level Sensors': '/autobulk/level_sensors',
      EdgeLink: '/edgelink',
    };
    setOpen(false);

    history.push(optionPaths[option]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const autoTreatRealTimeActivity = {
    label: 'Real Time Activity',
    url: '/autotreat/real_time_activity',
    icon: ToolsMajor,
  };

  const levelSensorRoutes = {
    label: 'Level Sensors',
    url: '/autobulk/level_sensors',
    icon: DigitalMediaReceiverMajor,
  };

  const edgelinkRoutes = {
    label: 'Devices',
    url: '#',
    icon: DigitalMediaReceiverMajor,
    subNavigationItems: [
      {
        url: '/edgelink',
        disabled: false,
        label: 'Account Dashboard',
      },
      {
        url: '/edgelink/devices/map',
        disabled: false,
        label: 'Map View',
      },
      {
        url: '/edgelink/devices/cards',
        disabled: false,
        label: 'Card View',
      },
      {
        url: '/edgelink/devices/table',
        disabled: false,
        label: 'Table View',
      },
    ],
  };

  const notificationRoutes = () => {
    return {
      label: 'Account Management',
      url: '/account_management/my_machines',
      icon: CustomersMajor,
      matches: true,
      subNavigationItems: [
        {
          url: '/account_management/company_machines',
          disabled: false,
          label: 'Company Machines',
        },
        {
          url: '/account_management/my_machines',
          disabled: false,
          label: 'My Machines',
        },
        {
          url: '/account_management/users',
          disabled: false,
          label: 'Users',
        },
        {
          url: '/account_management/company',
          disabled: false,
          label: 'Company',
        },
        {
          url: '/account_management/notifications',
          disabled: false,
          label: 'Notifications',
        },
        {
          url: '/machine_requests',
          disabled: false,
          label: 'Machine Requests',
        },
      ],
    };
  };

  const fetchAdminRoutes = () => {
    if (currentUserHasAdminPermission('ksi_superadmin')) {
      return superAdminRoutes;
    }

    if (currentUserHasAdminPermission('ksi_admin')) {
      return adminRoutes;
    }

    if (currentUserHasAdminPermission('ksi_support')) {
      return supportAdminRoutes;
    }

    return null;
  };

  return (
    <Navigation location={location.pathname}>
      <a href="/admin/client-projects" className="Polaris-TopBar__NavigationIcon">
        <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
      </a>
      {loginMessage && <p style={{ marginLeft: 25, marginTop: 10, marginBottom: -10 }}>{loginMessage}</p>}
      <hr />
      {modulesEnabled && modulesEnabled?.length > 1 && (
        <>
          <Grid
            onClick={handleToggle}
            container
            ref={anchorRef}
            aria-label="split button"
            style={{ boxShadow: 'none', display: 'inherit', paddingLeft: '2rem', marginBottom: 10, cursor: 'pointer' }}
          >
            <Grid item>
              <AppsIcon style={{ fontSize: 28, color: '#3d5a80' }} />
            </Grid>
            <Grid item style={{ marginLeft: 15 }}>
              <Typography variant="h2" style={{ color: '#333', marginTop: 2 }}>
                {selectedModule}
              </Typography>
            </Grid>
          </Grid>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 999 }}
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem style={{ zIndex: 999 }}>
                      {modulesEnabled.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={selectedModule === option}
                          onClick={(event) => handleMenuItemClick(option)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
      {
        {
          AutoTreat: (
            <Navigation.Section
              items={[
                currentUserHasUserPermission('reporting') && autoTreatRealTimeActivity,
                currentUserHasUserPermission('reporting') && autoTreatAnalyticsRoutes,
                (currentUserHasUserPermission('data_management') || user.email === 'demo@ksiedge.com') &&
                  autotreatMachineManagementRoutes,
              ]}
            />
          ),
          AutoBulk: (
            <Navigation.Section
              items={[
                currentUserHasUserPermission('reporting') && autobulkRealTimeActivityRoutes,
                currentUserHasUserPermission('reporting') && autobulkAnalyticsRoutes,
                (currentUserHasUserPermission('data_management') || user.email === 'demo@ksiedge.com') &&
                  autobulkMachineManagementRoutes,
              ]}
            />
          ),
          'Level Sensors': <Navigation.Section items={[levelSensorRoutes]} />,
          EdgeLink: <Navigation.Section items={[edgelinkRoutes]} />,
        }[selectedModule]
      }
      {(companyManaged || currentUserHasAdminPermission('ksi_support')) && selectedModule !== 'EdgeLink' && (
        <>
          <Navigation.Section
            items={[
              currentUserHasUserPermission('company_super_admin')
                ? notificationRoutes()
                : machineAccountManagementRoutes,
              currentUserHasAdminPermission('ksi_support') && fetchAdminRoutes(),
            ]}
            separator
          />
        </>
      )}
      {selectedModule === 'EdgeLink' && (
        <>
          <Navigation.Section items={[edgelinkDeliveryTicketRoutes]} separator />
          <Navigation.Section items={[edgelinkAccountManagementRoutes]} separator />
        </>
      )}
    </Navigation>
  );
};

export default Sidebar;
