import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Stack,
  Button,
  Divider,
  IconButton,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import axios from '../../../utils/axios.utils';
import GenericFormDialog from '../../sharedComponents/GenericFormDialog';
import DeviceProductFormContent from '../forms/DeviceProductFormContent';
import DeviceDeliveryTicketFormContent from '../forms/DeviceDeliveryTicketFormContent';
import DeviceGeoFencingFormContent from '../forms/DeviceGeoFencingFormContent';
import DeviceTankAssignmentFormContent from '../forms/DeviceTankAssignmentFormContent';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { DeviceResult } from '../../model';

// -------------------------
// Types & Helpers
// -------------------------

interface DeviceContentCardProps {
  selectedDevice: DeviceResult | null;
  /**
   * If true, displays the map at the bottom of this card (if device has valid lat/lng).
   */
  showMap?: boolean;

  /**
   * If true, shows a popout icon in the top-left corner. Clicking it navigates to
   * {device_serial}/primary | /secondary | /tertiary (depending on company_relationship).
   */
  popoutEnabled?: boolean;

  /**
   * Optional callback when user clicks the close (X) icon in the top-right corner.
   */
  onClose?: () => void;

  /**
   * Optional callback to re-fetch or re-render device data
   * after saves, so we stay in sync with the backend.
   */
  onDeviceUpdated?: () => void;
}

function displayOrNothing(val?: string | number | null): string {
  return val !== null && val !== undefined && val !== '' ? String(val) : '';
}

function timeSince(dateStr?: string | null): string {
  if (!dateStr) return 'Unknown';
  const now = Date.now();
  const then = new Date(dateStr).getTime();
  if (isNaN(then)) return 'Invalid date';
  const diffMs = now - then;
  if (diffMs < 0) return 'Just now';
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  if (diffMinutes < 1) return `Just now`;
  if (diffMinutes < 60) return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  const diffDays = Math.floor(diffHours / 24);
  return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
}

interface CompanyOption {
  id: number;
  name: string;
}

const DeviceContentCard: React.FC<DeviceContentCardProps> = ({
  selectedDevice,
  showMap = false,
  popoutEnabled = false,
  onDeviceUpdated,
}) => {
  const history = useHistory();

  // States for internal dialogs
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
  const [companyType, setCompanyType] = useState<'secondary' | 'tertiary' | null>(null);
  const [companyList, setCompanyList] = useState<CompanyOption[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | ''>('');
  const [assignError, setAssignError] = useState<string | null>(null);

  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [receiveError, setReceiveError] = useState<string | null>(null);

  // For the product-profile info popup
  const [productProfileDialogOpen, setProductProfileDialogOpen] = useState(false);

  // For toggling the form dialogs
  const [showProductForm, setShowProductForm] = useState(false);
  const [showNewProductForm, setShowNewProductForm] = useState(false);
  const [showDeliveryTicketForm, setShowDeliveryTicketForm] = useState(false);
  const [showTankAssignmentForm, setShowTankAssignmentForm] = useState(false);
  const [showGeoFencingForm, setShowGeoFencingForm] = useState(false);

  // For storing errors or success from child forms
  const [formError, setFormError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState<string | null>(null);

  // Navigation / Action Helpers
  const goToDeviceDashboard = (serial: string) => {
    history.push(`/edgelink/devices/${serial}/dashboard`);
  };

  // Derived Data
  const isSeedDevice = selectedDevice?.device_type?.device_type_name?.toLowerCase() === 'seed';
  const deviceTypeAbbrev = isSeedDevice ? 'S' : 'L';

  // Relationship of the current user to this device:
  const userRelationship = selectedDevice?.company_relationship || 'primary';

  // Display names
  let primaryName = 'None';
  let secondaryName = 'None';
  let tertiaryName = 'None';

  if (selectedDevice?.companies && selectedDevice.companies.length > 0) {
    selectedDevice.companies.forEach((c) => {
      if (c.primary_edgelink_company_name) {
        primaryName = c.primary_edgelink_company_name;
      }
      if (c.secondary_edgelink_company_name) {
        secondaryName = c.secondary_edgelink_company_name;
      }
      if (c.tertiary_edgelink_company_name) {
        tertiaryName = c.tertiary_edgelink_company_name;
      }
    });
  }

  const hasProduct = Boolean(selectedDevice?.product_profile);
  const productNameUnderLoadProduct = selectedDevice?.product_profile?.product_name || 'No product assigned';

  // lat/lng for the optional map
  const lat = selectedDevice?.gps_lat ? Number(selectedDevice.gps_lat) : null;
  const lng = selectedDevice?.gps_long ? Number(selectedDevice.gps_long) : null;

  // Quick reference to product profile
  const productProfile = selectedDevice?.product_profile;

  // -------------------------
  // Company Assignment
  // -------------------------
  const handleOpenCompanyDialog = async (type: 'secondary' | 'tertiary') => {
    if (!selectedDevice) return;
    setAssignError(null);
    setCompanyType(type);
    setSelectedCompanyId('');

    try {
      const response = await axios.get<any, any>(`/api/user/edgelink/companies/0/associated/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      });
      if (response.data.success) {
        setCompanyList(
          response.data.results.map((company: any) => ({
            id: company.edgelink_company_id,
            name: company.company_name,
          })),
        );
      } else {
        setAssignError('Failed to fetch companies.');
        setCompanyList([]);
      }
    } catch (error) {
      console.error(error);
      setAssignError('Failed to fetch companies.');
      setCompanyList([]);
    }

    setCompanyDialogOpen(true);
  };

  const handleCloseCompanyDialog = () => {
    setCompanyDialogOpen(false);
    setCompanyType(null);
    setAssignError(null);
  };

  const handleSaveCompanyDialog = async () => {
    if (!selectedDevice || !companyType || selectedCompanyId === '') {
      setAssignError('Please select a company.');
      return;
    }
    try {
      setAssignError(null);
      const deviceSerial = selectedDevice.device_serial_number;
      // Build the URL for the PUT request
      const url =
        companyType === 'secondary'
          ? `/api/user/edgelink/devices/${deviceSerial}/assign_secondary/${selectedCompanyId}/`
          : `/api/user/edgelink/devices/${deviceSerial}/assign_tertiary/${selectedCompanyId}/`;

      await axios.put(
        url,
        {
          device_id: selectedDevice.edgelink_device_id,
          company_id: selectedCompanyId,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        },
      );

      setCompanyDialogOpen(false);
      // Tell parent to re-fetch the device so we see updated data
      if (onDeviceUpdated) onDeviceUpdated();
    } catch (err) {
      console.error(err);
      setAssignError('Failed to assign company. Please try again.');
    }
  };

  // -------------------------
  // Receiving
  // -------------------------
  const handleOpenReceiveDialog = () => {
    if (!selectedDevice) return;
    setReceiveError(null);
    setReceiveDialogOpen(true);
  };
  const handleCloseReceiveDialog = () => {
    setReceiveDialogOpen(false);
    setReceiveError(null);
  };
  const handleConfirmReceive = async () => {
    if (!selectedDevice) {
      setReceiveError('No device found.');
      return;
    }
    try {
      await axios.post(
        '/api/user/edgelink/receive-device',
        { edgelink_device_id: selectedDevice.edgelink_device_id },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        },
      );
      setReceiveDialogOpen(false);
      if (onDeviceUpdated) onDeviceUpdated();
    } catch (err) {
      console.error(err);
      setReceiveError('Failed to receive device. Please try again.');
    }
  };

  // Placeholder "Mark For Pickup" action for Tertiary relationship
  const handleMarkForPickup = () => {
    alert('Mark for Pickup clicked!');
  };

  // -------------------------
  // Product Profile Info
  // -------------------------
  const handleShowProductProfileInfo = () => {
    setProductProfileDialogOpen(true);
  };

  // -------------------------
  // Form Save Callback
  // -------------------------
  const handleFormSaved = () => {
    // Close all form dialogs
    setShowProductForm(false);
    setShowNewProductForm(false);
    setShowDeliveryTicketForm(false);
    setShowGeoFencingForm(false);
    setShowTankAssignmentForm(false);

    // Let the parent know so it can re-fetch updated device data
    if (onDeviceUpdated) onDeviceUpdated();
  };

  // -------------
  // Render
  // -------------
  return (
    <Box sx={{ p: 1, width: '100%', maxWidth: '100%' }}>
      {/* Top Header Row */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Optional popout in the top-left */}
          {popoutEnabled && selectedDevice && (
            <IconButton
              onClick={() => {
                if (selectedDevice.company_relationship === 'primary') {
                  history.push(`/edgelink/devices/${selectedDevice.device_serial_number}/primary`);
                } else if (selectedDevice.company_relationship === 'secondary') {
                  history.push(`/edgelink/devices/${selectedDevice.device_serial_number}/secondary`);
                } else if (selectedDevice.company_relationship === 'tertiary') {
                  history.push(`/edgelink/devices/${selectedDevice.device_serial_number}/tertiary`);
                }
              }}
              sx={{ mr: 1 }}
            >
              <OpenInNewIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Device Info
          </Typography>
        </Box>
      </Stack>

      {selectedDevice ? (
        <>
          {/* Top Card */}
          <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  bgcolor: '#ccc',
                  color: '#fff',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 20,
                }}
              >
                {deviceTypeAbbrev}
              </Box>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#3f51b5' }}>
                  Device Serial: {selectedDevice.device_serial_number}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Device Type: {displayOrNothing(selectedDevice.device_type?.device_type_name)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Last Check-In: {timeSince(selectedDevice.last_seen_at)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Location: {displayOrNothing(selectedDevice.city)}
                  {selectedDevice.state && `, ${selectedDevice.state}`}
                </Typography>
                {selectedDevice.fill_status && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <strong>Fill Status:</strong>{' '}
                    <Box component="span" sx={{ color: 'orange' }}>
                      {displayOrNothing(selectedDevice.fill_status)}
                    </Box>
                  </Typography>
                )}
              </Box>
            </Stack>
          </Paper>

          {/* Device Details */}
          <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Details
            </Typography>
            <Stack spacing={0.5}>
              <Typography variant="body2">
                <strong>Serial:</strong> {selectedDevice.device_serial_number}
              </Typography>
              <Typography variant="body2">
                <strong>Microcontroller:</strong> {displayOrNothing(selectedDevice.microcontroller_serial)}
              </Typography>
              <Divider sx={{ my: 1, opacity: 0.2 }} />
              <Typography variant="body2">
                <strong>Primary Company:</strong> {primaryName}
              </Typography>
              {(userRelationship === 'secondary' || userRelationship === 'tertiary') && (
                <Typography variant="body2">
                  <strong>Secondary Company:</strong> {secondaryName}
                </Typography>
              )}
              {userRelationship === 'tertiary' && (
                <Typography variant="body2">
                  <strong>Tertiary Company:</strong> {tertiaryName}
                </Typography>
              )}
              <Typography variant="body2">
                <strong>Relationship:</strong> {userRelationship}
              </Typography>
              <Divider sx={{ my: 1, opacity: 0.2 }} />
              <Typography variant="body2">
                <strong>Address:</strong> {displayOrNothing(selectedDevice.address)}
              </Typography>
              <Typography variant="body2">
                <strong>City/State/Zip:</strong> {displayOrNothing(selectedDevice.city)}
                {selectedDevice.state ? `, ${selectedDevice.state}` : ''} {displayOrNothing(selectedDevice.zip_code)}
              </Typography>
              <Typography variant="body2">
                <strong>Country:</strong> {displayOrNothing(selectedDevice.country)}
              </Typography>
            </Stack>
          </Paper>

          {/* Product Profile Summary */}
          <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: 2 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold' }}>
                Product Profile
              </Typography>
              {selectedDevice.product_profile && (
                <IconButton
                  size="small"
                  onClick={handleShowProductProfileInfo}
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText',
                    ':hover': {
                      bgcolor: 'primary.dark',
                      borderColor: 'primary.dark',
                    },
                  }}
                >
                  <InfoOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
            </Box>
            {productProfile ? (
              <Stack spacing={0.5}>
                <Typography variant="body2">
                  <strong>Product:</strong> {displayOrNothing(productProfile.product_name)}
                </Typography>
                <Typography variant="body2">
                  <strong>Loaded At:</strong>{' '}
                  {productProfile.loaded_at ? new Date(productProfile.loaded_at).toLocaleString() : ''}
                </Typography>
                <Typography variant="body2">
                  <strong>Emptied At:</strong>{' '}
                  {productProfile.emptied_at ? new Date(productProfile.emptied_at).toLocaleString() : ''}
                </Typography>

                {/* If device type = 1, display SDS and Label below */}
                {selectedDevice.device_type?.device_type_id === 1 && (
                  <>
                    <Divider sx={{ my: 1, opacity: 0.2 }} />
                    <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
                      Documentation:
                    </Typography>

                    {/* Product Label */}
                    <ListItem disableGutters sx={{ py: 0.25, pl: 0 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <DescriptionIcon sx={{ color: 'text.secondary' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Product Label"
                        secondary={
                          productProfile.chemical_label_attachment ? (
                            <Link
                              component="a"
                              href={
                                productProfile.chemical_label_attachment.startsWith('http')
                                  ? productProfile.chemical_label_attachment
                                  : `https://${productProfile.chemical_label_attachment}`
                              }
                              target="_blank"
                              rel="noreferrer"
                              underline="none"
                              color="primary"
                            >
                              View Label
                            </Link>
                          ) : (
                            'No Label Available'
                          )
                        }
                      />
                    </ListItem>

                    {/* Safety Data Sheet */}
                    <ListItem disableGutters sx={{ py: 0.25, pl: 0 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <DescriptionIcon sx={{ color: 'text.secondary' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Safety Data Sheet"
                        secondary={
                          productProfile.safety_data_sheet_attachment ? (
                            <Link
                              component="a"
                              href={
                                productProfile.safety_data_sheet_attachment.startsWith('http')
                                  ? productProfile.safety_data_sheet_attachment
                                  : `https://${productProfile.safety_data_sheet_attachment}`
                              }
                              target="_blank"
                              rel="noreferrer"
                              underline="none"
                              color="primary"
                            >
                              View Sheet
                            </Link>
                          ) : (
                            'No SDS Available'
                          )
                        }
                      />
                    </ListItem>
                  </>
                )}
              </Stack>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                No product associated
              </Typography>
            )}
          </Paper>

          {/* Actions */}
          <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Actions
            </Typography>

            {userRelationship === 'primary' && (
              <Stack spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => goToDeviceDashboard(selectedDevice.device_serial_number)}
                >
                  <DashboardIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Device Dashboard
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                {!hasProduct && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      p: 1,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                    onClick={() => setShowProductForm(true)}
                  >
                    <DownloadIcon sx={{ mt: '3px' }} />
                    <Box sx={{ ml: 1, flex: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Load Product
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        No product assigned
                      </Typography>
                    </Box>
                    <ChevronRightIcon sx={{ mt: '3px' }} />
                  </Box>
                )}

                {hasProduct && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        p: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                      onClick={() => setShowProductForm(true)}
                    >
                      <EditIcon sx={{ mt: '3px' }} />
                      <Box sx={{ ml: 1, flex: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          Edit Current Product
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {productNameUnderLoadProduct}
                        </Typography>
                      </Box>
                      <ChevronRightIcon sx={{ mt: '3px' }} />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        p: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                      onClick={() => setShowNewProductForm(true)}
                    >
                      <DownloadIcon sx={{ mt: '3px' }} />
                      <Box sx={{ ml: 1, flex: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          Load Another Product
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          (Create new product profile)
                        </Typography>
                      </Box>
                      <ChevronRightIcon sx={{ mt: '3px' }} />
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={handleOpenReceiveDialog}
                >
                  <InventoryIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Receive
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => handleOpenCompanyDialog('secondary')}
                >
                  <SwapHorizIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Secondary Company
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {secondaryName === 'None' ? 'No secondary assigned' : secondaryName}
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => handleOpenCompanyDialog('tertiary')}
                >
                  <SwapHorizIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Tertiary Company
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {tertiaryName === 'None' ? 'No tertiary assigned' : tertiaryName}
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => setShowDeliveryTicketForm(true)}
                >
                  <LocalShippingIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Create Delivery Ticket
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Prototype
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => setShowGeoFencingForm(true)}
                >
                  <LocationOnIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Set Geofencing
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Prototype
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                {selectedDevice?.device_type?.device_type_id === 1 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      p: 1,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                    onClick={() => setShowTankAssignmentForm(true)}
                  >
                    <EditIcon sx={{ mt: '3px' }} />
                    <Box sx={{ ml: 1, flex: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Tank Configuration
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {selectedDevice.tank_config ? selectedDevice.tank_config.tank_name : 'No tank config assigned'}
                      </Typography>
                    </Box>
                    <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                  </Box>
                )}
              </Stack>
            )}

            {userRelationship === 'secondary' && (
              <Stack spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => goToDeviceDashboard(selectedDevice.device_serial_number)}
                >
                  <DashboardIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Device Dashboard
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                {!hasProduct && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      p: 1,
                      borderRadius: 1,
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#f5f5f5' },
                    }}
                    onClick={() => setShowProductForm(true)}
                  >
                    <DownloadIcon sx={{ mt: '3px' }} />
                    <Box sx={{ ml: 1, flex: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Load Product
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        No product assigned
                      </Typography>
                    </Box>
                    <ChevronRightIcon sx={{ mt: '3px' }} />
                  </Box>
                )}

                {hasProduct && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        p: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                      onClick={() => setShowProductForm(true)}
                    >
                      <EditIcon sx={{ mt: '3px' }} />
                      <Box sx={{ ml: 1, flex: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          Edit Current Product
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {productNameUnderLoadProduct}
                        </Typography>
                      </Box>
                      <ChevronRightIcon sx={{ mt: '3px' }} />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        p: 1,
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                      }}
                      onClick={() => setShowNewProductForm(true)}
                    >
                      <DownloadIcon sx={{ mt: '3px' }} />
                      <Box sx={{ ml: 1, flex: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          Load Another Product
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          (Create new product profile)
                        </Typography>
                      </Box>
                      <ChevronRightIcon sx={{ mt: '3px' }} />
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={handleOpenReceiveDialog}
                >
                  <InventoryIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Receive
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => handleOpenCompanyDialog('tertiary')}
                >
                  <SwapHorizIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Tertiary Company
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {tertiaryName === 'None' ? 'No tertiary assigned' : tertiaryName}
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => setShowDeliveryTicketForm(true)}
                >
                  <LocalShippingIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Create Delivery Ticket
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Prototype
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => setShowGeoFencingForm(true)}
                >
                  <LocationOnIcon sx={{ mt: '3px' }} />
                  <Box sx={{ ml: 1, flex: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Set Geofencing
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Prototype
                    </Typography>
                  </Box>
                  <ChevronRightIcon sx={{ ml: 'auto', mt: '3px' }} />
                </Box>
              </Stack>
            )}

            {userRelationship === 'tertiary' && (
              <Stack spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={() => goToDeviceDashboard(selectedDevice.device_serial_number)}
                >
                  <DashboardIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Device Dashboard
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={handleOpenReceiveDialog}
                >
                  <InventoryIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Receive
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                  onClick={handleMarkForPickup}
                >
                  <LocalShippingIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Mark For Pickup
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>
              </Stack>
            )}
          </Paper>

          {/* Optional Map */}
          {showMap && lat !== null && lng !== null && (
            <Paper variant="outlined" sx={{ p: 2, mb: 2, borderRadius: 2 }}>
              <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
                Device Location
              </Typography>
              <Box sx={{ width: '100%', height: 300, mb: 1 }}>
                <LoadScript googleMapsApiKey="AIzaSyA2IxUu6GtCHq9DUTuLuLgaSoYwodgblyE">
                  <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={{ lat, lng }} zoom={12}>
                    <Marker position={{ lat, lng }} />
                  </GoogleMap>
                </LoadScript>
              </Box>
              <Typography variant="body2">
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open in Maps
                </Link>
              </Typography>
            </Paper>
          )}
        </>
      ) : (
        <Typography variant="body2">No device selected.</Typography>
      )}

      {/* -------------------------
          Company Assignment Dialog
      ------------------------- */}
      {companyDialogOpen && (
        <Dialog
          open={companyDialogOpen}
          onClose={handleCloseCompanyDialog}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 3,
              p: 3,
              backgroundColor: 'background.paper',
            },
          }}
        >
          <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            {companyType === 'secondary' ? 'Assigning Secondary Company' : 'Assigning Tertiary Company'}
          </DialogTitle>
          <DialogContent dividers sx={{ my: 3 }}>
            {assignError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {assignError}
              </Alert>
            )}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select Company</InputLabel>
              <Select
                value={selectedCompanyId}
                label="Select Company"
                onChange={(e) => setSelectedCompanyId(e.target.value as number)}
              >
                <MenuItem value={0}>-- No Assignment --</MenuItem>
                {companyList.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ py: 2 }}>
            <Button onClick={handleCloseCompanyDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveCompanyDialog}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* -------------------------
          Receive Dialog
      ------------------------- */}
      {receiveDialogOpen && (
        <Dialog open={receiveDialogOpen} onClose={handleCloseReceiveDialog}>
          <DialogTitle>Receive Device</DialogTitle>
          <DialogContent dividers>
            {receiveError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {receiveError}
              </Alert>
            )}
            <Typography>Are you sure you want to receive this device?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReceiveDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleConfirmReceive}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* -------------------------
          Product Profile Info Dialog
      ------------------------- */}
      <Dialog
        open={productProfileDialogOpen}
        onClose={() => setProductProfileDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent dividers>
          {productProfile ? (
            <Stack spacing={0.5}>
              <Typography variant="body2">
                <strong>Product:</strong> {displayOrNothing(productProfile.product_name)}
              </Typography>
              <Typography variant="body2">
                <strong>Lot Number:</strong> {displayOrNothing(productProfile.lot_number)}
              </Typography>
              <Typography variant="body2">
                <strong>Batch Number:</strong> {displayOrNothing(productProfile.batch_number)}
              </Typography>
              <Typography variant="body2">
                <strong>Crop:</strong> {displayOrNothing(productProfile.crop)}
              </Typography>
              <Typography variant="body2">
                <strong>Loaded At:</strong>{' '}
                {productProfile.loaded_at ? new Date(productProfile.loaded_at).toLocaleString() : ''}
              </Typography>
              <Typography variant="body2">
                <strong>Emptied At:</strong>{' '}
                {productProfile.emptied_at ? new Date(productProfile.emptied_at).toLocaleString() : ''}
              </Typography>

              {/* If this is a seed device, show seed-specific details */}
              {isSeedDevice && (
                <>
                  <Typography variant="body2">
                    <strong>Seed Size:</strong> {displayOrNothing(productProfile.seed_size)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Seed Units:</strong> {displayOrNothing(productProfile.seed_units)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Treatment Package:</strong> {displayOrNothing(productProfile.seed_treatment_package)}
                  </Typography>
                </>
              )}

              <Typography variant="body2">
                <strong>Additional Notes:</strong> {displayOrNothing(productProfile.additional_notes)}
              </Typography>

              {/* If device type = 1 (liquid), also show SDS and Label here */}
              {selectedDevice?.device_type?.device_type_id === 1 && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
                    Documentation:
                  </Typography>
                  <ListItem disableGutters sx={{ py: 0.25, pl: 0 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <DescriptionIcon sx={{ color: 'text.secondary' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Product Label"
                      secondary={
                        productProfile.chemical_label_attachment ? (
                          <Link
                            href={productProfile.chemical_label_attachment}
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            color="primary"
                          >
                            View Label
                          </Link>
                        ) : (
                          'No Label Available'
                        )
                      }
                    />
                  </ListItem>

                  <ListItem disableGutters sx={{ py: 0.25, pl: 0 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <DescriptionIcon sx={{ color: 'text.secondary' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Safety Data Sheet"
                      secondary={
                        productProfile.safety_data_sheet_attachment ? (
                          <Link
                            href={productProfile.safety_data_sheet_attachment}
                            target="_blank"
                            rel="noreferrer"
                            underline="none"
                            color="primary"
                          >
                            View Sheet
                          </Link>
                        ) : (
                          'No SDS Available'
                        )
                      }
                    />
                  </ListItem>
                </>
              )}
            </Stack>
          ) : (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              No product associated
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProductProfileDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* -------------------------
          Product / Delivery / Tank / GeoFencing Forms
      ------------------------- */}
      <GenericFormDialog
        open={showProductForm}
        onClose={() => setShowProductForm(false)}
        title="Device Product Form"
        showLastModified
        lastModifiedBy={selectedDevice?.product_profile?.updated_by}
        lastModifiedAt={selectedDevice?.product_profile?.updated_at}
      >
        <DeviceProductFormContent
          deviceSerial={selectedDevice?.device_serial_number || ''}
          deviceTypeId={selectedDevice?.device_type?.device_type_id}
          existingProfile={selectedDevice?.product_profile}
          onSaved={handleFormSaved}
          onChangeError={setFormError}
          onChangeSuccessMessage={setFormSuccess}
          onClose={() => setShowProductForm(false)}
        />
      </GenericFormDialog>

      <GenericFormDialog
        open={showNewProductForm}
        onClose={() => setShowNewProductForm(false)}
        title="Device Product Form"
      >
        <DeviceProductFormContent
          deviceSerial={selectedDevice?.device_serial_number || ''}
          deviceTypeId={selectedDevice?.device_type?.device_type_id}
          existingProfile={null}
          onSaved={handleFormSaved}
          onChangeError={setFormError}
          onChangeSuccessMessage={setFormSuccess}
          onClose={() => setShowNewProductForm(false)}
        />
      </GenericFormDialog>

      <GenericFormDialog
        open={showDeliveryTicketForm}
        onClose={() => setShowDeliveryTicketForm(false)}
        title="Delivery Ticket Form"
      >
        <DeviceDeliveryTicketFormContent
          deviceSerial={selectedDevice?.device_serial_number || ''}
          onSaved={handleFormSaved}
          onChangeError={setFormError}
          onChangeSuccessMessage={setFormSuccess}
          onClose={() => setShowDeliveryTicketForm(false)}
        />
      </GenericFormDialog>

      <GenericFormDialog open={showGeoFencingForm} onClose={() => setShowGeoFencingForm(false)} title="GeoFencing Form">
        <DeviceGeoFencingFormContent
          deviceSerial={selectedDevice?.device_serial_number || ''}
          onSaved={handleFormSaved}
          onChangeError={setFormError}
          onChangeSuccessMessage={setFormSuccess}
          onClose={() => setShowGeoFencingForm(false)}
        />
      </GenericFormDialog>

      <GenericFormDialog
        open={showTankAssignmentForm}
        onClose={() => setShowTankAssignmentForm(false)}
        title="Tank Assignment Form"
      >
        <DeviceTankAssignmentFormContent
          deviceSerial={selectedDevice?.device_serial_number || ''}
          onSaved={handleFormSaved}
          onChangeError={setFormError}
          onChangeSuccessMessage={setFormSuccess}
          onClose={() => setShowTankAssignmentForm(false)}
        />
      </GenericFormDialog>
    </Box>
  );
};

export default DeviceContentCard;
