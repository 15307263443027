// databridge/partners/pages/PartnersPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import axios from '../../../utils/axios.utils';
import { useHistory } from 'react-router-dom';

import { ExternalApiPartner, PartnerResponse } from '../../models/dataBridgeModels';
import PartnerForm from '../forms/PartnerForm';

interface PartnersPageProps {
  formMode?: 'new' | 'edit' | null;
  partnerId?: string | null;
}

export default function PartnersPage({ formMode, partnerId }: PartnersPageProps) {
  const history = useHistory();
  const [partners, setPartners] = useState<ExternalApiPartner[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formOpen, setFormOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<ExternalApiPartner | null>(null);

  const fetchPartners = () => {
    setLoading(true);
    setError('');
    axios
      .get<PartnerResponse>('api/external_api_partner/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        // Sort by ID ascending
        const sorted = [...res.data].sort((a, b) => a.external_api_partner_id - b.external_api_partner_id);
        setPartners(sorted);
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to fetch External API Partners');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  useEffect(() => {
    if (formMode === 'new') {
      setSelectedPartner(null);
      setFormOpen(true);
    } else if (formMode === 'edit' && partnerId) {
      const partnerIdNum = parseInt(partnerId, 10);
      const partner = partners.find((p) => p.external_api_partner_id === partnerIdNum);
      if (partner) {
        setSelectedPartner(partner);
        setFormOpen(true);
      }
    }
  }, [formMode, partnerId, partners]);

  const handleCreateNew = () => {
    // Update URL without triggering any router events
    window.history.replaceState(null, '', '/admin/databridge_management/partners/new');
    setSelectedPartner(null);
    setFormOpen(true);
  };

  const handleRowClick = (partner: ExternalApiPartner) => {
    // Update URL without triggering any router events
    window.history.replaceState(null, '', `/admin/databridge_management/partners/${partner.external_api_partner_id}`);
    setSelectedPartner(partner);
    setFormOpen(true);
  };

  const handleFormClose = (reloadNeeded?: boolean) => {
    // Reset URL
    window.history.replaceState(null, '', '/admin/databridge_management/partners');
    setFormOpen(false);
    setSelectedPartner(null);
    if (reloadNeeded) {
      fetchPartners();
    }
  };

  // Convert any updated_at string to local time
  const formatUpdatedAt = (dateString?: string) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleString(); // e.g., "3/26/2025, 1:15:30 PM"
  };

  return (
    <Box mt={2}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">External API Partners</Typography>
        <Button variant="contained" onClick={handleCreateNew}>
          Create New
        </Button>
      </Box>

      {loading && <Typography>Loading...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && partners.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Description</strong>
                </TableCell>
                <TableCell>
                  <strong>Company</strong>
                </TableCell>
                <TableCell>
                  <strong>Enabled</strong>
                </TableCell>
                <TableCell>
                  <strong>Updated At</strong>
                </TableCell>
                <TableCell>
                  <strong>Machines</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners.map((p) => (
                <TableRow
                  key={p.external_api_partner_id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRowClick(p)}
                >
                  <TableCell>{p.external_api_partner_id}</TableCell>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>{p.description}</TableCell>
                  <TableCell>{p.company?.name}</TableCell>
                  <TableCell>{p.enabled ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{formatUpdatedAt(p.updated_at)}</TableCell>
                  <TableCell>{p.machines?.length || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!loading && partners.length === 0 && <Typography>No External API Partners found.</Typography>}

      {formOpen && <PartnerForm open={formOpen} onClose={handleFormClose} partner={selectedPartner} />}
    </Box>
  );
}
