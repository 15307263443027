// src/devices/components/DeviceFilterDialog.tsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

interface DeviceFilterDialogProps {
  open: boolean;
  onClose: () => void;

  productNames: string[];

  batteryBelow50: boolean;
  setBatteryBelow50: (val: boolean) => void;

  fillFilter: 'any' | 'full' | 'empty';
  setFillFilter: (val: 'any' | 'full' | 'empty') => void;

  timeFilter: 'any' | '24h' | '7d' | '30d';
  setTimeFilter: (val: 'any' | '24h' | '7d' | '30d') => void;

  deviceTypeFilter: 'any' | 'seed' | 'liquid';
  setDeviceTypeFilter: (val: 'any' | 'seed' | 'liquid') => void;

  loadedProductFilter: boolean;
  setLoadedProductFilter: (val: boolean) => void;

  productNameFilter: string;
  setProductNameFilter: (val: string) => void;

  handleClearFilters: () => void;
}

export default function DeviceFilterDialog({
  open,
  onClose,
  productNames,
  batteryBelow50,
  setBatteryBelow50,
  fillFilter,
  setFillFilter,
  timeFilter,
  setTimeFilter,
  deviceTypeFilter,
  setDeviceTypeFilter,
  loadedProductFilter,
  setLoadedProductFilter,
  productNameFilter,
  setProductNameFilter,
  handleClearFilters,
}: DeviceFilterDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ sx: { borderRadius: 3 } }}>
      <DialogTitle sx={{ fontWeight: 'bold' }}>Filter Devices</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={batteryBelow50} onChange={() => setBatteryBelow50(!batteryBelow50)} />}
              label="Battery < 50%"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Fill Status</FormLabel>
              <RadioGroup
                value={fillFilter}
                onChange={(e) => setFillFilter(e.target.value as 'any' | 'full' | 'empty')}
              >
                <FormControlLabel value="any" control={<Radio />} label="Any" />
                <FormControlLabel value="full" control={<Radio />} label="Full Only" />
                <FormControlLabel value="empty" control={<Radio />} label="Empty Only" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Last Seen</FormLabel>
              <RadioGroup
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value as 'any' | '24h' | '7d' | '30d')}
              >
                <FormControlLabel value="any" control={<Radio />} label="Any Time" />
                <FormControlLabel value="24h" control={<Radio />} label="< 24 Hours" />
                <FormControlLabel value="7d" control={<Radio />} label="≥ 7 Days" />
                <FormControlLabel value="30d" control={<Radio />} label="≥ 30 Days" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Device Type</InputLabel>
              <Select
                value={deviceTypeFilter}
                label="Device Type"
                onChange={(e) => setDeviceTypeFilter(e.target.value as 'any' | 'seed' | 'liquid')}
              >
                <MenuItem value="any">Any</MenuItem>
                <MenuItem value="seed">Seed</MenuItem>
                <MenuItem value="liquid">Liquid</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox checked={loadedProductFilter} onChange={(e) => setLoadedProductFilter(e.target.checked)} />
              }
              label="Loaded with Product"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Product</InputLabel>
              <Select value={productNameFilter} label="Product" onChange={(e) => setProductNameFilter(e.target.value)}>
                <MenuItem value="any">Any</MenuItem>
                {productNames.map((pn) => (
                  <MenuItem key={pn} value={pn}>
                    {pn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClearFilters} sx={{ borderRadius: 3, mr: 1 }}>
          Clear
        </Button>
        <Button variant="contained" onClick={onClose} sx={{ borderRadius: 3 }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
