import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoBulkTransaction } from '../model';

interface ProductsContextInterface {
  errorMessage?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  fetchProducts?: (combined: boolean, filterParams?: string) => Promise<void>;
  fetchProductDetails?: (filterParams?: string) => Promise<void>;
  products?: AutoBulkTransaction[];
  productsLoading?: boolean;
  productMetrics?: Record<string, string>;
}

const ProductsContext = React.createContext<ProductsContextInterface>({});

const ProductsContextConsumer = ProductsContext.Consumer;
const ProductsContextProvider: React.FC = ({ children }) => {
  const [products, setProducts] = React.useState<AutoBulkTransaction[]>([]);
  const [productsLoading, setProductsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [productMetrics, setProductMetrics] = React.useState<Record<string, string>>({});

  const fetchProducts = async (combined: boolean, filterParams = '') => {
    setProductsLoading(true);
    setErrorMessage('');

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';

    // Get dates from localStorage and handle them to prevent timezone issues
    let cachedStartDate = localStorage.getItem('startDate');
    let cachedEndDate = localStorage.getItem('endDate');

    // If dates include time component or special formatting, extract just the date part
    if (cachedStartDate && (cachedStartDate.includes('T') || cachedStartDate.includes(' '))) {
      cachedStartDate = cachedStartDate.split(/[T\s]/)[0];
    }

    if (cachedEndDate && (cachedEndDate.includes('T') || cachedEndDate.includes(' '))) {
      cachedEndDate = cachedEndDate.split(/[T\s]/)[0];
    }

    // Add noon time to prevent timezone boundary issues
    const startDateForApi = cachedStartDate ? `${cachedStartDate} 12:00:00` : '';
    const endDateForApi = cachedEndDate ? `${cachedEndDate} 12:00:00` : '';

    let timeParams = {};

    if (cachedStartDate && cachedEndDate) {
      timeParams =
        cachedTimePeriod !== 'Custom'
          ? { time_period: cachedTimePeriod || '' }
          : {
              // Use dates with noon time to prevent timezone boundary issues
              work_order_start_date_after: startDateForApi,
              work_order_start_date_before: endDateForApi,
            };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get<string, any>(`api/autobulk/analytics/transactions/products${combined ? '/combined' : ''}/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setProducts(response.data.results);
        setProductsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setProducts([]);
        setProductsLoading(false);
      });
  };

  const fetchProductDetails = async (filterParams = '') => {
    setProductsLoading(true);
    setErrorMessage('');

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';

    // Get dates from localStorage and handle them to prevent timezone issues
    let cachedStartDate = localStorage.getItem('startDate');
    let cachedEndDate = localStorage.getItem('endDate');

    // If dates include time component or special formatting, extract just the date part
    if (cachedStartDate && (cachedStartDate.includes('T') || cachedStartDate.includes(' '))) {
      cachedStartDate = cachedStartDate.split(/[T\s]/)[0];
    }

    if (cachedEndDate && (cachedEndDate.includes('T') || cachedEndDate.includes(' '))) {
      cachedEndDate = cachedEndDate.split(/[T\s]/)[0];
    }

    // Add noon time to prevent timezone boundary issues
    const startDateForApi = cachedStartDate ? `${cachedStartDate} 12:00:00` : '';
    const endDateForApi = cachedEndDate ? `${cachedEndDate} 12:00:00` : '';

    let timeParams = {};

    if (cachedStartDate && cachedEndDate) {
      timeParams =
        cachedTimePeriod !== 'Custom'
          ? { time_period: cachedTimePeriod || '' }
          : {
              // Use dates with noon time to prevent timezone boundary issues
              work_order_start_date_after: startDateForApi,
              work_order_start_date_before: endDateForApi,
            };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get<string, any>(`api/autobulk/analytics/transactions/product_details/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setProducts(response.data.results);
        setProductsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setProducts([]);
        setProductsLoading(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview,
    combineMachineTotals,
  ) => {
    setProductsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    axios
      .post<any, any>(
        `api/autobulk/analytics/exports/products`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          filterType,
          yearlyOverview,
          combineMachineTotals,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setProductsLoading(false);
      })
      .catch((error) => {
        setProductsLoading(false);
      });
  };

  return (
    <ProductsContext.Provider
      value={{
        errorMessage,
        exportTransactions,
        fetchProducts,
        fetchProductDetails,
        productMetrics,
        products,
        productsLoading,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContextProvider, ProductsContextConsumer, ProductsContext };
