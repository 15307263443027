import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../utils/axios.utils';
import DataTable from '../../dataTable/DataTable/DataTable';

interface RecipeItem {
  autotreat_mfr_recipe_item_cloud_id: number;
  global_recipe_id: string;
  global_liquid_id: string;
  liquid_rate: string;
  liquid_rate_type_id: string;
  liquid_name?: string;
  liquid_rate_type?: string;
  locked: boolean;
  active: boolean;
  obsolete: boolean;
}

interface RecipeData {
  global_recipe_id: string;
  global_mfr_id: string;
  liquid_recipe_name: string;
  liquid_recipe_desc: string;
  crop_id: string;
  recipe_volume_max: string;
  recipe_volume_recommended: string;
  seed_cnt_per_scale_uow: string;
  unit_size_cnt: string;
  locked: boolean;
  active: boolean;
  recipe_items: RecipeItem[];
}

const EditMfrRecipeForm: React.FC<{ recipeId: string; onSuccess: () => void }> = ({ recipeId, onSuccess }) => {
  const [formValues, setFormValues] = useState<RecipeData>({
    global_recipe_id: '',
    global_mfr_id: '',
    liquid_recipe_name: '',
    liquid_recipe_desc: '',
    crop_id: '',
    recipe_volume_max: '',
    recipe_volume_recommended: '',
    seed_cnt_per_scale_uow: '',
    unit_size_cnt: '',
    locked: false,
    active: true,
    recipe_items: [],
  });

  const [recipeItems, setRecipeItems] = useState<RecipeItem[]>([]);
  const [mfrLiquids, setMfrLiquids] = useState<any[]>([]);
  const [liquidRateTypes, setLiquidRateTypes] = useState<any[]>([]);
  const [crops, setCrops] = useState<any[]>([]);
  const [globalMfrs, setGlobalMfrs] = useState<any[]>([]);
  const [newRecipeItem, setNewRecipeItem] = useState({
    mfr_liquid: '',
    liquid_rate: '',
    liquid_rate_type_id: '',
  });

  const [originalGlobalRecipeId, setOriginalGlobalRecipeId] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecipeData = async () => {
      try {
        const response = await axios.get(`/api/admin/autotreat_mfr/mfr_recipes/${recipeId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        });

        const recipeData = response.data as Partial<RecipeData>;

        console.log('Fetched recipe data:', recipeData);

        // Store the original global_recipe_id
        setOriginalGlobalRecipeId(recipeData.global_recipe_id || null);

        // Ensure recipe items have liquid_rate_type_id
        const updatedRecipeItems =
          recipeData.recipe_items?.map((item) => ({
            ...item,
            liquid_rate_type_id:
              item.liquid_rate_type_id ?? (liquidRateTypes.length > 0 ? liquidRateTypes[0].liquid_rate_type_id : ''),
          })) || [];

        setFormValues((prevValues) => ({
          ...prevValues,
          ...recipeData,
          crop_id: (recipeData as any).crop?.crop_id?.toString() ?? '',
        }));

        setRecipeItems(updatedRecipeItems);
      } catch (error) {
        console.error('Error fetching recipe:', error);
        alert('Failed to load recipe. Please check your connection.');
      }
    };

    const fetchDropdownData = async () => {
      try {
        const authHeaders = {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        };

        const [mfrs, crops, liquids, rateTypes] = await Promise.all([
          axios.get('/api/admin/autotreat_mfr/mfrs/', authHeaders),
          axios.get('/api/admin/autotreat_mfr/crops/', authHeaders),
          axios.get('/api/admin/autotreat_mfr/mfr_liquids/', authHeaders),
          axios.get('/api/admin/autotreat_mfr/liquid_rate_types/', authHeaders),
        ]);

        setGlobalMfrs(mfrs.data);
        setCrops(crops.data);
        setMfrLiquids(liquids.data);
        setLiquidRateTypes(rateTypes.data);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchRecipeData();
    fetchDropdownData();
  }, [recipeId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({ ...formValues, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleAddRecipeItem = () => {
    if (newRecipeItem.mfr_liquid && newRecipeItem.liquid_rate && newRecipeItem.liquid_rate_type_id) {
      const selectedLiquid = mfrLiquids.find((l) => l.global_liquid_id === newRecipeItem.mfr_liquid);
      const selectedRateType = liquidRateTypes.find(
        (type) => type.liquid_rate_type_id === newRecipeItem.liquid_rate_type_id,
      );

      const newItem: RecipeItem = {
        autotreat_mfr_recipe_item_cloud_id: recipeItems.length + 1,
        global_recipe_id: formValues.global_recipe_id,
        global_liquid_id: newRecipeItem.mfr_liquid,
        liquid_name: selectedLiquid ? selectedLiquid.liquid_name : 'Unknown',
        liquid_rate: newRecipeItem.liquid_rate,
        liquid_rate_type_id: newRecipeItem.liquid_rate_type_id,
        liquid_rate_type: selectedRateType ? selectedRateType.liquid_rate_type : 'Unknown',
        locked: false,
        active: true,
        obsolete: false,
      };

      setRecipeItems([...recipeItems, newItem]);

      // Reset the input fields
      setNewRecipeItem({ mfr_liquid: '', liquid_rate: '', liquid_rate_type_id: '' });
    } else {
      alert('Please fill out all fields before adding a recipe item.');
    }
  };

  const handleRemoveRecipeItem = (itemId: number) => {
    setRecipeItems((prevItems) => prevItems.filter((item) => item.autotreat_mfr_recipe_item_cloud_id !== itemId));
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this recipe?')) return;

    try {
      await axios.delete(`/api/admin/autotreat_mfr/mfr_recipes/${recipeId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      onSuccess();
    } catch (error) {
      console.error('Error deleting recipe:', error);
      alert('Failed to delete recipe.');
    }
  };

  const handleUpdate = async () => {
    if (!formValues.liquid_recipe_name.trim()) {
      alert('Recipe Name is required.');
      return;
    }

    // Ensure all recipe items contain liquid_rate_type_id
    const updatedRecipeItems = recipeItems.map((item) => ({
      ...item,
      liquid_rate_type_id: item.liquid_rate_type_id || '',
    }));

    // Create the updated payload without global_recipe_id unless it changed
    const updatedData: Partial<RecipeData> = {
      ...formValues,
      recipe_items: updatedRecipeItems,
    };

    if (originalGlobalRecipeId !== formValues.global_recipe_id) {
      updatedData.global_recipe_id = formValues.global_recipe_id;
    } else {
      delete formValues.global_recipe_id;
    }

    try {
      await axios.put(`/api/admin/autotreat_mfr/mfr_recipes/${recipeId}/`, updatedData, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      onSuccess();
    } catch (error) {
      console.error('Error updating recipe:', error);
      alert('Failed to update recipe.');
    }
  };

  return (
    <form style={{ maxWidth: '70%', margin: 'auto', textAlign: 'left' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDelete}
        style={{ position: 'absolute', top: '40px', right: '150px' }}
      >
        Delete
      </Button>

      <Typography variant="h4" gutterBottom>
        Edit Recipe
      </Typography>

      <Typography variant="h1" gutterBottom>
        Edit Recipe
      </Typography>

      {/* Small "Return to Recipe List" Link */}
      <Typography
        variant="body2"
        style={{ cursor: 'pointer', color: 'lightgrey', marginBottom: '20px' }}
        onClick={() => window.history.back()}
      >
        ← Return to Recipe List
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Global Recipe ID"
            name="global_recipe_id"
            value={formValues.global_recipe_id}
            onChange={handleInputChange}
            fullWidth
            multiline
          />
        </Grid>

        {/* Select Global MFR ID - Dropdown */}
        <Grid item xs={12} sm={4}>
          <Select
            value={formValues.global_mfr_id}
            onChange={(e) => handleSelectChange('global_mfr_id', e.target.value as string)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Global MFR
            </MenuItem>
            {globalMfrs.map((mfr) => (
              <MenuItem key={mfr.global_mfr_id} value={mfr.global_mfr_id}>
                {mfr.mfr_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Liquid Recipe Name"
            name="liquid_recipe_name"
            value={formValues.liquid_recipe_name}
            onChange={handleInputChange}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Liquid Recipe Description"
            name="liquid_recipe_desc"
            value={formValues.liquid_recipe_desc}
            onChange={handleInputChange}
            fullWidth
            multiline
          />
        </Grid>

        {/* Select Crop - Dropdown */}
        <Grid item xs={12} sm={4}>
          <Select
            value={formValues.crop_id}
            onChange={(e) => handleSelectChange('crop_id', e.target.value as string)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Crop
            </MenuItem>
            {crops.map((crop) => (
              <MenuItem key={crop.crop_id} value={crop.crop_id.toString()}>
                {' '}
                {crop.name}{' '}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Recipe Volume Max"
            name="recipe_volume_max"
            value={formValues.recipe_volume_max}
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Recipe Volume Recommended"
            name="recipe_volume_recommended"
            value={formValues.recipe_volume_recommended}
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Seed CNT Per Scale UOW"
            name="seed_cnt_per_scale_uow"
            value={formValues.seed_cnt_per_scale_uow}
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Unit Size CNT"
            name="unit_size_cnt"
            value={formValues.unit_size_cnt}
            onChange={handleInputChange}
            fullWidth
            type="number"
          />
        </Grid>

        {/* Checkboxes: Locked & Active */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formValues.locked} onChange={handleInputChange} name="locked" />}
            label="Locked"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={formValues.active} onChange={handleInputChange} name="active" />}
            label="Active"
          />
        </Grid>

        {/* Recipe Items Label */}
        <Grid item xs={12}>
          <Typography variant="h6">Recipe Items</Typography>
        </Grid>

        {/* Recipe Items Inputs */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Select
              value={newRecipeItem.mfr_liquid}
              onChange={(e) => setNewRecipeItem({ ...newRecipeItem, mfr_liquid: e.target.value })}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select MFR Liquid
              </MenuItem>
              {mfrLiquids.map((liquid) => (
                <MenuItem key={liquid.global_liquid_id} value={liquid.global_liquid_id}>
                  {liquid.liquid_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Liquid Rate"
              value={newRecipeItem.liquid_rate}
              onChange={(e) => setNewRecipeItem({ ...newRecipeItem, liquid_rate: e.target.value })}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              value={newRecipeItem.liquid_rate_type_id}
              onChange={(e) => setNewRecipeItem({ ...newRecipeItem, liquid_rate_type_id: e.target.value })}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Liquid Rate Type
              </MenuItem>
              {liquidRateTypes.map((type) => (
                <MenuItem key={type.liquid_rate_type_id} value={type.liquid_rate_type_id}>
                  {type.liquid_rate_type}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <Button variant="contained" color="primary" onClick={handleAddRecipeItem} fullWidth>
              Add
            </Button>
          </Grid>
        </Grid>

        {/* Recipe Items Table */}
        <DataTable
          columns={[
            { name: 'Global Liquid ID', selector: (row) => row.global_liquid_id, sortable: true },
            { name: 'Liquid Name', selector: (row) => row.liquid_name, sortable: true },
            { name: 'Liquid Rate', selector: (row) => row.liquid_rate, sortable: true },
            { name: 'Liquid Rate Type', selector: (row) => row.liquid_rate_type, sortable: true },
            {
              name: 'Actions',
              cell: (row) => (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => handleRemoveRecipeItem(row.autotreat_mfr_recipe_item_cloud_id)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ),
            },
          ]}
          data={recipeItems}
          pagination
        />

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>

          <Button variant="contained" color="primary" onClick={() => window.history.back()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditMfrRecipeForm;
