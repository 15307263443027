import React from 'react';
import { Grid, Stack, TextField, Button, Divider, Typography, Alert } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

interface AssociatedCompanyFormData {
  name: string;
  main_contact: string;
  main_contact_phone: string;
  main_contact_email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface AssociatedCompanyFormContentProps {
  formData: AssociatedCompanyFormData;
  setFormData: (data: AssociatedCompanyFormData) => void;
  onSave: () => void;
  onCancel: () => void;
}

const AssociatedCompanyFormContent: React.FC<AssociatedCompanyFormContentProps> = ({
  formData,
  setFormData,
  onSave,
  onCancel,
}) => {
  const handleChange = (field: keyof AssociatedCompanyFormData, value: string | number) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      {/* Informational Note */}
      <Alert severity="info" icon={<InfoOutlined />} sx={{ mb: 2 }}>
        This will autocreate a new company and send an invitation to the main contact using the provided email, allowing
        them to provision a new AgConnex Account.
      </Alert>

      {/* 1) Company Name Section */}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            label="Company Name"
            value={formData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* 2) Contact Details Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Main Contact"
            value={formData.main_contact}
            onChange={(e) => handleChange('main_contact', e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone"
            value={formData.main_contact_phone}
            onChange={(e) => handleChange('main_contact_phone', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={formData.main_contact_email}
            onChange={(e) => handleChange('main_contact_email', e.target.value)}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* 3) Address Details Section */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Address"
            value={formData.address}
            onChange={(e) => handleChange('address', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="City"
            value={formData.city}
            onChange={(e) => handleChange('city', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="State"
            value={formData.state}
            onChange={(e) => handleChange('state', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Zip" value={formData.zip} onChange={(e) => handleChange('zip', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Country"
            value={formData.country}
            onChange={(e) => handleChange('country', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Save/Cancel Buttons */}
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default AssociatedCompanyFormContent;
