// src/devices/forms/DeviceTankAssignmentFormContent.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
  Divider,
} from '@mui/material';

interface TankConfigOption {
  tank_name: string;
  tank_type_name: string;
  uom_name: string;
  rectangle_length: number;
  rectangle_width: number;
  rectangle_height: number;
  rectangle_empty_space: number;
}

interface DeviceTankAssignmentFormContentProps {
  deviceSerial: string;
  onSaved?: () => void;
  onChangeError?: (msg: string | null) => void;
  onChangeSuccessMessage?: (msg: string | null) => void;
  onClose?: any;
}

const DeviceTankAssignmentFormContent: React.FC<DeviceTankAssignmentFormContentProps> = ({
  deviceSerial,
  onSaved,
  onChangeError,
  onChangeSuccessMessage,
}) => {
  const [tankConfigs, setTankConfigs] = useState<TankConfigOption[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<string>('');

  // Hard-coded for demo
  const dummyConfigs: TankConfigOption[] = [
    {
      tank_name: 'IBC Cage Tote 275 Gal',
      tank_type_name: 'Rectangle',
      uom_name: 'Inches',
      rectangle_length: 46,
      rectangle_width: 40,
      rectangle_height: 48,
      rectangle_empty_space: 0,
    },
    {
      tank_name: 'IBC Cage Tote 330 Gal',
      tank_type_name: 'Rectangle',
      uom_name: 'Inches',
      rectangle_length: 53,
      rectangle_width: 40,
      rectangle_height: 48,
      rectangle_empty_space: 0,
    },
  ];

  useEffect(() => {
    // In real code, you could fetch from API
    setTankConfigs(dummyConfigs);
  }, []);

  const currentConfig = tankConfigs.find((tc) => tc.tank_name === selectedConfig);

  // Called by parent "onSave"
  const handleSubmit = () => {
    // In real scenario, post to server
    onChangeSuccessMessage?.('Tank config updated successfully! (Prototype)');
    onSaved?.();
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Edit Tank Config for Device {deviceSerial}
      </Typography>

      <Paper sx={{ p: 3, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Select Tank Config</InputLabel>
          <Select value={selectedConfig} onChange={(e) => setSelectedConfig(e.target.value as string)}>
            {tankConfigs.map((cfg) => (
              <MenuItem key={cfg.tank_name} value={cfg.tank_name}>
                {cfg.tank_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {currentConfig && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Selected Tank Details
            </Typography>
            <Stack spacing={0.5}>
              <Typography variant="body2">
                <strong>Tank Type:</strong> {currentConfig.tank_type_name}
              </Typography>
              <Typography variant="body2">
                <strong>UOM:</strong> {currentConfig.uom_name}
              </Typography>
            </Stack>
          </Box>
        )}
      </Paper>

      <Divider sx={{ my: 2 }} />

      {/* The parent's "onSave" calls handleSubmit */}
      <Button variant="contained" onClick={handleSubmit} disabled={!selectedConfig}>
        Save
      </Button>
    </Box>
  );
};

export default DeviceTankAssignmentFormContent;
