import React from 'react';
import { Button, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ApplicationScreenshot from '../../../assets/application-screenshot.png';
import Footer from '../../navigation/components/Footer';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomePage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();
  const redirectUrl = query.get('redirect_url');

  return (
    <>
      <Grid
        container
        alignContent="center"
        style={{
          backgroundImage: `url("https://www.ksiedge.com/wp-content/uploads/IMG_3944.jpg")`,
          backgroundPosition: 'center 75%',
          height: 800,
          boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
        }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <h2 style={{ fontSize: 36, color: 'white' }}>Actionable Insights for All Your Data</h2>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              loginWithRedirect({
                appState: { redirectUrl: redirectUrl || '/' },
              })
            }
          >
            Login Now
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default HomePage;
