/* eslint-disable react-hooks/exhaustive-deps, no-alert */
import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Machine } from '../model';
import deviceDisplayColumns from '../constants/deviceDisplayColumns';
import inputDeviceColumns from '../constants/inputDeviceColumns';
import outputDeviceColumns from '../constants/outputDeviceColumns';
import { MachineDevicesAutoTreatContext } from '../contexts/MachineDevicesContext';
// import mockDeviceDisplayData from '../constants/mockDeviceDisplayData';
// import mockInputDeviceData from '../constants/mockInputDeviceData';
// import mockOutputDeviceData from '../constants/mockOutputDeviceData';

interface MachineDigitalIOProps {
  machine: Machine;
}

const MachineDigitalIO: React.FC<MachineDigitalIOProps> = (props) => {
  const { machine } = props;

  // State variables for loading and data
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceDisplayData, setDeviceDisplayData] = useState<any[]>([]);
  const [inputDeviceData, setInputDeviceData] = useState<any[]>([]);
  const [outputDeviceData, setOutputDeviceData] = useState<any[]>([]);

  const {
    fetchMachineDevicesAutoTreat,
    fetchMachineInputDevicesAutoTreat,
    fetchMachineOutputDevicesAutoTreat,
    machineDevices,
    machineDevicesLoading,
    machineInputDevices,
    machineInputDevicesLoading,
    machineOutputDevices,
    machineOutputDevicesLoading,
  } = React.useContext(MachineDevicesAutoTreatContext);

  useEffect(() => {
    if (machine?.machine_id) {
      // fetchMachineDeviceData();
      fetchMachineDevicesAutoTreat(machine?.serial_number);
      fetchMachineInputDevicesAutoTreat(machine?.serial_number);
      fetchMachineOutputDevicesAutoTreat(machine?.serial_number);
      // setIsLoading(false);
    }
  }, [machine?.machine_id]);

  return !machine?.machine_id ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <h2 style={{ fontSize: '18px', marginTop: -200 }}>Please select a machine to begin</h2>
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h2" component="h2">
              Device Display
            </Typography>
            <br />
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              noHeader
              columns={deviceDisplayColumns}
              data={machineDevices}
              defaultSortField="SoftwareSerial"
              defaultSortAsc={false}
              striped
              highlightOnHover
              pointerOnHover
              progressPending={machineDevicesLoading}
              progressComponent={
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress />
                </Box>
              }
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h2" component="h2">
              Input Card Devices
            </Typography>
            <br />
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              noHeader
              columns={inputDeviceColumns}
              data={machineInputDevices}
              defaultSortField="DigitalInputID"
              defaultSortAsc
              striped
              highlightOnHover
              pointerOnHover
              progressPending={machineInputDevicesLoading}
              progressComponent={
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress />
                </Box>
              }
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h2" component="h2">
              Output Card Devices
            </Typography>
            <br />
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              noHeader
              columns={outputDeviceColumns}
              data={machineOutputDevices}
              defaultSortField="DigitalOutputID"
              defaultSortAsc
              striped
              highlightOnHover
              pointerOnHover
              progressPending={machineOutputDevicesLoading}
              progressComponent={
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress />
                </Box>
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MachineDigitalIO;
