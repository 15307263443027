/**
 * ProductMetricsPage.tsx
 *
 * This layout is a single-column design:
 * 1. Header + Search
 * 2. Products Summary Table
 * 3. Detailed Info (devices, tickets) below
 */

import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from '../../../utils/axios.utils';

import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Divider,
  Stack,
  TextField,
  styled,
} from '@mui/material';

// ------------------------------------
// Types for the First Endpoint
// ------------------------------------
interface ProductSummary {
  product_name: string;
  device_units: number;
  ticket_units: number;
}

interface ProductSummaryResponse {
  success: boolean;
  data: ProductSummary[];
}

// ------------------------------------
// Types for the Second Endpoint
// (the "detailed" product info)
// ------------------------------------
interface Device {
  device_serial_number: string;
  product_name: string;
  crop: string;
  lot_number: string;
  batch_number: string;
  seed_treatment_package: string;
  additional_notes: string;
  seed_unit_size: string;
  seed_size: string;
  seed_units: string;
}

interface TicketItem {
  product_name: string;
  crop: string;
  lot_number: string;
  batch_number: string;
  seed_treatment_package: string;
  additional_notes: string;
  seed_unit_size: string;
  seed_size: string;
  seed_units: string;
}

interface Ticket {
  edgelink_delivery_ticket_id: number;
  delivery_ticket_number: string;
  delivered_at: string;

  edgelink_primary_company_id?: number;
  edgelink_primary_company_name?: string;
  edgelink_secondary_company_id?: number;
  edgelink_secondary_company_name?: string;
  edgelink_tertiary_company_id?: number;
  edgelink_tertiary_company_name?: string;

  items: TicketItem[];
}

interface ProductDetailResponse {
  success: boolean;
  data: {
    devices: Device[];
    tickets: Ticket[];
  };
}

// ------------------------------------
// A styled container for a subtle background
// ------------------------------------
const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  // A softer, modern background:
  background: `linear-gradient(
    135deg,
    ${theme.palette.grey[100]} 0%,
    ${theme.palette.grey[200]} 50%,
    ${theme.palette.grey[100]} 100%
  )`,
  padding: theme.spacing(3),
}));

// ------------------------------------
// Main Component
// ------------------------------------
const ProductMetricsPage: React.FC = () => {
  // State: For the first endpoint (summary)
  const [products, setProducts] = useState<ProductSummary[]>([]);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summaryError, setSummaryError] = useState('');

  // State: For the second endpoint (detail)
  const [selectedProductDetail, setSelectedProductDetail] = useState<{ devices: Device[]; tickets: Ticket[] } | null>(
    null,
  );
  const [detailLoading, setDetailLoading] = useState(false);
  const [detailError, setDetailError] = useState('');

  // State: For the search/filter
  const [searchTerm, setSearchTerm] = useState('');

  // ------------------------------------
  // Fetch the summary on mount
  // ------------------------------------
  useEffect(() => {
    const fetchSummary = async () => {
      setSummaryLoading(true);
      setSummaryError('');
      try {
        // *** Adjust your axios config or token usage as needed
        const response = await axios.get<ProductSummaryResponse>('/api/user/edgelink/product_metrics/', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        });
        if (response.data.success && Array.isArray(response.data.data)) {
          setProducts(response.data.data);
        } else {
          setProducts([]);
          setSummaryError('Failed to load product summary');
        }
      } catch (err) {
        console.error(err);
        setProducts([]);
        setSummaryError('Error fetching product summary');
      } finally {
        setSummaryLoading(false);
      }
    };
    fetchSummary();
  }, []);

  // ------------------------------------
  // Handler: Fetch detail for a particular product
  // ------------------------------------
  const handleViewProduct = async (productName: string) => {
    setSelectedProductDetail(null);
    setDetailLoading(true);
    setDetailError('');

    try {
      const response = await axios.get<ProductDetailResponse>(
        `/api/user/edgelink/product_metrics/product/?product_name=${encodeURIComponent(productName)}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        },
      );
      if (response.data.success) {
        setSelectedProductDetail(response.data.data);
      } else {
        setSelectedProductDetail(null);
        setDetailError('Failed to load product detail');
      }
    } catch (err) {
      console.error(err);
      setSelectedProductDetail(null);
      setDetailError('Error fetching product detail');
    } finally {
      setDetailLoading(false);
    }
  };

  // ------------------------------------
  // Handler: Search / Filter
  // ------------------------------------
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // ------------------------------------
  // Filter products based on search term
  // ------------------------------------
  const filteredProducts = products.filter((p) => p.product_name.toLowerCase().includes(searchTerm.toLowerCase()));

  // ------------------------------------
  // Render: Product summary table
  // ------------------------------------
  const renderSummaryTable = () => {
    if (summaryLoading) {
      return (
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      );
    }
    if (summaryError) {
      return (
        <Alert severity="error" sx={{ my: 2 }}>
          {summaryError}
        </Alert>
      );
    }
    if (products.length === 0) {
      return <Typography>No products found.</Typography>;
    }

    return (
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Inventory Seed Units</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Ticket Units</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProducts.map((p) => (
            <TableRow key={p.product_name}>
              <TableCell>{p.product_name}</TableCell>
              <TableCell>{p.device_units}</TableCell>
              <TableCell>{p.ticket_units}</TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => handleViewProduct(p.product_name)}>
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {filteredProducts.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="body2">No products match your search.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  // ------------------------------------
  // Render: Devices in a table
  // ------------------------------------
  const renderDevicesTable = (devices: Device[]) => {
    if (devices.length === 0) {
      return <Typography variant="body2">No devices for this product.</Typography>;
    }
    return (
      <Table size="small" sx={{ mb: 3 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Device Serial</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Crop</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Lot #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Batch #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Treatment</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Notes</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Unit Size</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Seed Size</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}># Units</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((dev) => (
            <TableRow key={dev.device_serial_number}>
              <TableCell>{dev.device_serial_number}</TableCell>
              <TableCell>{dev.crop}</TableCell>
              <TableCell>{dev.lot_number}</TableCell>
              <TableCell>{dev.batch_number}</TableCell>
              <TableCell>{dev.seed_treatment_package}</TableCell>
              <TableCell>{dev.additional_notes || '—'}</TableCell>
              <TableCell>{dev.seed_unit_size}</TableCell>
              <TableCell>{dev.seed_size}</TableCell>
              <TableCell>{dev.seed_units}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  // ------------------------------------
  // Render: Tickets in a single table, flattened by items
  // Each item => one row
  // ------------------------------------
  const renderTicketsTable = (tickets: Ticket[]) => {
    // Flatten all ticket items
    const allItems = tickets.flatMap((tk) =>
      tk.items.map((item) => ({
        ticket: tk,
        item,
      })),
    );

    if (allItems.length === 0) {
      return <Typography variant="body2">No tickets for this product.</Typography>;
    }

    return (
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
            <TableCell sx={{ fontWeight: 'bold' }}>Ticket #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Delivered At</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Primary Company</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Secondary Company</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Tertiary Company</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Crop</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Lot #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Batch #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Seed Treat Package</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Notes</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Unit Size</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Seed Size</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}># Units</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allItems.map(({ ticket, item }, idx) => (
            <TableRow key={`${ticket.edgelink_delivery_ticket_id}-${idx}`}>
              <TableCell>{ticket.delivery_ticket_number || <em>(none)</em>}</TableCell>
              <TableCell>{new Date(ticket.delivered_at).toLocaleString() || '—'}</TableCell>
              <TableCell>
                {ticket.edgelink_primary_company_name ? `${ticket.edgelink_primary_company_name}` : '—'}
              </TableCell>
              <TableCell>
                {ticket.edgelink_secondary_company_name ? `${ticket.edgelink_secondary_company_name}` : '—'}
              </TableCell>
              <TableCell>
                {ticket.edgelink_tertiary_company_name ? `${ticket.edgelink_tertiary_company_name}` : '—'}
              </TableCell>
              <TableCell>{item.product_name}</TableCell>
              <TableCell>{item.crop}</TableCell>
              <TableCell>{item.lot_number}</TableCell>
              <TableCell>{item.batch_number}</TableCell>
              <TableCell>{item.seed_treatment_package || '—'}</TableCell>
              <TableCell>{item.additional_notes || '—'}</TableCell>
              <TableCell>{item.seed_unit_size}</TableCell>
              <TableCell>{item.seed_size}</TableCell>
              <TableCell>{item.seed_units}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  // ------------------------------------
  // Render: Main detail section
  // ------------------------------------
  const renderProductDetail = () => {
    if (detailLoading) {
      return (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      );
    }
    if (detailError) {
      return (
        <Alert severity="error" sx={{ my: 2 }}>
          {detailError}
        </Alert>
      );
    }
    if (!selectedProductDetail) {
      return null; // No detail if nothing is selected
    }

    const { devices, tickets } = selectedProductDetail;

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Product Detail
        </Typography>

        {/* Devices Table */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          Devices
        </Typography>
        {renderDevicesTable(devices)}

        <Divider sx={{ mb: 3 }} />

        {/* Tickets Table */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          Tickets
        </Typography>
        {renderTicketsTable(tickets)}
      </Box>
    );
  };

  // ------------------------------------
  // Main return
  // ------------------------------------
  return (
    <PageContainer>
      {/* Search bar */}
      <Paper
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: '#ffffffcc',
          backdropFilter: 'blur(4px)',
          mb: 3,
        }}
        elevation={6}
      >
        <TextField
          label="Search by Product Name"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Paper>

      {/* Products Table */}
      <Paper
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: '#ffffffcc',
          backdropFilter: 'blur(4px)',
          mb: 4,
        }}
        elevation={6}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
          Products Summary
        </Typography>
        {renderSummaryTable()}
      </Paper>

      {/* Detailed Info (shown only if selected) */}
      {detailError && (
        <Alert severity="error" sx={{ my: 2 }}>
          {detailError}
        </Alert>
      )}
      {detailLoading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        selectedProductDetail && (
          <Paper
            sx={{
              p: 2,
              borderRadius: 3,
              backgroundColor: '#ffffffcc',
              backdropFilter: 'blur(4px)',
            }}
            elevation={6}
          >
            {renderProductDetail()}
          </Paper>
        )
      )}
    </PageContainer>
  );
};

export default ProductMetricsPage;
