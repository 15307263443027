// src/devices/forms/DeviceGeoFencingFormContent.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Paper,
  Typography,
  LinearProgress,
  Stack,
  TextField,
  Grid,
  Button,
  Alert,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import axios from '../../../utils/axios.utils';

interface Device {
  edgelink_device_id: number;
  device_serial_number: string;
  gps_lat?: number | string | null;
  gps_long?: number | string | null;
}

interface DeviceGeoFencingFormContentProps {
  deviceSerial: string;
  onSaved?: () => void;
  onChangeError?: (msg: string | null) => void;
  onChangeSuccessMessage?: (msg: string | null) => void;
  onClose?: any;
}

const containerStyle = {
  width: '100%',
  height: '400px',
};

const DeviceGeoFencingFormContent: React.FC<DeviceGeoFencingFormContentProps> = ({
  deviceSerial,
  onSaved,
  onChangeError,
  onChangeSuccessMessage,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [geofencingEnabled, setGeofencingEnabled] = useState<boolean>(true);
  const [geofenceName, setGeofenceName] = useState<string>('My Geofence');
  const [notificationEmails, setNotificationEmails] = useState<string>('someuser@example.com; another@example.com');
  const [latitude, setLatitude] = useState<number>(39.335242);
  const [longitude, setLongitude] = useState<number>(-93.289752);
  const [radiusMiles, setRadiusMiles] = useState<number>(5.0);

  const circleRef = useRef<google.maps.Circle | null>(null);

  // Fetch device and set map defaults
  useEffect(() => {
    let ignore = false;
    const fetchDevice = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get<any, any>(
          `/api/user/edgelink/devices/dashboard?device_serial_number=${deviceSerial}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            },
          },
        );
        if (!ignore) {
          if (response.data.success && response.data.results.length > 0) {
            const foundDevice = response.data.results[0];
            setDevice(foundDevice);
            // Use device's GPS if available
            if (foundDevice.gps_lat && foundDevice.gps_long) {
              setLatitude(Number(foundDevice.gps_lat) || 39.335242);
              setLongitude(Number(foundDevice.gps_long) || -93.289752);
            }
          } else {
            setError('No device found for this serial.');
          }
        }
      } catch (err: any) {
        if (!ignore) {
          console.error(err);
          setError('Failed to load device data.');
        }
      } finally {
        if (!ignore) setLoading(false);
      }
    };

    fetchDevice();
    return () => {
      ignore = true;
    };
  }, [deviceSerial]);

  const circleRadiusMeters = radiusMiles * 1609.34;

  const onMarkerDragEnd = useCallback((e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
  }, []);

  const onCircleLoad = (circle: google.maps.Circle) => {
    circleRef.current = circle;
  };

  const onCircleRadiusChanged = () => {
    if (!circleRef.current) return;
    const newMeters = circleRef.current.getRadius();
    if (newMeters) {
      const newMiles = newMeters / 1609.34;
      setRadiusMiles(newMiles);
    }
  };

  const handleSubmit = () => {
    // Example: just show a success
    setSuccessMessage('Geofencing settings would be saved here.');
    onChangeSuccessMessage?.('Geofencing saved (prototype).');
    onSaved?.();
  };

  return (
    <Box sx={{ maxWidth: 900, mx: 'auto', p: 1 }}>
      {loading && <LinearProgress sx={{ mb: 2 }} />}
      {error && <Alert severity="error">{error}</Alert>}
      {successMessage && (
        <Alert severity="success" sx={{ my: 2 }}>
          {successMessage}
        </Alert>
      )}

      {!loading && !error && device && (
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Set Geofencing for {deviceSerial}
          </Typography>

          <Alert severity="info" sx={{ mb: 2 }}>
            This is a prototype. The map is interactive, but fields are disabled.
          </Alert>

          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={geofencingEnabled}
                  onChange={(e) => setGeofencingEnabled(e.target.checked)}
                  color="primary"
                  disabled
                />
              }
              label="Geofencing Enabled"
            />
          </Box>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Geofence Name"
                variant="outlined"
                fullWidth
                value={geofenceName}
                onChange={(e) => setGeofenceName(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Notification Emails"
                variant="outlined"
                fullWidth
                value={notificationEmails}
                onChange={(e) => setNotificationEmails(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>

          <Divider sx={{ mb: 2 }} />

          <Box sx={{ width: '100%', height: 400, mb: 2 }}>
            <LoadScript googleMapsApiKey="AIzaSyA2IxUu6GtCHq9DUTuLuLgaSoYwodgblyE">
              <GoogleMap mapContainerStyle={containerStyle} center={{ lat: latitude, lng: longitude }} zoom={10}>
                <Marker draggable position={{ lat: latitude, lng: longitude }} onDragEnd={onMarkerDragEnd} />
                <Circle
                  center={{ lat: latitude, lng: longitude }}
                  radius={circleRadiusMeters}
                  onLoad={onCircleLoad}
                  onRadiusChanged={onCircleRadiusChanged}
                  options={{
                    fillColor: '#1976d233',
                    strokeColor: '#1976d2',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    editable: true,
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </Box>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={4}>
              <TextField label="Latitude" variant="outlined" fullWidth disabled value={latitude.toFixed(6)} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="Longitude" variant="outlined" fullWidth disabled value={longitude.toFixed(6)} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField label="Radius (Miles)" variant="outlined" fullWidth disabled value={radiusMiles.toFixed(2)} />
            </Grid>
          </Grid>

          <Alert severity="info" sx={{ mb: 2 }}>
            If geofencing is enabled, the system would notify {notificationEmails} if this device moves outside{' '}
            {radiusMiles.toFixed(2)} miles.
          </Alert>

          {/* The parent's "onSave" calls handleSubmit */}
          <Button variant="contained" onClick={handleSubmit} disabled>
            Save
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default DeviceGeoFencingFormContent;
