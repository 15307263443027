import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import OpacityIcon from '@mui/icons-material/Opacity';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import axios from '../../../utils/axios.utils';

// Shared Components
import GenericFormDialog from '../../sharedComponents/GenericFormDialog';

// Form Contents
import CompanyFormContent from '../forms/CompanyFormContent';
import AssociatedCompanyFormContent from '../forms/AssociatedCompanyFormContent';

// ---------------------------
// TypeScript Interfaces
// ---------------------------
interface SensorUom {
  sensor_uom_id: number;
  sensor_uom_name: string | null;
}

interface AssociatedCompany {
  edgelink_company_id: number;
  name: string;
  created_by: string;
  created_at: string;
}

interface CompanyDetails {
  edgelink_company_id: number;
  name: string;
  main_contact: string | null;
  main_contact_phone: string | null;
  main_contact_email: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  default_temp_sensor_uom: SensorUom | null;
  default_volume_sensor_uom: SensorUom | null;
  disable_public_access: number; // 0 or 1
  updated_by: string;
  updated_at: string;
  associated_companies: AssociatedCompany[];
}

interface CompanyFormData {
  name: string;
  main_contact: string;
  main_contact_phone: string;
  main_contact_email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  default_temp_sensor_uom: string;
  default_volume_sensor_uom: string;
  disable_public_access: number;
}

interface AssociatedCompanyFormData {
  name: string;
  main_contact: string;
  main_contact_phone: string;
  main_contact_email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

// ---------------------------
// Main Component
// ---------------------------
const CompanyDetailsPage: React.FC = () => {
  const history = useHistory();
  const [company, setCompany] = useState<CompanyDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  // ---------------------------
  // Dialog states
  // ---------------------------
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  // ---------------------------
  // Form states
  // ---------------------------
  const [companyFormData, setCompanyFormData] = useState<CompanyFormData>({
    name: '',
    main_contact: '',
    main_contact_phone: '',
    main_contact_email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    default_temp_sensor_uom: '',
    default_volume_sensor_uom: '',
    disable_public_access: 0,
  });

  const [assocFormData, setAssocFormData] = useState<AssociatedCompanyFormData>({
    name: '',
    main_contact: '',
    main_contact_phone: '',
    main_contact_email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  // ---------------------------
  // Fetch Company Details
  // ---------------------------
  const fetchCompany = () => {
    axios
      .get<any, any>('/api/user/edgelink/companies/0/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        if (response.data && response.data.results) {
          setCompany(response.data.results);
        } else {
          setError('Failed to fetch company details');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching company details:', err);
        setError('Error fetching company details');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  // ---------------------------
  // Handle "Update Company" open
  // ---------------------------
  const handleUpdateOpen = () => {
    if (company) {
      setCompanyFormData({
        name: company.name,
        main_contact: company.main_contact || '',
        main_contact_phone: company.main_contact_phone || '',
        main_contact_email: company.main_contact_email || '',
        address: company.address || '',
        city: company.city || '',
        state: company.state || '',
        zip: company.zip || '',
        country: company.country || '',
        default_temp_sensor_uom: company.default_temp_sensor_uom
          ? company.default_temp_sensor_uom.sensor_uom_id.toString()
          : '',
        default_volume_sensor_uom: company.default_volume_sensor_uom
          ? company.default_volume_sensor_uom.sensor_uom_id.toString()
          : '',
        disable_public_access: company.disable_public_access,
      });
    }
    setOpenUpdate(true);
  };
  const handleUpdateClose = () => {
    setOpenUpdate(false);
  };

  // ---------------------------
  // Handle "Update Company" save
  // ---------------------------
  const handleUpdateSave = () => {
    if (!company) return;

    axios
      .put(`/api/user/edgelink/companies/${company.edgelink_company_id}/`, companyFormData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then(() => {
        fetchCompany();
        setOpenUpdate(false);
      })
      .catch((err) => {
        console.error('Error updating company:', err);
      });
  };

  // ---------------------------
  // Handle "Create Associated Company"
  // ---------------------------
  const handleCreateOpen = () => {
    setAssocFormData({
      name: '',
      main_contact: '',
      main_contact_phone: '',
      main_contact_email: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    });
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    setOpenCreate(false);
  };
  const handleCreateSave = () => {
    if (!company) return;

    axios
      .post(`/api/user/edgelink/companies/${company.edgelink_company_id}/create_child_company/`, assocFormData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then(() => {
        fetchCompany();
        setOpenCreate(false);
      })
      .catch((err) => {
        console.error('Error creating associated company:', err);
      });
  };

  // ---------------------------
  // Remove Association Handler
  // ---------------------------
  const handleRemoveAssociation = (childId: number) => {
    if (window.confirm('Are you sure you want to remove this association?')) {
      axios
        .delete(`/api/user/edgelink/companies/${company?.edgelink_company_id}/remove_child_company/${childId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        })
        .then(() => {
          fetchCompany();
        })
        .catch((err) => {
          console.error('Error removing association:', err);
        });
    }
  };

  // ---------------------------
  // Render
  // ---------------------------
  if (loading) {
    return <Typography>Loading company details...</Typography>;
  }
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
        Company Details
      </Typography>

      {company && (
        <Card
          sx={{
            mb: 4,
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <CardHeader
            title={
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {company.name}
              </Typography>
            }
            subheader={
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Manage company details and associated companies
                </Typography>
              </Box>
            }
            sx={{ backgroundColor: (theme) => theme.palette.grey[200], py: 2, px: 3 }}
          />

          <CardContent sx={{ px: { xs: 3, md: 4 }, py: { xs: 2, md: 3 } }}>
            <Grid container spacing={3}>
              {/* Left Section: Core Company Details */}
              <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PersonIcon fontSize="small" />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {company.main_contact || 'N/A'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PhoneIcon fontSize="small" />
                    <Typography variant="body1">{company.main_contact_phone || 'N/A'}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <EmailIcon fontSize="small" />
                    <Typography variant="body1">{company.main_contact_email || 'N/A'}</Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <HomeIcon fontSize="small" />
                    <Typography variant="body1">{company.address || 'N/A'}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="body1">
                      {`${company.city || ''}, ${company.state || ''} ${company.zip || ''} ${company.country || ''}`}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ThermostatIcon fontSize="small" />
                    <Typography variant="body1">
                      Temp UOM:&nbsp;
                      {company.default_temp_sensor_uom
                        ? company.default_temp_sensor_uom.sensor_uom_name ||
                          company.default_temp_sensor_uom.sensor_uom_id
                        : 'N/A'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <OpacityIcon fontSize="small" />
                    <Typography variant="body1">
                      Volume UOM:&nbsp;
                      {company.default_volume_sensor_uom
                        ? company.default_volume_sensor_uom.sensor_uom_name ||
                          company.default_volume_sensor_uom.sensor_uom_id
                        : 'N/A'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <PublicOffIcon fontSize="small" />
                    <Typography variant="body1">
                      Public Access Disabled:&nbsp;
                      {company.disable_public_access === 1 ? 'Yes' : 'No'}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>

              {/* Right Section: Audit Info */}
              <Grid item xs={12} md={4}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.grey[50],
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                    Last Updated
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Updated By:</strong> {company.updated_by}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Updated At:</strong> {new Date(company.updated_at).toLocaleString()}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Box mt={3} display="flex" flexWrap="wrap" gap={2}>
              <Button variant="contained" startIcon={<EditIcon />} onClick={handleUpdateOpen} sx={{ minWidth: 200 }}>
                Update Company
              </Button>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateOpen} sx={{ minWidth: 200 }}>
                Create Associated Company
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}

      {/* Associated Companies List */}
      {company && company.associated_companies && company.associated_companies.length > 0 && (
        <Paper
          variant="outlined"
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            mb: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
            Associated Companies
          </Typography>
          <Grid container spacing={2}>
            {company.associated_companies.map((assoc) => (
              <Grid item xs={12} sm={6} md={4} key={assoc.edgelink_company_id}>
                <Card
                  sx={{
                    borderRadius: 2,
                    height: '100%',
                    '&:hover': {
                      boxShadow: 4,
                    },
                    transition: 'box-shadow 0.3s ease',
                    position: 'relative',
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                      {assoc.name}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      <strong>Created By:</strong> {assoc.created_by}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Created At:</strong> {new Date(assoc.created_at).toLocaleString()}
                    </Typography>
                  </CardContent>
                  <Box position="absolute" top={8} right={8}>
                    <Tooltip title="Remove Association">
                      <Button
                        size="small"
                        variant="text"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAssociation(assoc.edgelink_company_id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Update Company Dialog */}
      <GenericFormDialog
        open={openUpdate}
        onClose={handleUpdateClose}
        title="Update Company Details"
        showLastModified
        lastModifiedBy={company?.updated_by}
        lastModifiedAt={company?.updated_at}
      >
        {/* Form now contains its own Save/Cancel buttons */}
        <CompanyFormContent
          formData={companyFormData}
          setFormData={setCompanyFormData}
          onSave={handleUpdateSave}
          onCancel={handleUpdateClose}
        />
      </GenericFormDialog>

      {/* Create Associated Company Dialog */}
      <GenericFormDialog open={openCreate} onClose={handleCreateClose} title="Create Associated Company">
        {/* Form now contains its own Save/Cancel buttons */}
        <AssociatedCompanyFormContent
          formData={assocFormData}
          setFormData={setAssocFormData}
          onSave={handleCreateSave}
          onCancel={handleCreateClose}
        />
      </GenericFormDialog>
    </Box>
  );
};

export default CompanyDetailsPage;
