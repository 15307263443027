import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../../utils/axios.utils'; // Importing the centralized Axios instance

// Define the types for the context data
interface MfrRecipesNewContextType {
  globalMfrs: any[];
  crops: any[];
  fetchManufacturers: any;
  fetchCrops: any;
}

// Create the context with a default value of undefined
const MfrRecipesNewContext = createContext<MfrRecipesNewContextType | undefined>(undefined);

export const MfrRecipesNewProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [globalMfrs, setGlobalMfrs] = useState<any[]>([]);
  const [crops, setCrops] = useState<any[]>([]);

  const fetchManufacturers = async () => {
    try {
      // Use the centralized Axios instance for the API call
      const response = await axios.get<any, any>(`api/admin/autotreat_mfr/mfrs/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setGlobalMfrs(response.data);
    } catch (error) {
      console.error('Error fetching manufacturers:', error);
    }
  };

  const fetchCrops = async () => {
    try {
      const response = await axios.get<any, any>(`api/admin/autotreat_mfr/crops/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setCrops(response.data);
    } catch (error) {
      console.error('Error fetching crops:', error);
    }
  };

  return (
    <MfrRecipesNewContext.Provider
      value={{
        globalMfrs,
        crops,
        fetchManufacturers,
        fetchCrops,
      }}
    >
      {children}
    </MfrRecipesNewContext.Provider>
  );
};

export const useMfrRecipesNewContext = (): MfrRecipesNewContextType => {
  const context = useContext(MfrRecipesNewContext);
  if (!context) {
    throw new Error('useMfrRecipesNewContext must be used within an MfrRecipesNewProvider');
  }
  return context;
};
