import React, { useState, useContext, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { ManufacturersEditContext } from '../contexts/ManufacturersEditContext';
import axios from '../../utils/axios.utils';

const EditManufacturerForm = ({ onSuccess }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    global_mfr_id: '',
    mfr_name: '',
    mfr_enabled: false,
    mfr_enabled_liquid: false,
    mfr_enabled_recipe: false,
    mfr_enabled_seed: false,
    mfr_sync_set_id: '',
    active: true,
  });

  const { formSubmitting, formErrors, fetchManufacturerById } = useContext(ManufacturersEditContext);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const loadManufacturer = async () => {
      try {
        const manufacturer = await fetchManufacturerById(id);
        if (manufacturer && typeof manufacturer === 'object') {
          setFormValues(manufacturer);
        } else {
          console.warn(`No manufacturer found for ID: ${id}`);
        }
      } catch (error) {
        console.error('Error fetching manufacturer:', error);
      }
    };

    if (id) {
      loadManufacturer();
    }
  }, [id, fetchManufacturerById]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.put(`api/admin/autotreat_mfr/mfrs/${id}/`, formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      history.push('/admin/manufacturer_management');
    } catch (error) {
      console.error('Error updating manufacturer:', error);
      alert('Failed to update manufacturer. Please try again.');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this manufacturer?')) {
      try {
        await axios.delete(`api/admin/autotreat_mfr/mfrs/${id}/`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        });

        history.push('/admin/manufacturer_management');
        setModalOpen(false);
      } catch (error) {
        console.error('Error deleting manufacturer:', error);
        alert('Failed to delete manufacturer. Please try again.');
      }
    }
  };

  return (
    <>
      <Typography
        variant="body1"
        style={{ cursor: 'pointer', color: 'darkgrey', marginBottom: '20px' }}
        onClick={() => history.push('/admin/manufacturer_management')}
      >
        ← Return to Manufacturer List
      </Typography>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px', marginBottom: '10px' }}>
        Edit Manufacturer
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        style={{ marginBottom: '20px', float: 'right' }}
      >
        Delete
      </Button>
      <form onSubmit={handleUpdate} style={{ maxWidth: '50%', margin: '0', textAlign: 'left' }}>
        <Grid container spacing={3} style={{ marginLeft: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="global_mfr_id"
              label="Global MFR ID"
              fullWidth
              value={formValues.global_mfr_id}
              onChange={handleInputChange}
              error={!!formErrors.global_mfr_id}
              helperText={formErrors.global_mfr_id}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="mfr_name"
              label="MFR Name"
              fullWidth
              value={formValues.mfr_name}
              onChange={handleInputChange}
              error={!!formErrors.mfr_name}
              helperText={formErrors.mfr_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="mfr_sync_set_id"
              label="MFR Sync Set ID"
              fullWidth
              value={formValues.mfr_sync_set_id}
              onChange={handleInputChange}
              error={!!formErrors.mfr_sync_set_id}
              helperText={formErrors.mfr_sync_set_id}
            />
          </Grid>
          {['mfr_enabled', 'mfr_enabled_liquid', 'mfr_enabled_recipe', 'mfr_enabled_seed', 'active'].map((field) => (
            <Grid item xs={12} sm={6} key={field}>
              <FormControlLabel
                control={<Checkbox name={field} checked={formValues[field]} onChange={handleInputChange} />}
                label={field.replace('_', ' ').toUpperCase()}
              />
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
            <Button variant="contained" color="primary" type="submit" disabled={formSubmitting}>
              Update
            </Button>
            <Button variant="contained" onClick={() => history.push('/admin/manufacturer_management')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditManufacturerForm;
