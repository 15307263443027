// src/devices/pages/DevicesDashboardCards.tsx

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Grid,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Card,
  Stack,
  Paper,
  Divider,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import DevicesIcon from '@mui/icons-material/Devices';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';

import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import SolarPowerIcon from '@mui/icons-material/SolarPower';

import MapIcon from '@mui/icons-material/Map';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import TableChartIcon from '@mui/icons-material/TableChart';

import axios from '../../../utils/axios.utils';
import DeviceSidebar from '../components/DeviceSidebar';
import { DeviceResult, DevicesResponse } from '../../model';

// --------------------------------------------------
// Utility & Helper Functions
// --------------------------------------------------

function formatLastSeen(dateStr: string | null): string {
  if (!dateStr) return 'Never';
  const d = new Date(dateStr);
  if (isNaN(d.getTime())) return 'Invalid date';
  return d.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}

function parseBatteryPercent(str?: string | null) {
  if (!str) return -1;
  const numeric = parseInt(str.replace('%', ''), 10);
  return isNaN(numeric) ? -1 : numeric;
}

function getCellularIcon(strength?: string | null): JSX.Element {
  if (!strength) {
    return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 24, color: '#bdbdbd' }} />;
  }
  const s = strength.toLowerCase();
  if (s === 'strong') {
    return <SignalCellular4BarIcon sx={{ fontSize: 24, color: '#4caf50' }} />;
  }
  if (s === 'moderate') {
    return <SignalCellular2BarIcon sx={{ fontSize: 24, color: '#ff9800' }} />;
  }
  if (s === 'weak') {
    return <SignalCellular0BarIcon sx={{ fontSize: 24, color: '#f44336' }} />;
  }
  return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 24, color: '#9e9e9e' }} />;
}

function getBatteryIcon(battStr?: string | null): JSX.Element {
  const pct = parseBatteryPercent(battStr);
  if (pct < 0) {
    return <BatteryUnknownIcon sx={{ fontSize: 24, color: '#9e9e9e' }} />;
  }
  if (pct >= 80) {
    return <BatteryFullIcon sx={{ fontSize: 24, color: '#4caf50' }} />;
  }
  if (pct >= 60) {
    return <Battery60Icon sx={{ fontSize: 24, color: '#8bc34a' }} />;
  }
  if (pct >= 40) {
    return <Battery50Icon sx={{ fontSize: 24, color: '#ffc107' }} />;
  }
  if (pct > 0) {
    return <Battery20Icon sx={{ fontSize: 24, color: '#f44336' }} />;
  }
  return <Battery20Icon sx={{ fontSize: 24, color: '#f44336' }} />;
}

function getSolarIcon(volts?: string | null): JSX.Element {
  const val = parseFloat(volts || '0');
  const iconColor = val > 0 ? '#4caf50' : '#9e9e9e';
  return <SolarPowerIcon sx={{ fontSize: 24, color: iconColor }} />;
}

function renderSeedBox(fillStatus: string) {
  let baseColor = '#4d4d4d';
  let label = 'FULL';
  let gradient = `linear-gradient(to top, ${baseColor}, #616161)`;
  const lower = fillStatus.toLowerCase();

  if (lower === 'empty') {
    baseColor = '#4b4b4b';
    label = 'EMPTY';
    gradient = `linear-gradient(to top, ${baseColor}, #a0a0a0)`;
  } else if (lower === 'packed') {
    baseColor = '#4b4b4b';
    label = 'PACKED';
    gradient = `linear-gradient(to top, ${baseColor}, #a0a0a0)`;
  } else {
    if (/\d+%/.test(lower)) {
      label = fillStatus.toUpperCase();
    }
  }

  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        position: 'relative',
        backgroundColor: baseColor,
        borderRadius: 1,
        boxShadow: '0 4px 10px rgba(0,0,0,0.4)',
        overflow: 'hidden',
        background: gradient,
      }}
    >
      {lower === 'packed' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '50%',
            backgroundColor: '#fff',
          }}
        />
      )}
      {[...Array(5)].map((_, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            top: `${(i + 1) * 12}%`,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#2c2c2c',
            opacity: 0.7,
          }}
        />
      ))}
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 0,
          width: '100%',
          textAlign: 'center',
          color: '#fff',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '0.9rem',
        }}
      >
        {label}
      </Box>
    </Box>
  );
}

function renderLiquidTank(fillPercent: number) {
  const pct = Math.max(0, Math.min(fillPercent, 100));
  return (
    <Box
      sx={{
        width: 100,
        height: 140,
        position: 'relative',
        background: '#e0e0e0',
        borderRadius: '50px / 25px',
        boxShadow: 'inset 0 4px 6px rgba(0,0,0,0.2), 0 4px 10px rgba(0,0,0,0.2)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: `${pct}%`,
          background: 'linear-gradient(to top, #1976d2, #64b5f6)',
          transition: 'height 0.5s ease',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          width: '100%',
          textAlign: 'center',
          transform: 'translateY(-50%)',
          fontWeight: 'bold',
          color: pct >= 50 ? '#fff' : '#000',
        }}
      >
        {pct.toFixed(0)}%
      </Box>
    </Box>
  );
}

const StatusBadge = ({ status }: { status: string }) => {
  const getStatusColor = (val: string) => {
    switch (val?.toLowerCase()) {
      case 'full':
        return '#4caf50';
      case 'empty':
        return '#f44336';
      case 'packed':
        return '#ff9800';
      default:
        return '#757575';
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        bgcolor: getStatusColor(status),
        color: '#fff',
        px: 1,
        py: 0.5,
        borderRadius: 2,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
    >
      {status || 'N/A'}
    </Box>
  );
};

// --------------------------------------------------
// The Device Card
// --------------------------------------------------
function DeviceCard({ device, onClick }: { device: DeviceResult; onClick: () => void }) {
  const isSeedBox = device.device_type?.device_type_id === 2;
  const fillPercent =
    !isSeedBox && device.total_capacity_gallons
      ? Math.min(Math.max(0, ((device.amount_remaining_gallons || 0) / device.total_capacity_gallons) * 100), 100)
      : 0;

  const shapeElement = isSeedBox ? renderSeedBox(device.fill_status || 'Empty') : renderLiquidTank(fillPercent);

  const cityState =
    device.city && device.state ? `${device.city}, ${device.state}` : device.city || device.state || '—';

  let primaryCompany: string | null = null;
  let secondaryCompany: string | null = null;
  if (Array.isArray(device.companies)) {
    device.companies.forEach((co) => {
      if (co.primary_edgelink_company_name) {
        primaryCompany = co.primary_edgelink_company_name;
      }
      if (co.secondary_edgelink_company_name) {
        secondaryCompany = co.secondary_edgelink_company_name;
      }
    });
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card
        onClick={onClick}
        sx={{
          position: 'relative',
          p: 3,
          borderRadius: 4,
          bgcolor: '#fff',
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          overflow: 'hidden',
          '&:hover': {
            boxShadow: '0px 8px 24px rgba(0,0,0,0.15)',
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 4,
            bgcolor: isSeedBox ? '#4caf50' : '#1976d2',
          },
        }}
      >
        <StatusBadge status={device.fill_status || ''} />

        <Stack spacing={2} alignItems="center">
          {/* Top Title */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a1a1a', textAlign: 'center' }}>
              {device.device_serial_number}
            </Typography>
            <Typography variant="body2" sx={{ color: '#666', textAlign: 'center' }}>
              {cityState}
            </Typography>
          </Box>

          {/* The main shape (seed box or liquid tank) */}
          {shapeElement}

          {/* Middle Info: Product, Battery, etc. */}
          <Box textAlign="center">
            <Typography
              variant="body2"
              sx={{
                fontStyle: 'italic',
                color: '#444',
                mb: 1,
                maxWidth: 200,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                margin: '0 auto',
              }}
            >
              {device.product_profile?.product_name || 'No Product'}
            </Typography>

            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 1 }}>
              {getCellularIcon(device.cellular_strength)}
              {getBatteryIcon(device.battery_life_level)}
              {getSolarIcon(device.solar_volts)}
            </Stack>

            <Typography variant="caption" sx={{ color: '#888', display: 'block' }}>
              Last Seen: {formatLastSeen(device.last_seen_at)}
            </Typography>

            {(primaryCompany || secondaryCompany) && (
              <Typography
                variant="caption"
                sx={{
                  color: '#666',
                  mt: 0.5,
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 200,
                  margin: '0 auto',
                }}
              >
                P: {primaryCompany ?? 'None'} / S: {secondaryCompany ?? 'None'}
              </Typography>
            )}
          </Box>
        </Stack>
      </Card>
    </Grid>
  );
}

// --------------------------------------------------
// The Main Component
// --------------------------------------------------
export default function EdgeLinkDevicesDashboardCards() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  // The master list of devices
  const [devices, setDevices] = React.useState<DeviceResult[]>([]);
  // The final visible devices after filtering/searching
  const [visibleDevices, setVisibleDevices] = React.useState<DeviceResult[]>([]);

  // Searching & filtering
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [fillFilter, setFillFilter] = React.useState<'any' | 'full' | 'empty' | 'packed'>('any');
  const [batteryBelow50, setBatteryBelow50] = React.useState(false);
  const [timeFilter, setTimeFilter] = React.useState<'any' | '24h' | '7d' | '30d'>('any');
  const [deviceTypeFilter, setDeviceTypeFilter] = React.useState<'any' | 'seed' | 'liquid'>('any');
  const [loadedProductFilter, setLoadedProductFilter] = React.useState(false);
  const [productNameFilter, setProductNameFilter] = React.useState<string>('any');

  // For device sidebar
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState<DeviceResult | null>(null);

  // Distinct product names
  const productNames = React.useMemo(() => {
    const names = new Set<string>();
    devices.forEach((d) => {
      if (d.product_profile?.product_name) {
        names.add(d.product_profile.product_name);
      }
    });
    return Array.from(names);
  }, [devices]);

  // --------------------------------------------------
  // Fetch devices on mount
  // --------------------------------------------------
  React.useEffect(() => {
    axios
      .get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        if (Array.isArray(res.data.results)) {
          setDevices(res.data.results);
        } else {
          console.error('Unexpected response data:', res.data);
          setDevices([]);
        }
      })
      .catch((err) => {
        console.error('Error fetching devices:', err);
        setDevices([]);
      });
  }, []);

  // --------------------------------------------------
  // Recompute visibleDevices whenever devices or filter states change
  // --------------------------------------------------
  React.useEffect(() => {
    let result = [...devices];

    // 1. Search Term
    const txt = searchTerm.trim().toLowerCase();
    if (txt) {
      result = result.filter((d) => {
        const fields: string[] = [];

        // Basic device info
        if (d.device_serial_number) fields.push(d.device_serial_number);
        if (d.microcontroller_serial) fields.push(d.microcontroller_serial);
        if (d.city) fields.push(d.city);
        if (d.state) fields.push(d.state);
        if (d.battery_life_level) fields.push(d.battery_life_level);

        // Product info
        if (d.product_profile?.product_name) fields.push(d.product_profile.product_name);
        if (d.product_profile?.lot_number) fields.push(d.product_profile.lot_number);
        if (d.product_profile?.batch_number) fields.push(d.product_profile.batch_number);
        if (d.product_profile?.crop) fields.push(d.product_profile.crop);

        // Tank config
        if (d.tank_config?.tank_name) fields.push(d.tank_config.tank_name);

        // Company info (primary, secondary, tertiary)
        if (Array.isArray(d.companies)) {
          d.companies.forEach((company) => {
            if (company.primary_edgelink_company_name) fields.push(company.primary_edgelink_company_name);
            if (company.secondary_edgelink_company_name) fields.push(company.secondary_edgelink_company_name);
            if (company.tertiary_edgelink_company_name) fields.push(company.tertiary_edgelink_company_name);
          });
        }

        // fill_status
        if (d.fill_status) fields.push(d.fill_status);

        const lowerFields = fields.map((f) => f.toLowerCase());
        return lowerFields.some((field) => field.includes(txt));
      });
    }

    // 2. Battery < 50
    if (batteryBelow50) {
      result = result.filter((d) => {
        const pct = parseBatteryPercent(d.battery_life_level);
        return pct >= 0 && pct < 50;
      });
    }

    // 3. Fill status
    if (fillFilter === 'full') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'full');
    } else if (fillFilter === 'empty') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'empty');
    } else if (fillFilter === 'packed') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'packed');
    }

    // 4. Time filter
    const now = Date.now();
    function daysAgo(dateStr: string | null) {
      if (!dateStr) return Infinity;
      return (now - new Date(dateStr).getTime()) / (1000 * 3600 * 24);
    }
    if (timeFilter === '24h') {
      result = result.filter((d) => daysAgo(d.last_seen_at) < 1);
    } else if (timeFilter === '7d') {
      result = result.filter((d) => daysAgo(d.last_seen_at) >= 7);
    } else if (timeFilter === '30d') {
      result = result.filter((d) => daysAgo(d.last_seen_at) >= 30);
    }

    // 5. Device type filter
    if (deviceTypeFilter !== 'any') {
      result = result.filter((d) => {
        const dt = d.device_type?.device_type_name?.toLowerCase() || '';
        return dt === deviceTypeFilter;
      });
    }

    // 6. "Loaded with product" filter
    if (loadedProductFilter) {
      result = result.filter((d) => !!d.product_profile?.product_name);
    }

    // 7. Product name filter
    if (productNameFilter !== 'any') {
      result = result.filter((d) => d.product_profile?.product_name === productNameFilter);
    }

    setVisibleDevices(result);
  }, [
    devices,
    searchTerm,
    batteryBelow50,
    fillFilter,
    timeFilter,
    deviceTypeFilter,
    loadedProductFilter,
    productNameFilter,
  ]);

  /** Clear all filters */
  function handleClearFilters() {
    setBatteryBelow50(false);
    setFillFilter('any');
    setTimeFilter('any');
    setDeviceTypeFilter('any');
    setLoadedProductFilter(false);
    setProductNameFilter('any');
  }

  /** Card click => open sidebar */
  function handleCardClick(device: DeviceResult) {
    setSelectedDevice(device);
    setDrawerOpen(true);
  }
  function closeDrawer() {
    setDrawerOpen(false);
    setSelectedDevice(null);
  }

  // Additional states for demonstration
  const [companyDialogOpen, setCompanyDialogOpen] = React.useState(false);
  const [companyType, setCompanyType] = React.useState<'secondary' | 'tertiary' | null>(null);
  const [companyList, setCompanyList] = React.useState<{ id: number; name: string }[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number | ''>('');
  const [assignError, setAssignError] = React.useState<string | null>(null);

  const [receiveDialogOpen, setReceiveDialogOpen] = React.useState(false);
  const [receiveError, setReceiveError] = React.useState<string | null>(null);

  const [tankConfigDialogOpen, setTankConfigDialogOpen] = React.useState(false);
  const [productProfileDialogOpen, setProductProfileDialogOpen] = React.useState(false);

  function handleOpenReceiveDialog() {
    if (!selectedDevice) return;
    setReceiveError(null);
    setReceiveDialogOpen(true);
  }

  function handleOpenCompanyDialog(type: 'secondary' | 'tertiary') {
    if (!selectedDevice) return;
    setAssignError(null);
    setCompanyType(type);
    setSelectedCompanyId('');
    const mock = [
      { id: 101, name: 'Sample Secondary A' },
      { id: 102, name: 'Sample Secondary B' },
      { id: 201, name: 'Sample Tertiary A' },
      { id: 202, name: 'Sample Tertiary B' },
    ];
    setCompanyList(mock);
    setCompanyDialogOpen(true);
  }

  function handleShowTankConfigInfo() {
    setTankConfigDialogOpen(true);
  }
  function handleShowProductProfileInfo() {
    setProductProfileDialogOpen(true);
  }
  function handleConfirmReceive() {
    // Implementation pending
  }
  function handleSaveCompanyDialog() {
    // Implementation pending
  }

  // ---------------------------
  //  RE-FETCH ALL DEVICES AFTER SAVE
  // ---------------------------
  async function handleDeviceUpdated() {
    try {
      const resp = await axios.get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      if (resp.data.success && Array.isArray(resp.data.results)) {
        setDevices(resp.data.results);

        // If there's still a selected device, update it from the new list
        if (selectedDevice) {
          const updated = resp.data.results.find((d) => d.device_serial_number === selectedDevice.device_serial_number);
          if (updated) {
            setSelectedDevice(updated);
          }
        }
      }
    } catch (err) {
      console.error('Failed to refresh device data:', err);
    }
  }

  // Render
  return (
    <Box sx={{ width: '100%', minHeight: '100vh', bgcolor: '#f5f7fa' }}>
      {/* Header bar */}
      <Paper
        elevation={3}
        sx={{
          mb: 2,
          p: 2,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'linear-gradient(to right, #203a43, #2c5364)',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <DevicesIcon sx={{ fontSize: 32, color: '#fff' }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
            EdgeLink Devices
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          {!isMobile && (
            <>
              <Tooltip title="Map View">
                <IconButton onClick={() => history.push('/edgelink/devices/map')}>
                  <MapIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Card View">
                <IconButton onClick={() => history.push('/edgelink/devices/cards')}>
                  <ViewAgendaIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Table View">
                <IconButton onClick={() => history.push('/edgelink/devices/table')}>
                  <TableChartIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Filter">
            <IconButton onClick={() => setFilterOpen(true)}>
              <FilterListIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Search">
            <IconButton onClick={() => setSearchOpen(true)}>
              <SearchIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>

      {/* Cards Grid */}
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {visibleDevices.map((device) => (
            <DeviceCard key={device.edgelink_device_id} device={device} onClick={() => handleCardClick(device)} />
          ))}
          {visibleDevices.length === 0 && (
            <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
              <Typography variant="h6" color="text.secondary">
                No devices match your current filters
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>

      {/* Search Dialog */}
      <Dialog open={searchOpen} onClose={() => setSearchOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Search Devices</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Search by serial, city, state, product, etc."
            fullWidth
            variant="outlined"
            placeholder="Search by serial, location, product, or companies..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              sx: { borderRadius: 2 },
              startAdornment: <SearchIcon sx={{ color: '#757575', mr: 1 }} />,
            }}
          />
          {visibleDevices.length > 0 && (
            <List dense sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
              {visibleDevices.map((dev) => (
                <ListItem
                  key={dev.edgelink_device_id}
                  button
                  onClick={() => {
                    setSearchOpen(false);
                    handleCardClick(dev);
                  }}
                >
                  <ListItemText
                    primary={`Serial: ${dev.device_serial_number}`}
                    secondary={
                      dev.city ? `${dev.city}, ${dev.state || ''}` : `Lat: ${dev.gps_lat}, Lng: ${dev.gps_long}`
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
          {visibleDevices.length === 0 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              No devices match the current filters.
            </Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Filter Dialog */}
      <Dialog
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { borderRadius: 3 } }}
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>Filter Devices</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={batteryBelow50} onChange={() => setBatteryBelow50(!batteryBelow50)} />}
              label="Battery < 50%"
            />
            <FormControl>
              <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Fill Status</FormLabel>
              <RadioGroup
                value={fillFilter}
                onChange={(e) => setFillFilter(e.target.value as 'any' | 'full' | 'empty' | 'packed')}
              >
                <FormControlLabel value="any" control={<Radio />} label="Any" />
                <FormControlLabel value="full" control={<Radio />} label="Full Only" />
                <FormControlLabel value="empty" control={<Radio />} label="Empty Only" />
                <FormControlLabel value="packed" control={<Radio />} label="Packed Only" />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Last Seen</FormLabel>
              <RadioGroup
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value as 'any' | '24h' | '7d' | '30d')}
              >
                <FormControlLabel value="any" control={<Radio />} label="Any Time" />
                <FormControlLabel value="24h" control={<Radio />} label="< 24 Hours" />
                <FormControlLabel value="7d" control={<Radio />} label="≥ 7 Days" />
                <FormControlLabel value="30d" control={<Radio />} label="≥ 30 Days" />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Device Type</InputLabel>
              <Select
                value={deviceTypeFilter}
                label="Device Type"
                onChange={(e) => setDeviceTypeFilter(e.target.value as 'any' | 'seed' | 'liquid')}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="any">Any</MenuItem>
                <MenuItem value="seed">Seed</MenuItem>
                <MenuItem value="liquid">Liquid</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox checked={loadedProductFilter} onChange={(e) => setLoadedProductFilter(e.target.checked)} />
              }
              label="Loaded with Product"
            />
            <FormControl fullWidth>
              <InputLabel>Product</InputLabel>
              <Select
                value={productNameFilter}
                label="Product"
                onChange={(e) => setProductNameFilter(e.target.value)}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="any">Any</MenuItem>
                {productNames.map((pn) => (
                  <MenuItem key={pn} value={pn}>
                    {pn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClearFilters} sx={{ borderRadius: 3, mr: 1 }}>
            Clear
          </Button>
          <Button variant="contained" onClick={() => setFilterOpen(false)} sx={{ borderRadius: 3 }}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Device Sidebar */}
      <DeviceSidebar
        isOpen={drawerOpen}
        onClose={closeDrawer}
        selectedDevice={selectedDevice}
        popoutEnabled={true} // enable the popout icon
        showMap={true} // show the map in the DeviceContentCard if you want
        onDeviceUpdated={handleDeviceUpdated} // << re-fetch ALL devices after a save
      />

      {/* Additional / mock dialogs */}
      <Dialog open={receiveDialogOpen} onClose={() => setReceiveDialogOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Receive Device</DialogTitle>
        <DialogContent>
          {receiveError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {receiveError}
            </Alert>
          )}
          <Typography variant="body2">
            Are you sure you want to receive this device back under your company’s custody?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReceiveDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirmReceive}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={companyDialogOpen} onClose={() => setCompanyDialogOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>
          {companyType === 'secondary' ? 'Assign Secondary Company' : 'Assign Tertiary Company'}
        </DialogTitle>
        <DialogContent>
          {assignError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {assignError}
            </Alert>
          )}
          <FormControl fullWidth>
            <InputLabel>Company</InputLabel>
            <Select
              label="Company"
              value={selectedCompanyId}
              onChange={(e) => setSelectedCompanyId(e.target.value as number)}
            >
              {companyList.map((co) => (
                <MenuItem key={co.id} value={co.id}>
                  {co.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompanyDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveCompanyDialog}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={tankConfigDialogOpen} onClose={() => setTankConfigDialogOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Tank Configuration Details</DialogTitle>
        <DialogContent>
          {selectedDevice?.tank_config ? (
            <Typography variant="body2">Tank: {selectedDevice.tank_config.tank_name}</Typography>
          ) : (
            <Typography variant="body2">No Tank Configuration assigned.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTankConfigDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={productProfileDialogOpen}
        onClose={() => setProductProfileDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {selectedDevice?.product_profile ? (
            <Typography variant="body2">Product Name: {selectedDevice.product_profile.product_name}</Typography>
          ) : (
            <Typography variant="body2">No product assigned.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProductProfileDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
