import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../../utils/axios.utils';

interface GTINPrefix {
  autotreat_gtin_prefix_cloud_id: number;
  gtin_prefix_id: number;
  prefix: string;
  company: string;
  description: string;
  user_name: string;
  date_and_time: string;
  active: boolean;
  obsolete: boolean;
}

interface GTINPrefixesContextType {
  gtinPrefixes: GTINPrefix[];
  gtinPrefixesLoading: boolean;
  fetchGTINPrefixes: () => void;
}

const GTINPrefixesContext = createContext<GTINPrefixesContextType | undefined>(undefined);

export const MfrGTINPrefixesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [gtinPrefixes, setGTINPrefixes] = useState<GTINPrefix[]>([]);
  const [gtinPrefixesLoading, setGTINPrefixesLoading] = useState<boolean>(false);

  const fetchGTINPrefixes = async () => {
    setGTINPrefixesLoading(true);
    try {
      const response = await axios.get(`api/admin/autotreat_mfr/gtin_prefixes/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setGTINPrefixes(response.data);
    } catch (error) {
      console.error('Error fetching GTIN Prefixes:', error);
    }
    setGTINPrefixesLoading(false);
  };

  return (
    <GTINPrefixesContext.Provider value={{ gtinPrefixes, gtinPrefixesLoading, fetchGTINPrefixes }}>
      {children}
    </GTINPrefixesContext.Provider>
  );
};

export const useMfrGTINPrefixesContext = () => {
  const context = useContext(GTINPrefixesContext);
  if (!context) {
    throw new Error('useGTINPrefixesContext must be used within a MfrGTINPrefixesProvider');
  }
  return context;
};

export default MfrGTINPrefixesProvider;
