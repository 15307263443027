// src/sharedComponents/GenericFormDialog.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, Divider } from '@mui/material';

interface GenericFormDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
  showLastModified?: boolean;
  /**
   * We no longer have Save/Cancel buttons in the dialog, so
   * no "onSave" or "saveButtonDisabled" props here.
   */
}

/** Convert a UTC date string to the user's local time in a friendly format. */
const formatLocalDateTime = (utcString?: string): string => {
  if (!utcString) return '';
  const dateObj = new Date(utcString);
  if (isNaN(dateObj.getTime())) return '';
  return dateObj.toLocaleString(); // e.g. "3/10/2025, 11:00:29 PM"
};

const GenericFormDialog: React.FC<GenericFormDialogProps> = ({
  open,
  onClose,
  title,
  children,
  lastModifiedBy,
  lastModifiedAt,
  showLastModified = false,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {children}

        {showLastModified && (
          <Box sx={{ mt: 3 }}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Last Modified
            </Typography>
            <Typography variant="body2">
              <strong>By:</strong> {lastModifiedBy || 'N/A'}
            </Typography>
            <Typography variant="body2">
              <strong>At:</strong> {formatLocalDateTime(lastModifiedAt)}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GenericFormDialog;
