// edgelink/sharedConstants/CropDefaults.ts

export const CROP_DEFAULTS: Record<
  string,
  {
    seed_unit_size: number;
    seed_size: number | null;
    show_seed_size: boolean;
  }
> = {
  Soybean: { seed_unit_size: 140000, seed_size: 2800, show_seed_size: true },
  Corn: { seed_unit_size: 80000, seed_size: 1500, show_seed_size: true },
  Rice: { seed_unit_size: 50, seed_size: null, show_seed_size: false },
  Wheat: { seed_unit_size: 50, seed_size: null, show_seed_size: false },
  Cotton: { seed_unit_size: 230000, seed_size: 4500, show_seed_size: true },
  Cereal: { seed_unit_size: 50, seed_size: null, show_seed_size: false },
  Other: { seed_unit_size: 50, seed_size: null, show_seed_size: false },
};
