import React from 'react';
import {
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

interface UserFormData {
  email: string;
  full_name: string;
  phone_number: string;
  edgelink_role: number | null;
  status: boolean;
}

interface UserFormContentProps {
  formData: UserFormData;
  setFormData: (data: UserFormData) => void;
  onSave: () => void;
  onCancel: () => void;
  responseMessage?: string; // Optional message to show response/errors
}

// TODO: Use axios to fetch roles from the server
// import axios from '../../../utils/axios.utils';
// axios.get<any, any>('/api/edgelink/roles')
//   .then((response) => {
//     // handle response
//   })
//   .catch((err) => {
//     // handle error
//   });

const roleOptions = [
  { value: 1, label: 'No Role' },
  { value: 2, label: 'Admin' },
  { value: 3, label: 'Standard' },
  { value: 4, label: 'View Only' },
];

const UserFormContent: React.FC<UserFormContentProps> = ({
  formData,
  setFormData,
  onSave,
  onCancel,
  responseMessage,
}) => {
  const handleChange = (field: keyof UserFormData, value: string | boolean) => {
    if (field === 'edgelink_role') {
      // Attempt to parse the new role ID as an integer
      const parsed = parseInt(value as string, 10);
      const newRole = isNaN(parsed) ? null : parsed;
      setFormData({ ...formData, [field]: newRole });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  return (
    <>
      {responseMessage && (
        <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
          {responseMessage}
        </Typography>
      )}
      <Stack spacing={2} mt={1}>
        <TextField
          label="Email"
          value={formData.email}
          onChange={(e) => handleChange('email', e.target.value)}
          fullWidth
        />
        <TextField
          label="Full Name"
          value={formData.full_name}
          onChange={(e) => handleChange('full_name', e.target.value)}
          fullWidth
        />
        <TextField
          label="Phone Number"
          value={formData.phone_number}
          onChange={(e) => handleChange('phone_number', e.target.value)}
          fullWidth
        />

        {/* Role dropdown */}
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            label="Role"
            value={formData.edgelink_role !== null ? formData.edgelink_role : ''}
            onChange={(e) => handleChange('edgelink_role', e.target.value as any)}
          >
            {roleOptions.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              checked={formData.status}
              onChange={(e) => handleChange('status', e.target.checked)}
              color="primary"
            />
          }
          label="Active Status"
        />
      </Stack>

      {/* Save/Cancel buttons inside the form content */}
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default UserFormContent;
