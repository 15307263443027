// QRScanRouter.tsx

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, LinearProgress, Typography, Paper } from '@mui/material';
import axios from '../../utils/axios.utils';

const ScanPage: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    let ignore = false;

    // Poll sessionStorage for a token until a maximum wait time has passed.
    const waitForToken = (): Promise<string | null> => {
      const pollInterval = 50; // check every 50ms
      const maxWaitTime = 2000; // wait up to 2000ms
      let elapsed = 0;

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const token = sessionStorage.getItem('accessToken');
          elapsed += pollInterval;
          if (token || elapsed >= maxWaitTime) {
            clearInterval(interval);
            resolve(token);
          }
        }, pollInterval);
      });
    };

    const fetchRedirectUrl = async () => {
      try {
        setLoading(true);
        setError(null);

        // Parse query parameters from the URL.
        const params = new URLSearchParams(location.search);
        const deviceSerial = params.get('device_serial');
        if (!deviceSerial) {
          setError('No device serial provided.');
          setLoading(false);
          return;
        }

        // Wait a short period to see if the token becomes available.
        const token = await waitForToken();
        const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

        // Make the API call.
        const response = await axios.get<any>(`/api/public/scan?device_serial=${deviceSerial}`, config);

        if (!ignore) {
          if (response.data.success && response.data.url) {
            let redirectUrl = response.data.url;
            // If the URL is relative, prepend the base URL.
            if (!redirectUrl.startsWith('http')) {
              redirectUrl = window.location.origin + (redirectUrl.startsWith('/') ? '' : '/') + redirectUrl;
            }
            window.location.href = redirectUrl;
          } else {
            setError('Failed to retrieve redirect URL.');
          }
        }
      } catch (err: any) {
        if (!ignore) {
          console.error('Error fetching redirect URL:', err);
          setError('Failed to fetch redirect URL.');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchRedirectUrl();

    return () => {
      ignore = true;
    };
  }, [location.search]);

  if (loading) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center', p: 4 }}>
        <LinearProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography color="error">{error}</Typography>
        </Paper>
      </Box>
    );
  }

  return null;
};

export default ScanPage;
