import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, LinearProgress, Typography, Paper } from '@mui/material';
import axios from '../../utils/axios.utils';

interface RouterResponse {
  success: boolean;
  message: string;
  url: string;
  relationship: string;
}

const DeviceRouterPage: React.FC = () => {
  const { deviceSerial } = useParams<{ deviceSerial: string }>();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    let ignore = false;

    const fetchRedirectUrl = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get<any, any>(`/api/user/edgelink/device_router/${deviceSerial}/`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        });

        if (!ignore) {
          if (response.data.success && response.data.url) {
            let redirectUrl = response.data.url;
            // If the URL is relative, prepend the base URL.
            if (!redirectUrl.startsWith('http')) {
              redirectUrl = window.location.origin + (redirectUrl.startsWith('/') ? '' : '/') + redirectUrl;
            }
            window.location.href = redirectUrl;
          } else {
            setError('Failed to retrieve redirect URL.');
          }
        }
      } catch (err: any) {
        if (!ignore) {
          console.error('Error fetching redirect URL:', err);
          setError('Failed to fetch redirect URL.');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchRedirectUrl();

    return () => {
      ignore = true;
    };
  }, [deviceSerial]);

  if (loading) {
    return (
      <Box sx={{ width: '100%', textAlign: 'center', p: 4 }}>
        <LinearProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography color="error">{error}</Typography>
        </Paper>
      </Box>
    );
  }

  // If not loading and no error, we expect the redirect to have occurred.
  return null;
};

export default DeviceRouterPage;
