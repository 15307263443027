/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
  CircularProgress,
  Alert,
  Box,
  AlertColor,
  Link,
  Switch,
  FormControlLabel,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import FormInput from '../../../modules/shared/form/FormInput';
import { DriverAutoBulkFormValues } from '../model';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import { DriversAutoBulkValidationsContext } from '../contexts/DriversAutoBulkValidationsContext';
import SelectInput from '../../../modules/shared/form/SelectInput';
import { useHistory } from 'react-router-dom';

interface DriversAutoBulkFormDialogProps {
  open: boolean;
  onClose: () => void;
  driver: any;
}

const DriversAutoBulkFormDialog: React.FC<DriversAutoBulkFormDialogProps> = (props) => {
  const { open, onClose, driver } = props;
  const history = useHistory();

  // Need to return multiple dialogs so wrap in a React fragment

  const {
    activeMachine,
    driverSubmitting,
    createDriverAutoBulk,
    updateDriverAutoBulk,
    deleteDriverAutoBulk,
    driverOptions,
    setActiveDriverAutoBulk,
    fetchDriversAutoBulk,
    fetchDriverOptions,
  } = React.useContext(DriversAutoBulkContext);
  const { validateDriversAutoBulkFields, formErrors } = React.useContext(DriversAutoBulkValidationsContext);
  const [formValues, setFormValues] = React.useState<DriverAutoBulkFormValues>({});
  const [actionButtonDisabled, setActionButtonDisabled] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState('');
  const [responseType, setResponseType] = React.useState<AlertColor | undefined>(undefined);
  const [errorData, setErrorData] = React.useState<any>(null);
  const [initialFormValues, setInitialFormValues] = React.useState<DriverAutoBulkFormValues>({});
  const [hasFormChanged, setHasFormChanged] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleDeleteConfirm = () => {
    setActionButtonDisabled(true);
    setResponseMessage('');
    setResponseType(undefined);
    setDeleteDialogOpen(false);

    deleteDriverAutoBulk(formValues.driver_id, (success, message, apiResponseData) => {
      // Use the API response data if available, otherwise create fallback data
      const responseData = apiResponseData || (success ? null : { table: 'driver', record_id: formValues.driver_id });

      if (success) {
        // Close the dialog and refresh the list
        onClose();
      } else {
        // Show error and allow retry
        setResponseType('error');
        setResponseMessage(message || 'Failed to delete driver');
        // Only set error data if we have valid response data from the machine
        if (apiResponseData) {
          setErrorData(responseData);
        } else {
          // Clear error data if there's no response from the machine
          setErrorData(null);
        }
        setActionButtonDisabled(false);
      }
    });
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleUpdate = () => {
    setResponseMessage('');
    setResponseType(undefined);
    updateDriverAutoBulk(formValues, (success, message, apiResponseData) => {
      // Use the API response data if available, otherwise create fallback data
      const responseData = apiResponseData || (success ? null : { table: 'driver', record_id: formValues.driver_id });
      setResponseType(success ? 'success' : 'error');

      // For success message, just show the text
      if (success) {
        setResponseMessage(message || 'Driver updated successfully');

        // If update was successful, refresh the driver list in the background
        // but keep the form open for further edits
        if (activeMachine?.value) {
          fetchDriversAutoBulk(activeMachine.value, '');
        }
      } else {
        // For error message, keep the full response data to enable the conflict button
        setResponseMessage(message || 'Failed to update driver');
        setErrorData(responseData);
      }

      // Unlock the button on error to allow retry
      if (!success) {
        setActionButtonDisabled(false);
      }
    });
  };

  const handleCreate = () => {
    setResponseMessage('');
    setResponseType(undefined);
    createDriverAutoBulk(formValues, (success, message, apiResponseData) => {
      // Use the API response data if available, otherwise create fallback data
      const responseData = apiResponseData || (success ? null : { table: 'driver', record_id: null });
      setResponseType(success ? 'success' : 'error');

      // For success message, just show the text
      if (success) {
        setResponseMessage(message || 'Driver created successfully');

        // If creation was successful, refresh the driver list in the background
        // but keep the form open for further edits
        if (activeMachine?.value) {
          fetchDriversAutoBulk(activeMachine.value, '');

          // Reset the form for a new driver entry
          setFormValues({
            driver_id: '',
            driver_name: '',
            driver_phone: '',
            driver_email: '',
            driver_access_code: '',
            driver_comment: '',
            active: '',
            rfid_id: '',
            rfid_name: '',
            driver_api_partner: '',
          });
        }
      } else {
        // For error message, keep the full response data to enable the conflict button
        setResponseMessage(message || 'Failed to create driver');
        setErrorData(responseData);
      }

      // Unlock the button on error to allow retry
      if (!success) {
        setActionButtonDisabled(false);
      }
    });
  };

  React.useEffect(() => {
    const abDriversFieldsValid = validateDriversAutoBulkFields(formValues);
    setActionButtonDisabled(!abDriversFieldsValid);
  }, [formValues]);

  // Track if form has changed compared to initial values
  React.useEffect(() => {
    // Skip on first render or empty forms
    if (Object.keys(initialFormValues).length === 0) return;

    // Compare current values to initial values
    const hasChanged = Object.keys(formValues).some((key) => {
      // Skip driver_id as it doesn't change
      if (key === 'driver_id') return false;
      return formValues[key] !== initialFormValues[key];
    });

    setHasFormChanged(hasChanged);
  }, [formValues, initialFormValues]);

  // Reset error data and active driver when appropriate
  React.useEffect(() => {
    if (open) {
      // Reset error state on open
      setErrorData(null);
      setResponseMessage('');
      setResponseType(undefined);

      // Ensure driver options including RFID are loaded when form opens
      if (activeMachine?.value) {
        fetchDriverOptions(activeMachine.value);
      }
    } else {
      // Clear active driver when form is closed
      setActiveDriverAutoBulk({});
    }
  }, [open, activeMachine]);

  React.useEffect(() => {
    if (driver && open) {
      // Set active driver in context for validation purposes
      setActiveDriverAutoBulk(driver);

      const newFormValues = {
        driver_id: driver.driver_id || '',
        driver_name: driver.driver_name || '',
        driver_phone: driver.driver_phone || '',
        driver_email: driver.driver_email || '',
        driver_access_code: driver.driver_access_code || '',
        driver_comment: driver.driver_comment || '',
        active: driver.active || '',
        rfid_id: driver.rfid_id || '',
        rfid_name: driver.rfid_name || '',
        driver_api_partner: driver.driver_api_partner || '',
      };

      setFormValues(newFormValues);
      setInitialFormValues(newFormValues);
      setHasFormChanged(false);
    } else if (open) {
      // Reset active driver in context when creating a new driver
      setActiveDriverAutoBulk({});

      // Reset form when opening for new driver
      const newFormValues = {
        driver_id: '',
        driver_name: '',
        driver_phone: '',
        driver_email: '',
        driver_access_code: '',
        driver_comment: '',
        active: '',
        rfid_id: '',
        rfid_name: '',
        driver_api_partner: '',
      };

      setFormValues(newFormValues);
      setInitialFormValues(newFormValues);
      setHasFormChanged(false);
    }
  }, [driver, open]);

  // Handle close with confirmation for unsaved changes
  // Format date with appropriate timezone handling
  const formatDate = (dateString: string) => {
    if (!dateString) return 'N/A';
    // Handle UTC date and convert to local browser timezone
    // The API now returns ISO 8601 format with Z suffix
    try {
      // No need to append Z as it's already included in the API response
      const date = new Date(dateString);
      return format(date, 'MM/dd/yyyy hh:mm a');
    } catch (error) {
      console.error('Error parsing date:', error, dateString);
      return dateString || 'N/A';
    }
  };

  const handleClose = () => {
    if (hasFormChanged && !responseType) {
      if (window.confirm('You have unsaved changes. Are you sure you want to close this form?')) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth aria-labelledby="driver-form-dialog-title">
        <DialogTitle id="driver-form-dialog-title" sx={{ paddingBottom: 1 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{driver?.driver_id ? 'Edit Driver' : 'Create Driver'}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.active === 'True'}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        active: e.target.checked ? 'True' : 'False',
                      });
                    }}
                    color="primary"
                  />
                }
                label="Active"
                labelPlacement="start"
                sx={{ marginLeft: 2, marginRight: 1 }}
              />

              {driver?.driver_id && (
                <IconButton
                  aria-label="delete"
                  onClick={handleDelete}
                  sx={{
                    color: (theme) => theme.palette.error.main,
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  value={formValues?.driver_name}
                  onChange={(value) => setFormValues({ ...formValues, driver_name: value })}
                  label="Driver Name"
                  errorMessage={formErrors.DriverName}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  value={formValues?.driver_phone}
                  onChange={(value) => setFormValues({ ...formValues, driver_phone: value })}
                  label="Driver Phone"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  value={formValues?.driver_email}
                  onChange={(value) => setFormValues({ ...formValues, driver_email: value })}
                  label="Driver Email"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  value={formValues?.driver_access_code}
                  onChange={(value) => setFormValues({ ...formValues, driver_access_code: value })}
                  label="Driver Access Code"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  value={formValues?.driver_comment}
                  onChange={(value) => setFormValues({ ...formValues, driver_comment: value })}
                  label="Comment"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  value={formValues?.rfid_id}
                  groupedChoices={{
                    'Not assigned': driverOptions.driverRFIDOptions.filter(
                      (option) => option.associated_transport_id === '' && option.associated_driver_id === '',
                    ),
                    'Assigned Transport': driverOptions.driverRFIDOptions
                      .filter((option) => option.associated_transport_id !== '')
                      .map((option) => ({
                        ...option,
                        label: `${option.label} ----> ${option.associated_transport_name}`,
                      })),
                    'Assigned Driver': driverOptions.driverRFIDOptions
                      .filter((option) => option.associated_driver_id !== '')
                      .map((option) => ({
                        ...option,
                        label: `${option.label} ----> ${option.associated_driver_name}`,
                      })),
                  }}
                  onChange={(value) => setFormValues({ ...formValues, rfid_id: value, rfid_name: value })}
                  label="RFID"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {responseMessage && (
          <Box p={2} width="100%">
            <Alert
              severity={responseType}
              action={
                errorData && errorData.table && errorData.record_id ? (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      // Open the conflicting record in a new tab
                      if (activeMachine?.value && errorData.record_id) {
                        // Open conflicting record in a new tab
                        const url = `/autobulk/machines/${activeMachine.value}/drivers/${errorData.record_id}`;
                        window.open(url, '_blank');
                      }
                    }}
                  >
                    View Conflicting Record
                  </Button>
                ) : null
              }
            >
              {responseMessage}
            </Alert>
          </Box>
        )}

        {driver?.driver_id && (
          <Box px={3} pt={1}>
            <Divider />
            <Box mt={1} mb={1}>
              <Typography variant="body2" color="textSecondary">
                {driver.date_and_time && <>Updated At: {formatDate(driver.date_and_time)}</>}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {driver.user_name && <>Updated By: {driver.user_name}</>}
              </Typography>
            </Box>
          </Box>
        )}

        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          {driver?.driver_id ? (
            <Button
              variant="contained"
              color="primary"
              disabled={actionButtonDisabled || driverSubmitting || activeMachine?.online_status === 'offline'}
              onClick={handleUpdate}
            >
              {driverSubmitting ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: 8 }} />
                  Updating Driver...
                </>
              ) : (
                'Update Driver'
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={actionButtonDisabled || driverSubmitting || activeMachine?.online_status === 'offline'}
              onClick={handleCreate}
            >
              {driverSubmitting ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: 8 }} />
                  Creating Driver...
                </>
              ) : (
                'Create Driver'
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this driver?
            <Typography variant="subtitle2" color="error" sx={{ mt: 1 }}>
              This action cannot be undone.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DriversAutoBulkFormDialog;
