// src/devices/pages/DeviceDashboardTable.tsx
import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../../utils/axios.utils';

import {
  Box,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Stack,
  LinearProgress,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { styled } from '@mui/material/styles';

// Icons
import DevicesIcon from '@mui/icons-material/Devices';
import MapIcon from '@mui/icons-material/Map';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';

import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';

import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';

// ADD THIS IMPORT FOR GPS ICON
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import DeviceSidebar from '../components/DeviceSidebar';
import DeviceSearchDialog from '../components/DeviceSearchDialog';
import { DeviceResult, DevicesResponse } from '../../model';

/* -----------------------------------------
   Utility Functions
----------------------------------------- */
function parseBatteryPercent(str?: string | null) {
  if (!str) return -1;
  const numeric = parseInt(str.replace('%', ''), 10);
  return isNaN(numeric) ? -1 : numeric;
}

function getBatteryIcon(battStr?: string | null): JSX.Element {
  const pct = parseBatteryPercent(battStr);
  if (pct < 0) return <BatteryUnknownIcon sx={{ fontSize: 18, color: '#9e9e9e' }} />;
  if (pct >= 80) return <BatteryFullIcon sx={{ fontSize: 18, color: '#4caf50' }} />;
  if (pct >= 60) return <Battery60Icon sx={{ fontSize: 18, color: '#8bc34a' }} />;
  if (pct >= 40) return <Battery50Icon sx={{ fontSize: 18, color: '#ffc107' }} />;
  if (pct > 0) return <Battery20Icon sx={{ fontSize: 18, color: '#f44336' }} />;
  return <Battery20Icon sx={{ fontSize: 18, color: '#f44336' }} />;
}

function getCellularIcon(str?: string | null) {
  if (!str) {
    return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 18, color: '#bdbdbd' }} />;
  }
  const s = str.toLowerCase();
  if (s === 'strong') return <SignalCellular4BarIcon sx={{ fontSize: 18, color: '#4caf50' }} />;
  if (s === 'moderate') return <SignalCellular2BarIcon sx={{ fontSize: 18, color: '#ff9800' }} />;
  if (s === 'weak') return <SignalCellular0BarIcon sx={{ fontSize: 18, color: '#f44336' }} />;
  return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 18, color: '#9e9e9e' }} />;
}

function formatLastSeen(dateStr: string | null) {
  if (!dateStr) return 'Never';
  const d = new Date(dateStr);
  if (isNaN(d.getTime())) return 'Invalid date';
  return d.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}

function getFillPercent(device: DeviceResult) {
  if (device.device_type?.device_type_id === 1 && device.total_capacity_gallons) {
    const cap = Number(device.total_capacity_gallons);
    const amt = Number(device.amount_remaining_gallons);
    if (!isNaN(cap) && cap > 0 && !isNaN(amt) && amt >= 0) {
      return Math.round((amt / cap) * 100);
    }
  }
  const numeric = parseInt(device.fill_status || '', 10);
  if (!isNaN(numeric)) return numeric;
  return 0;
}

function daysSince(dateStr?: string | null): number {
  if (!dateStr) return Infinity;
  const then = new Date(dateStr).getTime();
  const now = Date.now();
  return (now - then) / (1000 * 3600 * 24);
}

/** Return number of hours since last seen. */
function hoursSince(dateStr?: string | null): number {
  if (!dateStr) return Infinity;
  const then = new Date(dateStr).getTime();
  const now = Date.now();
  return (now - then) / (1000 * 3600);
}

/**
 * Render a human-readable "time since last check-in":
 * - Just now (less than 1 minute)
 * - X minutes ago (< 60 minutes)
 * - X hours ago (< 24 hours)
 * - X days ago (≥ 24 hours)
 */
function formatTimeSince(dateStr: string | null): string {
  if (!dateStr) return 'Never';
  const then = new Date(dateStr).getTime();
  if (isNaN(then)) return 'Invalid date';

  const now = Date.now();
  const diffMs = now - then;
  if (diffMs < 0) return 'In the future'; // edge case

  const diffSec = diffMs / 1000;
  if (diffSec < 60) return 'Just now';
  if (diffSec < 3600) {
    const mins = Math.floor(diffSec / 60);
    return `${mins} minute${mins > 1 ? 's' : ''} ago`;
  }
  if (diffSec < 86400) {
    const hours = Math.floor(diffSec / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  const days = Math.floor(diffSec / 86400);
  return `${days} day${days > 1 ? 's' : ''} ago`;
}

// Added safeNumber utility function
function safeNumber(val: number | string | null | undefined, precision: number): string {
  const num = Number(val);
  return val != null && !isNaN(num) ? num.toFixed(precision) : '—';
}

/* -----------------------------------------
   TableColumn definition
----------------------------------------- */
export interface TableColumn {
  key: string;
  label: string;
  visible: boolean;
}

// Default columns for Desktop (notice we removed 'online_status')
const DEFAULT_COLUMNS: TableColumn[] = [
  { key: 'device_serial_number', label: 'Serial', visible: true },
  { key: 'device_type', label: 'Device Type', visible: true },
  { key: 'product', label: 'Product', visible: true },
  { key: 'fill', label: 'Fill / Level', visible: true },
  { key: 'battery', label: 'Battery', visible: true },
  { key: 'cellular_strength', label: 'Cellular Strength', visible: true },
  { key: 'last_seen', label: 'Last Seen', visible: true },
  { key: 'city_state', label: 'City / State', visible: true },

  // NEW COLUMN FOR GPS LAST SEEN (desktop default = visible)
  { key: 'gps_last_seen', label: 'GPS Last Seen', visible: true },

  // Hidden by default
  { key: 'last_seen_at', label: 'Last Seen At', visible: false },
  { key: 'microcontroller_serial', label: 'Microcontroller', visible: false },
  { key: 'address', label: 'Address', visible: false },
  { key: 'zip_code', label: 'Zip', visible: false },
  { key: 'country', label: 'Country', visible: false },
  { key: 'sim_imsi', label: 'SIM IMSI', visible: false },
  { key: 'orientation', label: 'Orientation', visible: false },
  { key: 'accelerometer', label: 'Accelerometer', visible: false },
  { key: 'battery_volts', label: 'Battery Volts', visible: false },
  { key: 'solar_volts', label: 'Solar Volts', visible: false },
  { key: 'temperature_f', label: 'Temp (°F)', visible: false },
  { key: 'temperature_c', label: 'Temp (°C)', visible: false },
  { key: 'temperature_f_scale', label: 'Temp Scale (°F)', visible: false },
  { key: 'temperature_c_scale', label: 'Temp Scale (°C)', visible: false },
  { key: 'amount_remaining_gallons', label: 'Remaining (Gal)', visible: false },
  { key: 'total_capacity_gallons', label: 'Capacity (Gal)', visible: false },
  { key: 'radar_mm', label: 'Radar (mm)', visible: false },
  { key: 'tank_name', label: 'Tank Name', visible: false },
  { key: 'uom_name', label: 'Unit of Measure', visible: false },
  { key: 'rectangle_length', label: 'Rect. Length', visible: false },
  { key: 'rectangle_width', label: 'Rect. Width', visible: false },
  { key: 'rectangle_height', label: 'Rect. Height', visible: false },
  { key: 'rectangle_empty_space', label: 'Rect. Empty Space', visible: false },
  { key: 'sds', label: 'SDS Link', visible: false },
  { key: 'label', label: 'Label Link', visible: false },

  // Additional hidden columns
  { key: 'primary_company', label: 'Primary Company', visible: false },
  { key: 'secondary_company', label: 'Secondary Company', visible: false },
  { key: 'tertiary_company', label: 'Tertiary Company', visible: false },
  { key: 'fill_status_raw', label: 'Fill Status', visible: false },
  { key: 'lot_number', label: 'Lot Number', visible: false },
  { key: 'batch_number', label: 'Batch Number', visible: false },
  { key: 'crop', label: 'Crop', visible: false },
  { key: 'gps', label: 'GPS (Lat, Long)', visible: false },
];

// Default columns for Mobile (notice we removed 'online_status')
const MOBILE_DEFAULT_COLUMNS: TableColumn[] = [
  { key: 'device_serial_number', label: 'Serial', visible: true },
  { key: 'product', label: 'Product', visible: true },
  { key: 'fill', label: 'Fill / Level', visible: true },
  { key: 'battery', label: 'Battery', visible: true },
  { key: 'last_seen', label: 'Last Seen', visible: true },

  // Everything else hidden by default
  { key: 'cellular_strength', label: 'Cellular Strength', visible: false },
  { key: 'last_seen_at', label: 'Last Seen At', visible: false },
  { key: 'device_type', label: 'Device Type', visible: false },
  { key: 'microcontroller_serial', label: 'Microcontroller', visible: false },
  { key: 'address', label: 'Address', visible: false },
  { key: 'zip_code', label: 'Zip', visible: false },
  { key: 'country', label: 'Country', visible: false },
  { key: 'sim_imsi', label: 'SIM IMSI', visible: false },
  { key: 'orientation', label: 'Orientation', visible: false },
  { key: 'accelerometer', label: 'Accelerometer', visible: false },
  { key: 'battery_volts', label: 'Battery Volts', visible: false },
  { key: 'solar_volts', label: 'Solar Volts', visible: false },
  { key: 'temperature_f', label: 'Temp (°F)', visible: false },
  { key: 'temperature_c', label: 'Temp (°C)', visible: false },
  { key: 'temperature_f_scale', label: 'Temp (°F) Graphic', visible: false },
  { key: 'temperature_c_scale', label: 'Temp (°C) Graphic', visible: false },
  { key: 'amount_remaining_gallons', label: 'Remaining (Gal)', visible: false },
  { key: 'total_capacity_gallons', label: 'Capacity (Gal)', visible: false },
  { key: 'radar_mm', label: 'Radar (mm)', visible: false },
  { key: 'tank_name', label: 'Tank Name', visible: false },
  { key: 'uom_name', label: 'Unit of Measure', visible: false },
  { key: 'rectangle_length', label: 'Rect. Length', visible: false },
  { key: 'rectangle_width', label: 'Rect. Width', visible: false },
  { key: 'rectangle_height', label: 'Rect. Height', visible: false },
  { key: 'rectangle_empty_space', label: 'Rect. Empty Space', visible: false },
  { key: 'sds', label: 'SDS Link', visible: false },
  { key: 'label', label: 'Label Link', visible: false },

  // Additional hidden columns
  { key: 'primary_company', label: 'Primary Company', visible: false },
  { key: 'secondary_company', label: 'Secondary Company', visible: false },
  { key: 'tertiary_company', label: 'Tertiary Company', visible: false },
  { key: 'fill_status_raw', label: 'Fill Status', visible: false },
  { key: 'lot_number', label: 'Lot Number', visible: false },
  { key: 'batch_number', label: 'Batch Number', visible: false },
  { key: 'crop', label: 'Crop', visible: false },
  { key: 'gps', label: 'GPS (Lat, Long)', visible: false },

  // NEW COLUMN FOR GPS LAST SEEN (mobile default = false)
  { key: 'gps_last_seen', label: 'GPS Last Seen', visible: false },
];

// API keys for table configuration persistence
const DESKTOP_STORAGE_KEY = 'DeviceDashboardTable_Desktop';
const MOBILE_STORAGE_KEY = 'DeviceDashboardTable_Mobile';

/* -----------------------------------------
   API Functions for Table Config
----------------------------------------- */
async function fetchTableConfig(isMobile: boolean) {
  const key = isMobile ? MOBILE_STORAGE_KEY : DESKTOP_STORAGE_KEY;
  try {
    const res = await axios.get<any, any>(`/api/user/edgelink/user/0/settings/${key}/`, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
    });
    return JSON.parse(res.data.setting_value);
  } catch (err: any) {
    if (err?.response?.status === 404) {
      // No saved setting, just return null
      return null;
    }
    console.error('Error fetching table config:', err);
    return null;
  }
}

async function saveTableConfigAPI(
  isMobile: boolean,
  config: { columns: TableColumn[]; sortKey: string; sortDirection: 'asc' | 'desc' },
) {
  const key = isMobile ? MOBILE_STORAGE_KEY : DESKTOP_STORAGE_KEY;
  try {
    await axios.post(
      `/api/user/edgelink/user/0/settings/`,
      {
        setting_key: key,
        setting_value: JSON.stringify(config),
      },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      },
    );
  } catch (err) {
    console.error('Error saving table config:', err);
  }
}

/* -----------------------------------------
   Merge columns from default with user config
----------------------------------------- */
function mergeColumns(defaultCols: TableColumn[], configCols: TableColumn[]): TableColumn[] {
  // We'll build a final array
  const finalCols: TableColumn[] = [];
  const defMap = new Map<string, TableColumn>();
  defaultCols.forEach((dc) => defMap.set(dc.key, dc));

  // 1) First, go through configCols in order and add them IF they exist in defaults
  configCols.forEach((cc) => {
    const match = defMap.get(cc.key);
    if (match) {
      // Use the default column's label in case it changed, but override visible from config
      finalCols.push({
        ...match,
        visible: cc.visible,
      });
      defMap.delete(cc.key); // remove from map so we don't add it twice
    }
  });

  // 2) Then, add any remaining default columns that weren't in config
  // so newly introduced columns also appear
  defaultCols.forEach((dc) => {
    if (defMap.has(dc.key)) {
      finalCols.push(dc);
    }
  });

  return finalCols;
}

/* -----------------------------------------
   Draggable Item
   (We'll just do minimal changes to fix lag
   and let you move columns to the top.)
----------------------------------------- */
const DraggableItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f7f7f7',
  borderRadius: 8,
  marginBottom: 8,
  padding: '8px 12px',
  cursor: 'move',
  '&.drag-over': {
    border: '2px dashed #3f51b5',
  },
}));

export default function DeviceDashboardTable() {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [devices, setDevices] = React.useState<DeviceResult[]>([]);
  const [visibleDevices, setVisibleDevices] = React.useState<DeviceResult[]>([]);

  // Searching & filtering
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [batteryBelow50, setBatteryBelow50] = React.useState(false);
  const [fillFilter, setFillFilter] = React.useState<'any' | 'full' | 'empty'>('any');
  const [timeFilter, setTimeFilter] = React.useState<'any' | '24h' | '7d' | '30d'>('any');
  const [deviceTypeFilter, setDeviceTypeFilter] = React.useState<'any' | 'seed' | 'liquid'>('any');
  const [loadedProductFilter, setLoadedProductFilter] = React.useState(false);
  const [productNameFilter, setProductNameFilter] = React.useState<string>('any');

  // Sorting
  const [sortKey, setSortKey] = React.useState<string>('');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');

  // Columns
  const [columns, setColumns] = React.useState<TableColumn[]>([]);
  const [columnsDialogOpen, setColumnsDialogOpen] = React.useState(false);

  // Draggable states
  const [dragIndex, setDragIndex] = React.useState<number | null>(null);
  const [dragOverIndex, setDragOverIndex] = React.useState<number | null>(null);

  // Device sidebar
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState<DeviceResult | null>(null);

  // Distinct product names (for filter)
  const productNames = React.useMemo(() => {
    const names = new Set<string>();
    devices.forEach((d) => {
      if (d.product_profile?.product_name) {
        names.add(d.product_profile.product_name);
      }
    });
    return Array.from(names);
  }, [devices]);

  // -----------------------------------------
  // Fetch devices on mount
  // -----------------------------------------
  React.useEffect(() => {
    axios
      .get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        if (Array.isArray(res.data.results)) {
          setDevices(res.data.results);
        } else {
          console.error('Unexpected response data:', res.data);
          setDevices([]);
        }
      })
      .catch((err) => {
        console.error('Error fetching devices:', err);
        setDevices([]);
      });
  }, []);

  // On mount (and breakpoint changes), load column config from backend
  React.useEffect(() => {
    (async () => {
      const config = await fetchTableConfig(isMobile);
      if (config) {
        // config.columns might exist
        // We'll merge them with the default columns
        const defaultCols = isMobile ? MOBILE_DEFAULT_COLUMNS : DEFAULT_COLUMNS;
        const merged = mergeColumns(defaultCols, config.columns);
        setColumns(merged);
        setSortKey(config.sortKey || '');
        setSortDirection(config.sortDirection || 'asc');
      } else {
        // No user config: just use defaults
        setColumns(isMobile ? MOBILE_DEFAULT_COLUMNS : DEFAULT_COLUMNS);
        setSortKey('');
        setSortDirection('asc');
      }
    })();
  }, [isMobile]);

  // Whenever columns or sorting changes, save to backend
  React.useEffect(() => {
    if (columns.length > 0) {
      saveTableConfigAPI(isMobile, { columns, sortKey, sortDirection });
    }
  }, [isMobile, columns, sortKey, sortDirection]);

  // -----------------------------------------
  // Searching, Filtering, Sorting
  // -----------------------------------------
  React.useEffect(() => {
    let result = [...devices];

    // Searching
    const txt = searchTerm.trim().toLowerCase();
    if (txt) {
      result = result.filter((d) => {
        const fields: string[] = [];
        fields.push(d.device_serial_number.toLowerCase());
        if (d.microcontroller_serial) fields.push(d.microcontroller_serial.toLowerCase());
        if (d.city) fields.push(d.city.toLowerCase());
        if (d.state) fields.push(d.state.toLowerCase());
        if (d.product_profile?.product_name) {
          fields.push(d.product_profile.product_name.toLowerCase());
        }
        d.companies?.forEach((co) => {
          if (co.primary_edgelink_company_name) {
            fields.push(co.primary_edgelink_company_name.toLowerCase());
          }
          if (co.secondary_edgelink_company_name) {
            fields.push(co.secondary_edgelink_company_name.toLowerCase());
          }
          if (co.tertiary_edgelink_company_name) {
            fields.push(co.tertiary_edgelink_company_name.toLowerCase());
          }
        });
        if (d.tank_config?.tank_name) {
          fields.push(d.tank_config.tank_name.toLowerCase());
        }
        return fields.some((f) => f.includes(txt));
      });
    }

    // Battery filter
    if (batteryBelow50) {
      result = result.filter((d) => parseBatteryPercent(d.battery_life_level) < 50);
    }

    // Fill filter
    if (fillFilter === 'full') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'full');
    } else if (fillFilter === 'empty') {
      result = result.filter((d) => d.fill_status?.toLowerCase() === 'empty');
    }

    // Time filter
    if (timeFilter === '24h') {
      result = result.filter((d) => daysSince(d.last_seen_at) < 1);
    } else if (timeFilter === '7d') {
      result = result.filter((d) => daysSince(d.last_seen_at) >= 7);
    } else if (timeFilter === '30d') {
      result = result.filter((d) => daysSince(d.last_seen_at) >= 30);
    }

    // Device type filter
    if (deviceTypeFilter !== 'any') {
      result = result.filter((d) => {
        const dt = d.device_type?.device_type_name?.toLowerCase() || '';
        return dt === deviceTypeFilter;
      });
    }

    // Loaded product filter
    if (loadedProductFilter) {
      result = result.filter((d) => !!d.product_profile?.product_name);
    }

    // Product name filter
    if (productNameFilter !== 'any') {
      result = result.filter((d) => d.product_profile?.product_name === productNameFilter);
    }

    // Sorting
    if (sortKey) {
      result.sort((a, b) => {
        const valA = getSortValue(a, sortKey);
        const valB = getSortValue(b, sortKey);
        if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
        if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setVisibleDevices(result);
  }, [
    devices,
    searchTerm,
    batteryBelow50,
    fillFilter,
    timeFilter,
    deviceTypeFilter,
    loadedProductFilter,
    productNameFilter,
    sortKey,
    sortDirection,
  ]);

  function getSortValue(dev: DeviceResult, colKey: string): number | string {
    switch (colKey) {
      case 'device_serial_number':
        return dev.device_serial_number.toLowerCase();
      case 'device_type':
        return dev.device_type?.device_type_name?.toLowerCase() || '';
      case 'product':
        return dev.product_profile?.product_name?.toLowerCase() || '';
      case 'fill':
        return getFillPercent(dev);
      case 'battery':
        return parseBatteryPercent(dev.battery_life_level);
      case 'cellular_strength': {
        if (!dev.cellular_strength) return 0;
        switch (dev.cellular_strength.toLowerCase()) {
          case 'strong':
            return 3;
          case 'moderate':
            return 2;
          case 'weak':
            return 1;
          default:
            return 0;
        }
      }
      case 'time_since_last_seen':
        // Sort by difference in time; older => bigger
        return Date.now() - (dev.last_seen_at ? new Date(dev.last_seen_at).getTime() : 0);
      case 'last_seen':
        if (dev.last_seen_at) return new Date(dev.last_seen_at).getTime();
        return 0;
      case 'primary_company':
        return dev.companies?.[0]?.primary_edgelink_company_name?.toLowerCase() || '';
      case 'secondary_company':
        return dev.companies?.[1]?.secondary_edgelink_company_name?.toLowerCase() || '';
      case 'tertiary_company':
        return dev.companies?.[2]?.tertiary_edgelink_company_name?.toLowerCase() || '';
      case 'fill_status_raw':
        return dev.fill_status?.toLowerCase() || '';
      case 'lot_number':
        return dev.product_profile?.lot_number?.toLowerCase() || '';
      case 'batch_number':
        return dev.product_profile?.batch_number?.toLowerCase() || '';
      case 'crop':
        return dev.product_profile?.crop?.toLowerCase() || '';
      case 'gps':
        return dev.gps_lat && dev.gps_long ? `${dev.gps_lat},${dev.gps_long}` : '';
      default:
        return 0;
    }
  }

  function handleRowClick(device: DeviceResult) {
    setSelectedDevice(device);
    setDrawerOpen(true);
  }
  function closeSidebar() {
    setDrawerOpen(false);
    setSelectedDevice(null);
  }

  function handleClearFilters() {
    setBatteryBelow50(false);
    setFillFilter('any');
    setTimeFilter('any');
    setDeviceTypeFilter('any');
    setLoadedProductFilter(false);
    setProductNameFilter('any');
    setSearchTerm('');
  }

  // Re-fetch devices after sidebar form save
  async function handleDeviceUpdated() {
    try {
      const resp = await axios.get<DevicesResponse>('/api/user/edgelink/devices/dashboard', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      if (resp.data.success && Array.isArray(resp.data.results)) {
        setDevices(resp.data.results);

        // If there's still a selected device, update it
        if (selectedDevice) {
          const updated = resp.data.results.find((d) => d.device_serial_number === selectedDevice.device_serial_number);
          if (updated) {
            setSelectedDevice(updated);
          }
        }
      }
    } catch (err) {
      console.error('Failed to refresh device data:', err);
    }
  }

  function handleSelectDeviceFromSearch(device: DeviceResult) {
    setSearchOpen(false);
    handleRowClick(device);
  }

  // -----------------------------------------
  // Drag & Drop for column reordering
  // -----------------------------------------
  function handleDragStart(e: React.DragEvent<HTMLDivElement>, index: number) {
    setDragIndex(index);
    setDragOverIndex(null);
  }

  // Less “lag”: only update if overIndex changed
  function handleDragOver(e: React.DragEvent<HTMLDivElement>, overIndex: number) {
    e.preventDefault();
    if (overIndex !== dragOverIndex) {
      setDragOverIndex(overIndex);
    }
  }

  // Simpler insertion so you can move a column to the very top
  function handleDrop(e: React.DragEvent<HTMLDivElement>, dropIndex: number) {
    e.preventDefault();
    if (dragIndex === null || dropIndex === null || dragIndex === dropIndex) {
      setDragIndex(null);
      setDragOverIndex(null);
      return;
    }
    setColumns((prevCols) => {
      const newCols = [...prevCols];
      const [moved] = newCols.splice(dragIndex, 1);
      // If the column was dragged from above, removing it shifts indices down by 1
      if (dragIndex < dropIndex) {
        dropIndex--;
      }
      newCols.splice(dropIndex, 0, moved);
      return newCols;
    });
    setDragIndex(null);
    setDragOverIndex(null);
  }

  // Toggle column visibility
  function handleToggleVisible(colKey: string) {
    setColumns((prev) => prev.map((c) => (c.key === colKey ? { ...c, visible: !c.visible } : c)));
  }

  // Reset to defaults
  function handleResetToDefaults() {
    const def = isMobile ? MOBILE_DEFAULT_COLUMNS : DEFAULT_COLUMNS;
    setColumns([...def]); // copy
    setSortKey('');
    setSortDirection('asc');
  }

  return (
    <Box sx={{ width: '100%', minHeight: '100vh', bgcolor: '#f5f7fa' }}>
      {/* Title bar */}
      <Paper
        elevation={3}
        sx={{
          mb: 2,
          p: 2,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'linear-gradient(to right, #203a43, #2c5364)',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <DevicesIcon sx={{ fontSize: 32, color: '#fff' }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
            EdgeLink Devices
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          {/* Hide map/card/table on mobile */}
          {!isMobile && (
            <>
              <Tooltip title="Map View">
                <IconButton onClick={() => history.push('/edgelink/devices/map')}>
                  <MapIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Card View">
                <IconButton onClick={() => history.push('/edgelink/devices/cards')}>
                  <ViewAgendaIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Table View">
                <IconButton onClick={() => history.push('/edgelink/devices/table')}>
                  <TableChartIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </>
          )}

          {/* Configure Columns & Sorting */}
          <Tooltip title="Configure Columns & Sorting">
            <IconButton onClick={() => setColumnsDialogOpen(true)}>
              <ViewColumnIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>

          {/* Filter */}
          <Tooltip title="Filter">
            <IconButton onClick={() => setFilterOpen(true)}>
              <FilterListIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>

          {/* Search */}
          <Tooltip title="Search">
            <IconButton onClick={() => setSearchOpen(true)}>
              <SearchIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>

      {/* The Table */}
      <Box sx={{ p: 2, pt: 0 }}>
        <Paper
          sx={{
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #ddd',
            boxShadow: 2,
            transition: 'all 0.3s ease',
            [theme.breakpoints.down('sm')]: {
              borderRadius: 0,
              border: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <TableContainer
            sx={{
              maxWidth: '100%',
              overflowX: 'auto',
              transition: 'all 0.3s ease',
              [theme.breakpoints.down('sm')]: {
                borderRadius: 0,
                margin: 0,
              },
            }}
          >
            <Table
              size="small"
              sx={{
                tableLayout: 'auto',
                whiteSpace: 'nowrap',
                // row striping & more padding
                '& thead th': {
                  py: 1.5,
                  fontWeight: 'bold',
                  backgroundColor: '#f5f7fa',
                  borderBottom: '1px solid #ddd',
                },
                '& tbody td': {
                  py: 1.0,
                  borderBottom: '1px solid #eee',
                },
                '& tbody tr:nth-of-type(even)': {
                  backgroundColor: '#fafafa',
                },
                '& tbody tr:hover': {
                  backgroundColor: '#f1f1f1',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns
                    .filter((col) => col.visible)
                    .map((col) => (
                      <TableCell key={col.key}>{col.label}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleDevices.map((dev) => (
                  <TableRow
                    key={dev.edgelink_device_id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(dev)}
                  >
                    {columns
                      .filter((c) => c.visible)
                      .map((col) => (
                        <TableCell key={col.key}>{renderCellContent(dev, col)}</TableCell>
                      ))}
                  </TableRow>
                ))}
                {visibleDevices.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={columns.filter((c) => c.visible).length || 1}
                      sx={{ textAlign: 'center', py: 3 }}
                    >
                      <Typography variant="body2">No matching devices found.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      {/* Device Sidebar */}
      <DeviceSidebar
        isOpen={drawerOpen}
        onClose={closeSidebar}
        selectedDevice={selectedDevice}
        popoutEnabled={true}
        showMap={true}
        onDeviceUpdated={handleDeviceUpdated}
      />

      {/* DeviceSearchDialog */}
      <DeviceSearchDialog
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        visibleDevices={visibleDevices}
        handleSelectDevice={handleSelectDeviceFromSearch}
      />

      {/* Filter Dialog */}
      <Dialog
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { borderRadius: 3 } }}
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>Filter Devices</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={batteryBelow50} onChange={() => setBatteryBelow50(!batteryBelow50)} />}
                label="Battery < 50%"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" sx={{ width: '100%' }}>
                <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Fill Status</FormLabel>
                <RadioGroup
                  value={fillFilter}
                  onChange={(e) => setFillFilter(e.target.value as 'any' | 'full' | 'empty')}
                >
                  <FormControlLabel value="any" control={<Radio />} label="Any" />
                  <FormControlLabel value="full" control={<Radio />} label="Full Only" />
                  <FormControlLabel value="empty" control={<Radio />} label="Empty Only" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" sx={{ width: '100%' }}>
                <FormLabel sx={{ fontWeight: 'bold', mb: 1 }}>Last Seen</FormLabel>
                <RadioGroup
                  value={timeFilter}
                  onChange={(e) => setTimeFilter(e.target.value as 'any' | '24h' | '7d' | '30d')}
                >
                  <FormControlLabel value="any" control={<Radio />} label="Any Time" />
                  <FormControlLabel value="24h" control={<Radio />} label="< 24 Hours" />
                  <FormControlLabel value="7d" control={<Radio />} label="≥ 7 Days" />
                  <FormControlLabel value="30d" control={<Radio />} label="≥ 30 Days" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Device Type</InputLabel>
                <Select
                  value={deviceTypeFilter}
                  label="Device Type"
                  onChange={(e) => setDeviceTypeFilter(e.target.value as 'any' | 'seed' | 'liquid')}
                >
                  <MenuItem value="any">Any</MenuItem>
                  <MenuItem value="seed">Seed</MenuItem>
                  <MenuItem value="liquid">Liquid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={loadedProductFilter} onChange={(e) => setLoadedProductFilter(e.target.checked)} />
                }
                label="Loaded with Product"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Product</InputLabel>
                <Select
                  value={productNameFilter}
                  label="Product"
                  onChange={(e) => setProductNameFilter(e.target.value)}
                >
                  <MenuItem value="any">Any</MenuItem>
                  {productNames.map((pn) => (
                    <MenuItem key={pn} value={pn}>
                      {pn}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClearFilters} sx={{ borderRadius: 3, mr: 1 }}>
            Clear
          </Button>
          <Button variant="contained" onClick={() => setFilterOpen(false)} sx={{ borderRadius: 3 }}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Column Chooser Dialog */}
      <Dialog open={columnsDialogOpen} onClose={() => setColumnsDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Configure Columns &amp; Sorting</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Drag to reorder columns, toggle visibility, and set the sort column/direction.
          </Typography>

          {/* Sort Settings */}
          <Paper variant="outlined" sx={{ p: 2, mb: 3, borderRadius: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Sort By
            </Typography>
            <Stack direction="row" spacing={2}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Column</InputLabel>
                <Select label="Column" value={sortKey} onChange={(e) => setSortKey(e.target.value)}>
                  <MenuItem value="">(None)</MenuItem>
                  {columns.map((c) => (
                    <MenuItem key={c.key} value={c.key}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Direction</InputLabel>
                <Select
                  label="Direction"
                  value={sortDirection}
                  onChange={(e) => {
                    const dir = e.target.value as 'asc' | 'desc';
                    setSortDirection(dir);
                  }}
                >
                  <MenuItem value="asc">Ascending</MenuItem>
                  <MenuItem value="desc">Descending</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Paper>

          {/* Columns List & Reset */}
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', alignSelf: 'center' }}>
              Columns
            </Typography>
            <Button variant="outlined" onClick={handleResetToDefaults}>
              Reset to Default
            </Button>
          </Stack>

          {columns.map((col, index) => {
            const isDragOver = index === dragOverIndex && dragIndex !== index;
            return (
              <DraggableItem
                key={col.key}
                draggable
                className={isDragOver ? 'drag-over' : ''}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
              >
                <IconButton size="small" sx={{ mr: 1 }}>
                  <DragIndicatorIcon fontSize="small" />
                </IconButton>
                <Checkbox checked={col.visible} onChange={() => handleToggleVisible(col.key)} sx={{ mr: 1 }} />
                <Typography sx={{ flex: 1 }}>{col.label}</Typography>
              </DraggableItem>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setColumnsDialogOpen(false)}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

/* -----------------------------------------
   Cell Rendering Helper
----------------------------------------- */
function renderCellContent(dev: DeviceResult, col: TableColumn) {
  switch (col.key) {
    case 'device_serial_number':
      return <Typography sx={{ fontWeight: 600 }}>{dev.device_serial_number}</Typography>;
    case 'microcontroller_serial':
      return dev.microcontroller_serial || '—';
    case 'device_type':
      return dev.device_type?.device_type_name || '—';
    case 'product':
      return dev.product_profile?.product_name || '—';
    case 'fill': {
      const fillPct = getFillPercent(dev);
      const isLiquid = dev.device_type?.device_type_id === 1;
      if (isLiquid) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box sx={{ width: 60 }}>
              <LinearProgress variant="determinate" value={fillPct} sx={{ height: 8, borderRadius: 4 }} />
            </Box>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {fillPct}%
            </Typography>
          </Stack>
        );
      }
      return dev.fill_status || 'N/A';
    }
    case 'battery':
      return (
        <Stack direction="row" spacing={0.5} alignItems="center">
          {getBatteryIcon(dev.battery_life_level)}
          <Typography variant="body2">{dev.battery_life_level || 'N/A'}</Typography>
        </Stack>
      );
    case 'cellular_strength':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          {getCellularIcon(dev.cellular_strength)}
          <Typography variant="body2">{dev.cellular_strength || 'Unknown'}</Typography>
        </Stack>
      );

    case 'last_seen': {
      // If it's been <= 10 hours, show LinkIcon. Otherwise, LinkOffIcon.
      const offline = hoursSince(dev.last_seen_at) > 10;
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          {offline ? (
            <LinkOffIcon sx={{ fontSize: 18, color: '#f44336' }} />
          ) : (
            <LinkIcon sx={{ fontSize: 18, color: '#4caf50' }} />
          )}
          <Typography variant="body2">{formatTimeSince(dev.last_seen_at)}</Typography>
        </Stack>
      );
    }
    case 'last_seen_at':
      return <Typography variant="body2">{formatLastSeen(dev.last_seen_at)}</Typography>;
    case 'city_state':
      return `${dev.city || '—'}, ${dev.state || ''}`;
    case 'address':
      return dev.address || '—';
    case 'zip_code':
      return dev.zip_code || '—';
    case 'country':
      return dev.country || '—';
    case 'sim_imsi':
      return dev.sim_imsi || '—';
    case 'orientation':
      return dev.orientation || '—';
    case 'accelerometer':
      return dev.accelerometer || '—';
    case 'battery_volts':
      return dev.battery_volts != null ? `${safeNumber(dev.battery_volts, 2)} V` : '—';
    case 'solar_volts':
      return dev.solar_volts || '—';
    case 'temperature_f':
      return safeNumber(dev.temperature_in_f, 1) + '°F';
    case 'temperature_c':
      return safeNumber(dev.temperature_in_c, 1) + '°C';
    case 'amount_remaining_gallons':
      return safeNumber(dev.amount_remaining_gallons, 1);
    case 'total_capacity_gallons':
      return safeNumber(dev.total_capacity_gallons, 1);
    case 'radar_mm': {
      if (!dev.radar_mm) return '—';
      const val = Number(dev.radar_mm);
      return isNaN(val) ? '—' : String(val);
    }
    case 'tank_name':
      return dev.tank_config?.tank_name || '—';
    case 'uom_name':
      return dev.tank_config?.uom_name || '—';
    case 'rectangle_length':
      return dev.tank_config?.rectangle_length || '—';
    case 'rectangle_width':
      return dev.tank_config?.rectangle_width || '—';
    case 'rectangle_height':
      return dev.tank_config?.rectangle_height || '—';
    case 'rectangle_empty_space':
      return dev.tank_config?.rectangle_empty_space || '—';
    case 'sds': {
      const url = dev.product_profile?.safety_data_sheet_attachment;
      return url ? (
        <Link href={url} target="_blank" rel="noopener" underline="hover">
          SDS
        </Link>
      ) : (
        '—'
      );
    }
    case 'label': {
      const url = dev.product_profile?.chemical_label_attachment;
      return url ? (
        <Link href={url} target="_blank" rel="noopener" underline="hover">
          Label
        </Link>
      ) : (
        '—'
      );
    }
    case 'primary_company':
      return dev.companies?.[0]?.primary_edgelink_company_name || '—';
    case 'secondary_company':
      return dev.companies?.[1]?.secondary_edgelink_company_name || '—';
    case 'tertiary_company':
      return dev.companies?.[2]?.tertiary_edgelink_company_name || '—';
    case 'fill_status_raw':
      return dev.fill_status || '—';
    case 'lot_number':
      return dev.product_profile?.lot_number || '—';
    case 'batch_number':
      return dev.product_profile?.batch_number || '—';
    case 'crop':
      return dev.product_profile?.crop || '—';
    case 'gps':
      return dev.gps_lat && dev.gps_long ? `${dev.gps_lat}, ${dev.gps_long}` : '—';

    // NEW CASE FOR GPS LAST SEEN
    case 'gps_last_seen': {
      const gpsTs = dev.gps_timestamp;
      if (!gpsTs) {
        // No GPS fix ever
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <GpsFixedIcon sx={{ fontSize: 18, color: '#f44336' }} />
            <Typography variant="body2">Never</Typography>
          </Stack>
        );
      }
      // If older than 10 hours => stale
      const isStale = hoursSince(gpsTs) > 10;
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <GpsFixedIcon sx={{ fontSize: 18, color: isStale ? '#f44336' : '#4caf50' }} />
          <Typography variant="body2">{formatTimeSince(gpsTs)}</Typography>
        </Stack>
      );
    }

    // In the renderCellContent function, replace ONLY the temperature_f and temperature_c cases with this more elegant approach:
    case 'temperature_f_scale': {
      const tempF = Number(dev.temperature_in_f);
      if (isNaN(tempF)) return '—';

      const min = 0;
      const max = 120;
      const percent = ((tempF - min) / (max - min)) * 100;
      const clamped = Math.min(Math.max(percent, 0), 100);

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 100 }}>
          <Box
            sx={{
              width: 80,
              height: 6,
              borderRadius: 3,
              background: 'linear-gradient(to right, #add8e6 0%, #fff9c4 50%, #f8bbd0 100%)',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: `${clamped}%`,
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: '#666',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ ml: 1 }}>
            {tempF.toFixed(1)}°F
          </Typography>
        </Box>
      );
    }

    case 'temperature_c_scale': {
      const tempC = Number(dev.temperature_in_c);
      if (isNaN(tempC)) return '—';

      const min = -30;
      const max = 50;
      const percent = ((tempC - min) / (max - min)) * 100;
      const clamped = Math.min(Math.max(percent, 0), 100);

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 100 }}>
          <Box
            sx={{
              width: 80,
              height: 6,
              borderRadius: 3,
              background: 'linear-gradient(to right, #add8e6 0%, #fff9c4 50%, #f8bbd0 100%)',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: `${clamped}%`,
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: '#666',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Box>
          <Typography variant="body2" sx={{ ml: 1 }}>
            {tempC.toFixed(1)}°C
          </Typography>
        </Box>
      );
    }

    default:
      return '—';
  }
}
