import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  ListItem,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
  CircularProgress,
  styled,
  DialogContentText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

// A small styled wrapper to fix potential Autocomplete width issues
const AutocompleteWrapper = styled('div')({
  width: '100%',
  '& .MuiAutocomplete-root': { width: '100%' },
  '& .MuiFormControl-root': { width: '100%' },
  '& .MuiInputBase-root': { width: '100%' },
});

import axios from '../../../utils/axios.utils';
import {
  DataBridgeAdapter,
  AdaptersResponse,
  ExternalApiPartnerOption,
  PartnerResponse,
  IntegrationDetail,
  IntegrationMachine,
  IntegrationMachineKeyValue,
} from '../../models/dataBridgeModels';

interface IntegrationFormProps {
  open: boolean;
  onClose: (reloadNeeded?: boolean) => void;
  integrationId: number | null;
  isCreateMode: boolean;
}

// A small helper to parse JSON arrays from string values
function parseArrayValue(val: string): string[] {
  try {
    const parsed = JSON.parse(val);
    if (Array.isArray(parsed)) return parsed;
  } catch {
    // ignore
  }
  return [];
}

// A helper to parse options out of a definition's description
// Supports both new JSON array format "Options: [ \"value1\", \"value2\" ]"
// and legacy format "Options:(value1,value2)"
function parseOptionsFromDescription(desc: string): string[] {
  // Try to match the new JSON array format
  const jsonMatch = desc.match(/Options:\s*\[(.*?)\]/);
  if (jsonMatch) {
    try {
      // Parse the JSON array
      const arrayStr = `[${jsonMatch[1]}]`;
      const parsed = JSON.parse(arrayStr);
      if (Array.isArray(parsed)) return parsed;
    } catch {
      // If parsing fails, fall back to legacy format
    }
  }

  // Fall back to the legacy format
  const legacyMatch = desc.match(/Options:\(([^)]+)\)/);
  if (legacyMatch) {
    return legacyMatch[1].split(',').map((s) => s.trim());
  }

  return [];
}

/**
 * Reconcile the integration’s existing machine key-values with the adapter’s definitions.
 * - If a key-value exists in integration but is not found in the adapter definitions, mark it as "unrecognizedInAdapter".
 * - If a definition exists but is not found in the existing machine key-values, append a new one with default_value.
 */
function reconcileMachineKeyValues(
  machines: IntegrationMachine[],
  adapterDefinitions: {
    databridge_adapter_key_definition_id: number;
    key: string;
    name: string;
    data_type: 'string' | 'boolean' | 'array' | 'number';
    default_value: string;
    description?: string;
  }[],
): IntegrationMachine[] {
  const defsByKey: Record<string, typeof adapterDefinitions[number]> = {};
  adapterDefinitions.forEach((def) => {
    defsByKey[def.key] = def;
  });

  return machines.map((mach) => {
    // We'll build a new array of key_values in the correct order, matching the adapter’s definitions
    const existingKVs = mach.key_values || [];

    // Step 1: Mark any existing KVs that don't appear in the adapter definitions
    existingKVs.forEach((kv) => {
      const foundDef = defsByKey[kv.key];
      if (!foundDef) {
        // Mark as unrecognized in adapter
        // We'll store a custom property so the UI can highlight it
        (kv as any).unrecognizedInAdapter = true;
      } else {
        // We found a definition => attach the definition ID/data_type so we can save later
        kv.databridge_adapter_key_definition_id = foundDef.databridge_adapter_key_definition_id;
        kv.data_type = foundDef.data_type;
        (kv as any).unrecognizedInAdapter = false;
      }
    });

    // Step 2: For each definition in the adapter, if there's no existing KV, create one
    const newKVs: IntegrationMachineKeyValue[] = [];
    adapterDefinitions.forEach((def) => {
      // Does the machine have an existing KV with def.key?
      const foundKV = existingKVs.find((kv) => kv.key === def.key);
      if (foundKV) {
        // Use the existing one
        newKVs.push(foundKV);
      } else {
        // Create a new KV with the default value
        newKVs.push({
          databridge_adapter_key_definition_id: def.databridge_adapter_key_definition_id,
          key: def.key,
          name: def.name,
          data_type: def.data_type,
          value: def.default_value,
        });
      }
    });

    // Step 3: Append any truly “unrecognized” KVs that are not in the adapter’s definitions but do exist
    // or you can skip them if you want. We'll keep them so the user sees them in the UI
    existingKVs.forEach((kv) => {
      if ((kv as any).unrecognizedInAdapter) {
        // It's not recognized => we can place it at the end or keep original order
        newKVs.push(kv);
      }
    });

    return {
      ...mach,
      key_values: newKVs,
    };
  });
}

export default function IntegrationForm({ open, onClose, integrationId, isCreateMode }: IntegrationFormProps) {
  // --------------------------------------------------------------------------
  // State variables
  // --------------------------------------------------------------------------
  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [awsSecretId, setAwsSecretId] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [authorizationCode, setAuthorizationCode] = useState('');

  // Fetched Adapters & Partners
  const [adapters, setAdapters] = useState<DataBridgeAdapter[]>([]);
  const [partners, setPartners] = useState<ExternalApiPartnerOption[]>([]);
  const [selectedAdapter, setSelectedAdapter] = useState<DataBridgeAdapter | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<ExternalApiPartnerOption | null>(null);

  // Merged machine data
  const [machinesData, setMachinesData] = useState<IntegrationMachine[]>([]);

  // UI states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [fetchesDone, setFetchesDone] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [pendingMachineDelete, setPendingMachineDelete] = useState<number | null>(null);
  const [showMachineDeleteConfirm, setShowMachineDeleteConfirm] = useState(false);

  // For unsaved changes warnings
  const [formChanged, setFormChanged] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const [initialFormState, setInitialFormState] = useState<any>(null);
  const [hasInitializedForm, setHasInitializedForm] = useState(false);

  // We store a separate machine to add (instead of adding it automatically)
  const [machineToAdd, setMachineToAdd] = useState<{ machine_id: number; name: string; serial_number: string } | null>(
    null,
  );

  // Confirm dialog for adapter changes
  const [showAdapterConfirm, setShowAdapterConfirm] = useState(false);
  const [pendingAdapter, setPendingAdapter] = useState<DataBridgeAdapter | null>(null);
  const [adapterConfirmText, setAdapterConfirmText] = useState('');

  // Confirm dialog for partner changes
  const [showPartnerConfirm, setShowPartnerConfirm] = useState(false);
  const [pendingPartner, setPendingPartner] = useState<ExternalApiPartnerOption | null>(null);
  const [partnerConfirmText, setPartnerConfirmText] = useState('');

  // --------------------------------------------------------------------------
  // 1) Fetch Adapters & Partners
  // --------------------------------------------------------------------------
  const fetchAdaptersAndPartners = async () => {
    setLoading(true);
    setError('');
    try {
      const [adapRes, partRes] = await Promise.all([
        axios.get<AdaptersResponse>('api/databridge_adapters/', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        }),
        axios.get<PartnerResponse>('api/external_api_partner/', {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        }),
      ]);

      setAdapters(adapRes.data.results || []);
      const mappedPartners: ExternalApiPartnerOption[] = partRes.data.map((p) => ({
        external_api_partner_id: p.external_api_partner_id,
        name: p.name,
        machines: p.machines,
      }));
      setPartners(mappedPartners);
      setFetchesDone(true);
    } catch (err: any) {
      console.error('Failed to load adapters/partners:', err);
      setError('Failed to load Adapters or Partners');
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------------
  // 2) If editing, fetch Integration detail + Reconcile with adapter definitions
  // --------------------------------------------------------------------------
  const fetchIntegrationDetail = async (id: number) => {
    setLoading(true);
    setError('');
    try {
      const detailRes = await axios.get<{ success: boolean; message: string; result: IntegrationDetail }>(
        `api/databridge_integrations/${id}/`,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      );
      const data = detailRes.data.result;

      // Fill top-level fields
      setName(data.name);
      setEnabled(data.enabled);
      setAwsSecretId(data.aws_secret_id || '');
      setRefreshToken(data.refresh_token || '');
      setAuthorizationCode(data.authorization_code || '');

      // Attempt to select the correct adapter from the already fetched list
      const foundAdapter = adapters.find((a) => a.databridge_adapter_id === data.adapter.databridge_adapter_id);
      setSelectedAdapter(foundAdapter || null);

      // Attempt to select the correct partner
      const foundPartner = partners.find((p) => p.external_api_partner_id === data.partner.external_api_partner_id);
      setSelectedPartner(foundPartner || null);

      // Step: Reconcile existing machine key-values with the adapter definitions
      if (foundAdapter) {
        const mergedMachines = reconcileMachineKeyValues(data.machines || [], foundAdapter.key_definitions || []);
        setMachinesData(mergedMachines);
      } else {
        // If we can't find the adapter yet, just store data as-is. We'll re-merge once the user picks an adapter
        setMachinesData(data.machines || []);
      }
    } catch (err: any) {
      console.error('Failed to load integration detail:', err);
      setError('Failed to load integration detail.');
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------------
  // Effects
  // --------------------------------------------------------------------------
  // (A) On open => fetch Adapters & Partners
  useEffect(() => {
    if (open) {
      setFetchesDone(false);
      fetchAdaptersAndPartners();
    }
  }, [open]);

  // (B) Once we have them, if not create => fetch detail
  useEffect(() => {
    if (open && fetchesDone) {
      if (isCreateMode) {
        // Reset for create
        setName('');
        setEnabled(true);
        setAwsSecretId('');
        setRefreshToken('');
        setAuthorizationCode('');
        setSelectedAdapter(null);
        setSelectedPartner(null);
        setMachinesData([]);
        setError('');

        setFormChanged(false);
        setHasInitializedForm(false);
        setInitialFormState({
          name: '',
          enabled: true,
          awsSecretId: '',
          refreshToken: '',
          authorizationCode: '',
          adapter: null,
          partner: null,
          machines: '[]',
        });
      } else if (integrationId) {
        fetchIntegrationDetail(integrationId);
      }
    }
  }, [open, fetchesDone, isCreateMode, integrationId]);

  // (C) Capture initial form state
  useEffect(() => {
    if (!loading && open && fetchesDone && !hasInitializedForm) {
      const initialState = {
        name,
        enabled,
        awsSecretId,
        refreshToken,
        authorizationCode,
        adapter: selectedAdapter ? selectedAdapter.databridge_adapter_id : null,
        partner: selectedPartner ? selectedPartner.external_api_partner_id : null,
        machines: JSON.stringify(machinesData),
      };
      setInitialFormState(initialState);
      setHasInitializedForm(true);
      setFormChanged(false);
    }
  }, [
    loading,
    open,
    fetchesDone,
    hasInitializedForm,
    name,
    enabled,
    awsSecretId,
    refreshToken,
    authorizationCode,
    selectedAdapter,
    selectedPartner,
    machinesData,
  ]);

  // (D) Check for form changes
  useEffect(() => {
    if (!initialFormState || !hasInitializedForm) return;

    const currentState = {
      name,
      enabled,
      awsSecretId,
      refreshToken,
      authorizationCode,
      adapter: selectedAdapter ? selectedAdapter.databridge_adapter_id : null,
      partner: selectedPartner ? selectedPartner.external_api_partner_id : null,
      machines: JSON.stringify(machinesData),
    };

    const hasChanged =
      initialFormState.name !== currentState.name ||
      initialFormState.enabled !== currentState.enabled ||
      initialFormState.awsSecretId !== currentState.awsSecretId ||
      initialFormState.refreshToken !== currentState.refreshToken ||
      initialFormState.authorizationCode !== currentState.authorizationCode ||
      initialFormState.adapter !== currentState.adapter ||
      initialFormState.partner !== currentState.partner ||
      initialFormState.machines !== currentState.machines;

    if (hasChanged !== formChanged) {
      setFormChanged(hasChanged);
    }
  }, [
    name,
    enabled,
    awsSecretId,
    refreshToken,
    authorizationCode,
    selectedAdapter,
    selectedPartner,
    machinesData,
    initialFormState,
    hasInitializedForm,
    formChanged,
  ]);

  // (E) If the dialog closes, reset
  useEffect(() => {
    if (!open) {
      setHasInitializedForm(false);
    }
  }, [open]);

  // --------------------------------------------------------------------------
  // Handle Close & Unsaved Changes
  // --------------------------------------------------------------------------
  const handleClose = useCallback(() => {
    if (formChanged) {
      setShowUnsavedDialog(true);
    } else {
      onClose(false);
    }
  }, [formChanged, onClose]);

  const handleConfirmClose = () => {
    setShowUnsavedDialog(false);
    onClose(false);
  };

  const handleCancelClose = () => {
    setShowUnsavedDialog(false);
  };

  // --------------------------------------------------------------------------
  // Changing the Adapter & Partner with Confirm
  // --------------------------------------------------------------------------
  const onAdapterChange = (newAdapter: DataBridgeAdapter | null) => {
    if (!newAdapter) {
      setSelectedAdapter(null);
      return;
    }

    // If there's an existing adapter, or we just want user to confirm
    if (
      !isCreateMode &&
      selectedAdapter &&
      selectedAdapter.databridge_adapter_id !== newAdapter.databridge_adapter_id
    ) {
      setPendingAdapter(newAdapter);
      setAdapterConfirmText('');
      setShowAdapterConfirm(true);
    } else {
      setSelectedAdapter(newAdapter);
    }
  };

  const confirmAdapterChange = () => {
    if (!pendingAdapter) return;
    // The user must type the adapter's name exactly
    if (adapterConfirmText === pendingAdapter.name) {
      // Actually set it
      setSelectedAdapter(pendingAdapter);
      setPendingAdapter(null);
      setShowAdapterConfirm(false);
      setAdapterConfirmText('');
      // If we want, we can re-merge machines if changing the adapter
      const mergedMachines = reconcileMachineKeyValues(machinesData, pendingAdapter.key_definitions || []);
      setMachinesData(mergedMachines);
    }
  };

  const cancelAdapterChange = () => {
    setPendingAdapter(null);
    setAdapterConfirmText('');
    setShowAdapterConfirm(false);
  };

  const onPartnerChange = (newPartner: ExternalApiPartnerOption | null) => {
    if (!newPartner) {
      setSelectedPartner(null);
      return;
    }

    if (
      !isCreateMode &&
      selectedPartner &&
      selectedPartner.external_api_partner_id !== newPartner.external_api_partner_id
    ) {
      setPendingPartner(newPartner);
      setPartnerConfirmText('');
      setShowPartnerConfirm(true);
    } else {
      setSelectedPartner(newPartner);
    }
  };

  const confirmPartnerChange = () => {
    if (!pendingPartner) return;
    if (partnerConfirmText === pendingPartner.name) {
      setSelectedPartner(pendingPartner);
      setPendingPartner(null);
      setShowPartnerConfirm(false);
      setPartnerConfirmText('');
    }
  };

  const cancelPartnerChange = () => {
    setPendingPartner(null);
    setPartnerConfirmText('');
    setShowPartnerConfirm(false);
  };

  // --------------------------------------------------------------------------
  // Add / Remove Machines
  // --------------------------------------------------------------------------
  const [selectedMachineOption, setSelectedMachineOption] = useState<{
    machine_id: number;
    name: string;
    serial_number: string;
  } | null>(null);

  const handleAddMachineClick = () => {
    if (!selectedAdapter) return;
    if (!selectedMachineOption) return;
    // If this machine is already in the list, skip
    if (machinesData.some((m) => m.machine_id === selectedMachineOption.machine_id)) return;

    // Build default key_values from the adapter's definitions
    const defs = selectedAdapter.key_definitions || [];
    const key_values: IntegrationMachineKeyValue[] = defs.map((def) => ({
      databridge_adapter_key_definition_id: def.databridge_adapter_key_definition_id,
      key: def.key,
      name: def.name,
      data_type: def.data_type,
      value: def.default_value,
    }));

    const newMachine: IntegrationMachine = {
      machine_id: selectedMachineOption.machine_id,
      machine_name: selectedMachineOption.name,
      machine_serial_number: selectedMachineOption.serial_number,
      key_values,
    };
    setMachinesData((prev) => [...prev, newMachine]);
    // Clear out the local selection
    setSelectedMachineOption(null);
  };

  const handleConfirmRemoveMachine = (machineId: number) => {
    setPendingMachineDelete(machineId);
    setShowMachineDeleteConfirm(true);
  };

  const handleRemoveMachine = () => {
    if (pendingMachineDelete === null) return;

    setMachinesData((prev) => prev.filter((m) => m.machine_id !== pendingMachineDelete));
    setShowMachineDeleteConfirm(false);
    setPendingMachineDelete(null);
  };

  // State for tracking which chip is being edited
  const [editingChip, setEditingChip] = useState<{
    machineIndex: number;
    keyIndex: number;
    chipIndex: number;
    value: string;
  } | null>(null);

  // Changing the value of a particular key-value
  const handleChangeKeyValue = (machineIndex: number, keyIndex: number, newValue: string | string[]) => {
    const newMachines = [...machinesData];
    const kv = { ...newMachines[machineIndex].key_values[keyIndex] };

    if (kv.data_type === 'array') {
      kv.value = JSON.stringify(newValue);
    } else {
      kv.value = newValue as string;
    }
    newMachines[machineIndex].key_values[keyIndex] = kv;
    setMachinesData(newMachines);
  };

  // Handles starting chip edit mode on double-click
  const handleStartChipEdit = (machineIndex: number, keyIndex: number, chipIndex: number, initialValue: string) => {
    setEditingChip({
      machineIndex,
      keyIndex,
      chipIndex,
      value: initialValue,
    });
  };

  // Handles saving edited chip value
  const handleSaveChipEdit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && editingChip) {
      const { machineIndex, keyIndex, chipIndex, value } = editingChip;

      try {
        // Get current array values
        const currentValues = parseArrayValue(machinesData[machineIndex].key_values[keyIndex].value);

        // If the value is empty, remove the chip
        if (value.trim() === '') {
          currentValues.splice(chipIndex, 1);
        } else {
          // Update specific chip value
          currentValues[chipIndex] = value.trim();
        }

        // Save updated array
        handleChangeKeyValue(machineIndex, keyIndex, currentValues);
      } catch (error) {
        console.error('Error updating chip value:', error);
      }

      // Exit edit mode
      setEditingChip(null);
    } else if (event.key === 'Escape') {
      // Cancel editing on Escape key
      setEditingChip(null);
    }
  };

  // Handle blur event for chip editing
  const handleChipEditBlur = () => {
    if (editingChip) {
      const { machineIndex, keyIndex, chipIndex, value } = editingChip;

      try {
        // Get current array values
        const currentValues = parseArrayValue(machinesData[machineIndex].key_values[keyIndex].value);

        // Only update if the value has changed and is not empty
        if (value.trim() !== '' && value !== currentValues[chipIndex]) {
          currentValues[chipIndex] = value.trim();
          handleChangeKeyValue(machineIndex, keyIndex, currentValues);
        }
      } catch (error) {
        console.error('Error updating chip value on blur:', error);
      }

      // Exit edit mode
      setEditingChip(null);
    }
  };

  // For UI display: parse possible Options from the definition's description
  const getOptionsFromKeyDefinition = (kvKey: string): string[] => {
    if (!selectedAdapter) return [];
    const def = selectedAdapter.key_definitions.find((d) => d.key === kvKey);
    if (!def) return [];
    return parseOptionsFromDescription(def.description || '');
  };

  // --------------------------------------------------------------------------
  // Final Save & Delete
  // --------------------------------------------------------------------------
  const handleDelete = async () => {
    setDeleteInProgress(true);
    setError('');

    try {
      if (!integrationId) return;

      await axios.delete(`api/databridge_integrations/${integrationId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      setShowDeleteConfirm(false);
      setDeleteInProgress(false);
      onClose(true); // Reload needed
    } catch (err: any) {
      console.error('[IntegrationForm] Error deleting integration:', err);
      setError(`Error deleting integration: ${err?.response?.data?.detail || err.message || 'Unknown error'}`);
      setDeleteInProgress(false);
    }
  };

  const handleSave = () => {
    if (!selectedAdapter) {
      setError('Please select an Adapter');
      return;
    }
    if (!selectedPartner) {
      setError('Please select an External API Partner');
      return;
    }
    setLoading(true);
    setError('');

    // Build final payload
    // We'll skip any "unrecognizedInAdapter" property – purely local for UI
    const payload = {
      name,
      enabled,
      aws_secret_id: awsSecretId || null,
      refresh_token: refreshToken || null,
      authorization_code: authorizationCode || null,
      adapter_id: selectedAdapter.databridge_adapter_id,
      external_api_partner_id: selectedPartner.external_api_partner_id,
      machines: machinesData.map((m) => ({
        machine_id: m.machine_id,
        key_values: m.key_values.map((kv) => ({
          databridge_adapter_key_definition_id: kv.databridge_adapter_key_definition_id,
          value: kv.value,
        })),
      })),
    };

    if (isCreateMode) {
      axios
        .post('api/databridge_integrations/', payload, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then(() => {
          setFormChanged(false);
          setHasInitializedForm(false);
          onClose(true);
        })
        .catch((err) => {
          console.error('Failed to create integration:', err);
          setError(err.response?.data?.message || 'Failed to create integration.');
        })
        .finally(() => setLoading(false));
    } else if (integrationId) {
      axios
        .put(`api/databridge_integrations/${integrationId}/`, payload, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        })
        .then(() => {
          setFormChanged(false);
          setHasInitializedForm(false);
          onClose(true);
        })
        .catch((err) => {
          console.error('Failed to update integration:', err);
          setError(err.response?.data?.message || 'Failed to update integration.');
        })
        .finally(() => setLoading(false));
    }
  };

  // If the partner has available machines, we store them here for "Add Machine"
  const partnerMachines = selectedPartner?.machines || [];
  const isStillLoading = open && !fetchesDone && !isCreateMode;

  if (!open) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          console.log('[IntegrationForm] onClose triggered by reason:', reason);
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        {/* Delete button removed from here */}
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2} pt={2}>
          <Box display="flex" alignItems="center">
            <DialogTitle sx={{ p: 0 }}>{isCreateMode ? 'Create Integration' : 'Edit Integration'}</DialogTitle>
            {!isCreateMode && (
              <IconButton
                size="medium"
                color="error"
                onClick={() => setShowDeleteConfirm(true)}
                sx={{
                  ml: 2,
                  opacity: 0.8,
                  '&:hover': { opacity: 1 },
                  transform: 'scale(1)',
                  bgcolor: 'rgba(255,230,230,0.3)',
                }}
                title="Delete integration"
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
          <FormControlLabel
            control={<Switch checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />}
            label="Enabled"
          />
        </Box>

        <DialogContent dividers>
          {(isStillLoading || loading) && (
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Typography color="error" mb={2}>
              {error}
            </Typography>
          )}

          {!isStillLoading && !loading && (
            <>
              {/* Integration Name */}
              <TextField
                label="Integration Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
              />

              {/* Select Adapter */}
              <AutocompleteWrapper sx={{ width: '100%' }}>
                <Autocomplete
                  options={adapters}
                  getOptionLabel={(option) => option.name}
                  value={selectedAdapter}
                  // We use onChange logic that triggers confirm dialog
                  onChange={(event, newValue) => onAdapterChange(newValue || null)}
                  renderInput={(params) => <TextField {...params} label="Select Adapter" margin="normal" fullWidth />}
                />
              </AutocompleteWrapper>

              {/* Select External API Partner */}
              <AutocompleteWrapper sx={{ width: '100%' }}>
                <Autocomplete
                  options={partners}
                  getOptionLabel={(option) => option.name}
                  value={selectedPartner}
                  // We use onChange logic that triggers confirm dialog
                  onChange={(event, newValue) => onPartnerChange(newValue || null)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select External API Partner" margin="normal" fullWidth />
                  )}
                />
              </AutocompleteWrapper>

              {/* AWS Secret ID */}
              <TextField
                label="AWS Secret ID"
                value={awsSecretId}
                onChange={(e) => setAwsSecretId(e.target.value)}
                fullWidth
                margin="normal"
              />

              {/* Refresh Token */}
              <TextField
                label="Refresh Token"
                value={refreshToken}
                onChange={(e) => setRefreshToken(e.target.value)}
                fullWidth
                margin="normal"
              />

              {/* Authorization Code */}
              <TextField
                label="Authorization Code"
                value={authorizationCode}
                onChange={(e) => setAuthorizationCode(e.target.value)}
                fullWidth
                margin="normal"
              />

              <Divider sx={{ my: 2 }} />

              {/* Machine Integrations */}
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Machine Integrations
                </Typography>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Box display="flex" alignItems="center" mb={1}>
                  <AutocompleteWrapper sx={{ flex: 1, mr: 2 }}>
                    <Autocomplete
                      options={partnerMachines}
                      getOptionLabel={(option) => option.name}
                      value={selectedMachineOption}
                      onChange={(e, newVal) => setSelectedMachineOption(newVal)}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Machine" fullWidth margin="normal" />
                      )}
                    />
                  </AutocompleteWrapper>
                  <Button
                    variant="contained"
                    onClick={handleAddMachineClick}
                    disabled={!selectedMachineOption || !selectedAdapter}
                  >
                    Add Machine
                  </Button>
                </Box>
              </Box>

              {machinesData.length === 0 && <Typography>No machines added yet.</Typography>}

              {machinesData.map((machine, mIndex) => {
                // If partner doesn’t have that machine, show [No Access]
                const partnerHasAccess = selectedPartner?.machines?.some((pm) => pm.machine_id === machine.machine_id);

                return (
                  <Accordion key={machine.machine_id} defaultExpanded={false} sx={{ mb: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: '1.4rem' }}>
                          {machine.machine_name} (SN: {machine.machine_serial_number})
                          {!partnerHasAccess && (
                            <Typography component="span" color="error" ml={2}>
                              [No Access]
                            </Typography>
                          )}
                        </Typography>
                        {/* Compact display of key values when collapsed */}
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{
                            fontSize: '0.75rem',
                            mt: 0.5,
                            lineHeight: 1.2,
                            maxHeight: '2.4em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {machine.key_values
                            .map((kv) => {
                              let displayValue = kv.value;
                              // Format arrays more cleanly
                              if (kv.data_type === 'array') {
                                try {
                                  const arr = JSON.parse(kv.value);
                                  if (Array.isArray(arr)) {
                                    displayValue = arr.join(', ');
                                  }
                                } catch {
                                  // Use original value if parsing fails
                                }
                              }
                              // Format boolean values more clearly
                              else if (kv.data_type === 'boolean') {
                                displayValue = kv.value === 'TRUE' ? '✓' : '✗';
                              }

                              // Truncate very long values
                              if (displayValue.length > 20) {
                                displayValue = `${displayValue.substring(0, 18)}...`;
                              }

                              return `${kv.name}: ${displayValue}`;
                            })
                            .join(' • ')}
                        </Typography>
                      </Box>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleConfirmRemoveMachine(machine.machine_id);
                        }}
                        sx={{
                          '&:hover': { bgcolor: 'rgba(255,230,230,0.2)' },
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 1 }}>
                      <List dense sx={{ mb: 0 }}>
                        {(machine.key_values ?? [])
                          // Sort key values by ID in ascending order
                          .sort((a, b) => {
                            // Sort by databridge_adapter_key_definition_id if available
                            return (
                              (a.databridge_adapter_key_definition_id || 0) -
                              (b.databridge_adapter_key_definition_id || 0)
                            );
                          })
                          .map((kv, kvIndex) => {
                            const dt = kv.data_type || 'string';
                            const possibleOptions = getOptionsFromKeyDefinition(kv.key);

                            // If the server says this key-value is unrecognized, you can highlight or show a warning
                            const unrecognized = (kv as any).unrecognizedInAdapter;

                            return (
                              <ListItem
                                key={kv.key}
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  mb: 1,
                                  p: 0,
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <Typography variant="subtitle2" fontSize="1.4rem">
                                    {kv.name} ({kv.key})
                                  </Typography>
                                  {unrecognized && (
                                    <Typography variant="body2" color="error" ml={1}>
                                      [No Matching Definition in Adapter!]
                                    </Typography>
                                  )}
                                </Box>

                                {/* Render input based on data_type */}
                                {dt === 'array' ? (
                                  <AutocompleteWrapper>
                                    <Autocomplete
                                      multiple
                                      freeSolo
                                      value={parseArrayValue(kv.value)}
                                      onChange={(event, newArrVal) => handleChangeKeyValue(mIndex, kvIndex, newArrVal)}
                                      options={[]}
                                      renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                          // Check if this chip is currently being edited
                                          const isEditing =
                                            editingChip &&
                                            editingChip.machineIndex === mIndex &&
                                            editingChip.keyIndex === kvIndex &&
                                            editingChip.chipIndex === index;

                                          return isEditing ? (
                                            <Box
                                              key={`edit-${index}`}
                                              sx={{ display: 'inline-flex', alignItems: 'center', m: 0.5 }}
                                            >
                                              <TextField
                                                size="small"
                                                autoFocus
                                                value={editingChip.value}
                                                onChange={(e) =>
                                                  setEditingChip({
                                                    ...editingChip,
                                                    value: e.target.value,
                                                  })
                                                }
                                                onKeyDown={handleSaveChipEdit}
                                                onBlur={handleChipEditBlur}
                                                onClick={(e) => e.stopPropagation()}
                                                sx={{
                                                  width: 'auto',
                                                  minWidth: '120px',
                                                  m: 0,
                                                  '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main',
                                                    borderWidth: '1px',
                                                  },
                                                }}
                                                variant="outlined"
                                                inputProps={{
                                                  style: { padding: '4px 10px', height: '24px' },
                                                  spellCheck: false,
                                                }}
                                              />
                                            </Box>
                                          ) : (
                                            <Chip
                                              variant="outlined"
                                              label={option}
                                              {...getTagProps({ index })}
                                              key={option + String(index)}
                                              onDoubleClick={() => handleStartChipEdit(mIndex, kvIndex, index, option)}
                                              sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                                            />
                                          );
                                        })
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size="small"
                                          label="Enter values"
                                          placeholder="Add new item"
                                          fullWidth
                                          sx={{ mt: 1 }}
                                          // ---------------------------------------------
                                          // ADDED: Prevent backspace from removing chips
                                          // when the input is empty:
                                          // ---------------------------------------------
                                          onKeyDown={(e) => {
                                            if (e.key === 'Backspace' && !params.inputProps.value) {
                                              e.stopPropagation();
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </AutocompleteWrapper>
                                ) : dt === 'boolean' ? (
                                  <Select
                                    size="small"
                                    fullWidth
                                    value={kv.value === 'TRUE' ? 'TRUE' : 'FALSE'}
                                    onChange={(e) => handleChangeKeyValue(mIndex, kvIndex, e.target.value)}
                                    sx={{ mt: 1 }}
                                  >
                                    <MenuItem value="TRUE">TRUE</MenuItem>
                                    <MenuItem value="FALSE">FALSE</MenuItem>
                                  </Select>
                                ) : dt === 'number' ? (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    value={kv.value}
                                    onChange={(e) => handleChangeKeyValue(mIndex, kvIndex, e.target.value)}
                                    sx={{ mt: 1 }}
                                  />
                                ) : possibleOptions.length > 0 ? (
                                  <Select
                                    size="small"
                                    fullWidth
                                    value={kv.value}
                                    onChange={(e) => handleChangeKeyValue(mIndex, kvIndex, e.target.value)}
                                    sx={{ mt: 1 }}
                                  >
                                    {possibleOptions.map((opt) => (
                                      <MenuItem key={opt} value={opt}>
                                        {opt}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                ) : (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    value={kv.value}
                                    onChange={(e) => handleChangeKeyValue(mIndex, kvIndex, e.target.value)}
                                    sx={{ mt: 1 }}
                                  />
                                )}
                              </ListItem>
                            );
                          })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              console.log('[IntegrationForm] Cancel button clicked');
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} disabled={loading || isStillLoading}>
            {isCreateMode ? 'Create Integration' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Unsaved Changes Dialog */}
      <Dialog
        open={showUnsavedDialog}
        onClose={handleCancelClose}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <DialogTitle id="unsaved-changes-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-description">
            You have unsaved changes that will be lost if you close this form. Are you sure you want to discard your
            changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose}>Cancel</Button>
          <Button onClick={handleConfirmClose} color="error" autoFocus>
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Adapter Confirm Dialog */}
      <Dialog
        open={showAdapterConfirm}
        onClose={cancelAdapterChange}
        aria-labelledby="adapter-confirm-title"
        aria-describedby="adapter-confirm-description"
      >
        <DialogTitle id="adapter-confirm-title">Confirm Adapter Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="adapter-confirm-description">
            Changing the adapter can drastically alter the machine key values. Please type the new adapter name to
            confirm:
          </DialogContentText>
          <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{pendingAdapter?.name}</Typography>
          <TextField
            fullWidth
            margin="normal"
            value={adapterConfirmText}
            onChange={(e) => setAdapterConfirmText(e.target.value)}
            placeholder="Type adapter name exactly"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelAdapterChange}>Cancel</Button>
          <Button
            onClick={confirmAdapterChange}
            disabled={!pendingAdapter || adapterConfirmText !== pendingAdapter.name}
            variant="contained"
            color="warning"
          >
            Confirm Adapter
          </Button>
        </DialogActions>
      </Dialog>

      {/* Partner Confirm Dialog */}
      <Dialog
        open={showPartnerConfirm}
        onClose={cancelPartnerChange}
        aria-labelledby="partner-confirm-title"
        aria-describedby="partner-confirm-description"
      >
        <DialogTitle id="partner-confirm-title">Confirm External API Partner Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="partner-confirm-description">
            Changing the External API Partner might affect machine access. Please type the new partner name to confirm:
          </DialogContentText>
          <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{pendingPartner?.name}</Typography>
          <TextField
            fullWidth
            margin="normal"
            value={partnerConfirmText}
            onChange={(e) => setPartnerConfirmText(e.target.value)}
            placeholder="Type partner name exactly"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelPartnerChange}>Cancel</Button>
          <Button
            onClick={confirmPartnerChange}
            disabled={!pendingPartner || partnerConfirmText !== pendingPartner.name}
            variant="contained"
            color="warning"
          >
            Confirm Partner
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        aria-labelledby="delete-confirm-title"
        aria-describedby="delete-confirm-description"
      >
        <DialogTitle id="delete-confirm-title" color="error">
          Confirm Soft Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirm-description">
            This will perform a <strong>soft delete</strong> of this integration. The record will be marked as deleted
            in the database but will still exist.
            <br />
            <br />
            <strong>Important:</strong> No machines tied to this integration will execute after deletion.
            <br />
            <br />
            Are you absolutely sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)} autoFocus>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained" disabled={deleteInProgress}>
            {deleteInProgress ? 'Deleting...' : 'Confirm Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Machine Delete Confirmation Dialog */}
      <Dialog
        open={showMachineDeleteConfirm}
        onClose={() => {
          setShowMachineDeleteConfirm(false);
          setPendingMachineDelete(null);
        }}
        aria-labelledby="machine-delete-confirm-title"
        aria-describedby="machine-delete-confirm-description"
      >
        <DialogTitle id="machine-delete-confirm-title">Remove Machine</DialogTitle>
        <DialogContent>
          <DialogContentText id="machine-delete-confirm-description">
            Are you sure you want to remove this machine from the integration? This will delete all machine-specific
            configuration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowMachineDeleteConfirm(false);
              setPendingMachineDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleRemoveMachine} color="error" variant="contained">
            Remove Machine
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
