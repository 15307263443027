import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Card, CardContent, Typography } from '@mui/material';
import FormInput from '../../shared/form/FormInput';
import UserCompanyInformation from './UserCompanyInformation';
import UserCompanyMachines from './UserCompanyMachines';
import UserCompanyUsers from './UserCompanyUsers';
import { AGSYNC_CLIENT_ID, AGSYNC_CLIENT_CODE, AGSYNC_REDIRECT_URI } from '../../utils/env';

interface UserCompanyDetailsProps {
  company: any;
}

const scope = encodeURIComponent('openid profile email agsync roles offline_access');
const state = encodeURIComponent('21859377180d2a730811cee3b45a7893');

const authUrl = `https://auth.agsync.com/core/connect/authorize?response_type=code&state=${state}&client_id=${AGSYNC_CLIENT_ID}&client_secret=${AGSYNC_CLIENT_CODE}&scope=${scope}&redirect_uri=${AGSYNC_REDIRECT_URI}`;

const UserCompanyDetails: React.FC<UserCompanyDetailsProps> = (props) => {
  const { company } = props;

  const handleLogin = () => {
    window.location.href = authUrl;
  };

  return (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Company Information
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <FormInput value={company?.name} onChange={(value) => null} label="Name" disabled />
                </Grid>
              </Grid>
              <br />
              {company?.associated_integrations?.includes('agsync') && (
                <Button variant="contained" color="primary" onClick={handleLogin}>
                  Authenticate with Agsync
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <UserCompanyUsers company={company} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserCompanyMachines company={company} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCompanyDetails;
