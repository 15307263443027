/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Machine } from '../../machinesAdminManagement/model';
import DataTable from '../../dataTable/DataTable/DataTable';
import { SoftwareVersionContext } from '../contexts/SoftwareVersionsContext';
import softwareVersionColumns from '../constants/softwareVersionColumns';
import SoftwareVersionsFilterBar from '../components/SoftwareVersionFilterBar';
import defaultFilterOptions from '../constants/defaultFilterOptions';

const SoftwareVersionsList: React.FunctionComponent = () => {
  const history = useHistory();

  const { fetchSoftwareVersions, softwareVersionsLoading, softwareVersions } = React.useContext(SoftwareVersionContext);

  const [tableFilters, setTableFilters] = React.useState(defaultFilterOptions);

  React.useEffect(() => {
    fetchSoftwareVersions();
  }, []);

  const filterSoftwareVersions = () => {
    if (
      tableFilters.machine_type !== 'All' ||
      tableFilters.software_type !== 'All' ||
      tableFilters.software_version_name
    ) {
      let filteredSoftwareVersions = softwareVersions;

      if (tableFilters.machine_type && tableFilters.machine_type !== 'All') {
        filteredSoftwareVersions = filteredSoftwareVersions.filter((softwareVersion) =>
          softwareVersion.machine_type?.toLowerCase().includes(tableFilters.machine_type.toLowerCase()),
        );
      }

      if (tableFilters.software_type && tableFilters.software_type !== 'All') {
        filteredSoftwareVersions = filteredSoftwareVersions.filter((softwareVersion) =>
          softwareVersion.machine_software_type?.toLowerCase().includes(tableFilters.software_type.toLowerCase()),
        );
      }

      if (tableFilters.software_version_name) {
        filteredSoftwareVersions = filteredSoftwareVersions.filter((softwareVersion) =>
          softwareVersion.machine_software_version_name
            ?.toLowerCase()
            .includes(tableFilters.software_version_name.toLowerCase()),
        );
      }

      return filteredSoftwareVersions;
    }

    return softwareVersions;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Software Versions
          </Typography>
          <br />
        </Grid>
      </Grid>
      <SoftwareVersionsFilterBar
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        fetchFunction={fetchSoftwareVersions}
      />
      <br />
      <Card>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                SoftwareVersions
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  history.push(`/admin/software_versions/create`);
                }}
              >
                Add Software Version
              </Button>
            </Grid>
          </Grid>
          <br />
          <DataTable
            noHeader
            columns={softwareVersionColumns}
            // data={softwareVersions}
            data={filterSoftwareVersions()}
            onRowClicked={(row: Machine) => {
              console.log(row);
            }}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={softwareVersionsLoading}
          />
        </CardContent>
        <br />
      </Card>
      <br />
    </>
  );
};

export default SoftwareVersionsList;
