import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, LinearProgress } from '@mui/material';
import axios from '../../../utils/axios.utils';
import DeviceContentCard from './DeviceContentCard';
import { DeviceResult } from '../../model';

// Helper to display time since last check-in
function timeSince(dateString: string | null): string {
  if (!dateString) return 'Unknown';
  const now = new Date();
  const past = new Date(dateString);
  const diffMs = now.getTime() - past.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    const remainderHours = diffHours % 24;
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ${remainderHours}h ago`;
  }
  if (diffHours > 0) {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  }
  if (diffMinutes > 0) {
    return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
  }
  return 'Just now';
}

// Helper to show N/A if value is null or empty
function displayOrNothing(val?: string | number | null): string {
  return val !== null && val !== undefined && val !== '' ? String(val) : '';
}

const DeviceDetailsPrimary: React.FC = () => {
  const history = useHistory();
  const { deviceSerial } = useParams<{ deviceSerial: string }>();

  const [device, setDevice] = React.useState<DeviceResult | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  /**
   * Fetch the device from API.
   *
   * @param skipLoading - If true, we do not show the global <LinearProgress>.
   *                     Useful for silent refresh after a form save.
   */
  const fetchDevice = React.useCallback(
    async (skipLoading: boolean = false) => {
      try {
        if (!skipLoading) {
          setLoading(true);
        }
        const response = await axios.get<any, any>(
          `/api/user/edgelink/devices/dashboard?device_serial_number=${deviceSerial}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            },
          },
        );
        if (response.data.success && response.data.results?.length > 0) {
          setDevice(response.data.results[0]);
          setError(null);
        } else {
          setError('No device found for this serial.');
        }
      } catch (err) {
        console.error(err);
        setError('Failed to load device data.');
      } finally {
        if (!skipLoading) {
          setLoading(false);
        }
      }
    },
    [deviceSerial],
  );

  // On mount or when deviceSerial changes, do an "initial" fetch with loading
  React.useEffect(() => {
    fetchDevice(false);
  }, [fetchDevice]);

  // Callback from child to silently refresh the device (no loading bar)
  const handleDeviceUpdated = async () => {
    // Pass true to skip showing <LinearProgress>
    await fetchDevice(true);
  };

  const handleCloseCard = () => {
    history.push('/edgelink/devices');
  };

  return (
    <Box sx={{ width: '100%', p: 1 }}>
      {/* Only show loading bar during the initial fetch */}
      {loading && <LinearProgress />}

      {error && (
        <Box sx={{ p: 1, mb: 2 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      {/* Content card only appears if we have a device and no error */}
      {!loading && !error && device && (
        <Box
          sx={{
            maxWidth: 700,
            mx: 'auto',
            p: 1,
          }}
        >
          <DeviceContentCard selectedDevice={device} showMap={true} onDeviceUpdated={handleDeviceUpdated} />
        </Box>
      )}
    </Box>
  );
};

export default DeviceDetailsPrimary;
