/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Divider,
  Chip,
  Card,
  CardContent,
  Typography,
  Link,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Popover,
  Button,
  Stack,
} from '@mui/material';
import { LoadScript, GoogleMap, Marker, Polyline, InfoWindow } from '@react-google-maps/api';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import MemoryIcon from '@mui/icons-material/Memory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ScienceIcon from '@mui/icons-material/Science';
import DescriptionIcon from '@mui/icons-material/Description';
import OpacityIcon from '@mui/icons-material/Opacity';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InfoIcon from '@mui/icons-material/Info';

import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';

import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import SolarPowerIcon from '@mui/icons-material/SolarPower';

import Breadcrumbs from '../../../navigation/components/Breadcrumbs';
import axios from '../../../utils/axios.utils';

/* ------------------------------------------------------------------
   Chart.js Setup
------------------------------------------------------------------ */
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/* ------------------------------------------------------------------
   Interfaces
------------------------------------------------------------------ */
interface ISensorUom {
  uom_id: number;
  uom: string;
  uom_abbreviation: string;
  value: number | string;
}

interface ISensorEventData {
  sensor?: string;
  uoms: ISensorUom[];
  order?: number;
}

interface IEvent {
  event_id: number;
  time_stamp: string;
  transmit_reason?: string;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  country?: string | null;
  gps_lat: number | string;
  gps_long: number | string;
  sensor_event_data?: ISensorEventData[];
}

/** New interface for GPS events specifically. */
interface IGpsEvent {
  event_id: number;
  time_stamp: string;
  gps_lat: number;
  gps_long: number;
}

interface ICompany {
  primary_edgelink_company_id?: number;
  primary_edgelink_company_name?: string;
  secondary_edgelink_company_id?: number;
  secondary_edgelink_company_name?: string;
  tertiary_edgelink_company_id?: number;
  tertiary_edgelink_company_name?: string;
}

interface IProductProfile {
  edgelink_product_profile_id: number;
  product_name: string;
  lot_number: string;
  batch_number: string;
  crop: string;
  safety_data_sheet_attachment: string | null;
  chemical_label_attachment: string | null;
  loaded_at: string | null;
  emptied_at: string | null;
  status?: boolean;
  seed_units?: string | null;
  seed_treatment_package?: string | null;
  additional_notes?: string | null;
  seed_size?: string | null;
}

interface IDeviceData {
  edgelink_device_id: number;
  device_serial_number: string;
  microcontroller_serial: string;
  connection_state: string; // e.g. "strong", "moderate", "weak" or "offline"
  last_seen_at: string; // e.g. "2025-02-24T20:35:26.434Z"
  sim_imsi: string | null;
  device_type: {
    device_type_id: number; // 1 for Liquid Tank, 2 for Seed Box, etc.
    device_type_name: string;
  };
  gps_lat: string;
  gps_long: string;
  address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  battery_life_level: string; // e.g. "80%", "Unknown", etc.
  battery_volts: number;
  orientation?: string;
  fill_status?: string; // e.g. "90%", "Full", "Empty", etc.
  total_capacity_gallons?: number;
  remaining_liquid_gallons?: number;
  company_relationship: string | null;
  companies: ICompany[];
  product_profile?: IProductProfile | null;

  // The original "events" array with sensor data, times, etc.
  events: IEvent[];

  // The new array for GPS events:
  gps_events?: IGpsEvent[];
}

/* ------------------------------------------------------------------
   Additional fields for the DeviceCard snippet
------------------------------------------------------------------ */
type DeviceResult = IDeviceData & {
  cellular_strength?: string | null;
  solar_volts?: string | null;
};

/* ------------------------------------------------------------------
   Helpers
------------------------------------------------------------------ */

/** Format the last-seen date/time in a user-friendly local time. */
function formatLastSeen(dateStr: string | null): string {
  if (!dateStr) return 'Never';
  const d = new Date(dateStr);
  if (isNaN(d.getTime())) return 'Invalid date';
  return d.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}

/** Convert battery string (e.g. "80%") to a number or -1 if unknown */
function parseBatteryPercent(str?: string | null) {
  if (!str) return -1;
  const numeric = parseInt(str.replace('%', ''), 10);
  return isNaN(numeric) ? -1 : numeric;
}

/** Return a color-coded cellular icon. */
function getCellularIcon(strength?: string | null): JSX.Element {
  if (!strength) {
    return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 24, color: '#bdbdbd' }} />;
  }
  const s = strength.toLowerCase();
  if (s === 'strong') {
    return <SignalCellular4BarIcon sx={{ fontSize: 24, color: '#4caf50' }} />;
  }
  if (s === 'moderate') {
    return <SignalCellular2BarIcon sx={{ fontSize: 24, color: '#ff9800' }} />;
  }
  if (s === 'weak') {
    return <SignalCellular0BarIcon sx={{ fontSize: 24, color: '#f44336' }} />;
  }
  return <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 24, color: '#9e9e9e' }} />;
}

/** Return a color-coded battery icon. */
function getBatteryIcon(battStr?: string | null): JSX.Element {
  const pct = parseBatteryPercent(battStr);
  if (pct < 0) {
    return <BatteryUnknownIcon sx={{ fontSize: 24, color: '#9e9e9e' }} />;
  }
  if (pct >= 80) {
    return <BatteryFullIcon sx={{ fontSize: 24, color: '#4caf50' }} />;
  }
  if (pct >= 60) {
    return <Battery60Icon sx={{ fontSize: 24, color: '#8bc34a' }} />;
  }
  if (pct >= 40) {
    return <Battery50Icon sx={{ fontSize: 24, color: '#ffc107' }} />;
  }
  if (pct > 0) {
    return <Battery20Icon sx={{ fontSize: 24, color: '#f44336' }} />;
  }
  return <Battery20Icon sx={{ fontSize: 24, color: '#f44336' }} />;
}

/** Return a solar icon with color depending on solar volts. */
function getSolarIcon(volts?: string | null): JSX.Element {
  const val = parseFloat(volts || '0');
  const iconColor = val > 0 ? '#4caf50' : '#9e9e9e';
  return <SolarPowerIcon sx={{ fontSize: 24, color: iconColor }} />;
}

/** Renders a “seed box” shape for seed devices. */
function renderSeedBox(fillStatus: string) {
  let baseColor = '#4d4d4d';
  let label = 'FULL';
  let gradient = `linear-gradient(to top, ${baseColor}, #616161)`;
  const lower = fillStatus.toLowerCase();

  if (lower === 'empty') {
    baseColor = '#4b4b4b';
    label = 'EMPTY';
    gradient = `linear-gradient(to top, ${baseColor}, #a0a0a0)`;
  } else if (lower === 'packed') {
    baseColor = '#4b4b4b';
    label = 'PACKED';
    gradient = `linear-gradient(to top, ${baseColor}, #a0a0a0)`;
  } else {
    if (/\d+%/.test(lower)) {
      label = fillStatus.toUpperCase();
    }
  }

  return (
    <Box
      sx={{
        width: 180, // narrower
        height: 220,
        position: 'relative',
        backgroundColor: baseColor,
        borderRadius: 1,
        boxShadow: '0 4px 10px rgba(0,0,0,0.4)',
        overflow: 'hidden',
        background: gradient,
      }}
    >
      {lower === 'packed' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '50%',
            backgroundColor: '#fff',
          }}
        />
      )}
      {[...Array(5)].map((_, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            top: `${(i + 1) * 12}%`,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#2c2c2c',
            opacity: 0.7,
          }}
        />
      ))}
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 0,
          width: '100%',
          textAlign: 'center',
          color: '#fff',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '0.9rem',
        }}
      >
        {label}
      </Box>
    </Box>
  );
}

/** Renders a “liquid tank” shape for liquid devices. */
function renderLiquidTank(fillPercent: number) {
  const pct = Math.max(0, Math.min(fillPercent, 100));
  return (
    <Box
      sx={{
        width: 180, // narrower
        height: 220,
        position: 'relative',
        background: '#e0e0e0',
        borderRadius: '50px / 25px',
        boxShadow: 'inset 0 4px 6px rgba(0,0,0,0.2), 0 4px 10px rgba(0,0,0,0.2)',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: `${pct}%`,
          background: 'linear-gradient(to top, #1976d2, #64b5f6)',
          transition: 'height 0.5s ease',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          width: '100%',
          textAlign: 'center',
          transform: 'translateY(-50%)',
          fontWeight: 'bold',
          color: pct >= 50 ? '#fff' : '#000',
        }}
      >
        {pct.toFixed(0)}%
      </Box>
    </Box>
  );
}

function displayOrNothing(val?: string | number | null): string {
  return val !== null && val !== undefined && val !== '' ? String(val) : '';
}

// Status Badge Component
const StatusBadge = ({ status }: { status: string }) => {
  const getStatusColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'full':
        return '#4caf50';
      case 'empty':
        return '#f44336';
      case 'packed':
        return '#ff9800';
      default:
        // If it's numeric fill (e.g. "90%"), pick a color by threshold
        const numeric = parseInt(status, 10);
        if (!isNaN(numeric)) {
          if (numeric >= 80) return '#4caf50';
          if (numeric >= 40) return '#ffc107';
          return '#f44336';
        }
        return '#757575';
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        bgcolor: getStatusColor(status),
        color: '#fff',
        px: 1,
        py: 0.5,
        borderRadius: 2,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
    >
      {status || 'N/A'}
    </Box>
  );
};

/* ------------------------------------------------------------------
   DeviceCard Component
   (Now returns only a Card; 
   we remove the outer <Grid item> so the parent can control width)
------------------------------------------------------------------ */
const DeviceCard = ({ device }: { device: DeviceResult }) => {
  const isSeedBox = device.device_type?.device_type_id === 2;

  // For liquid devices, we look at total_capacity_gallons & remaining_liquid_gallons
  const fillPercent =
    !isSeedBox && device.total_capacity_gallons
      ? Math.min(Math.max(0, ((device.remaining_liquid_gallons || 0) / device.total_capacity_gallons) * 100), 100)
      : 0;

  const shapeElement = isSeedBox ? renderSeedBox(device.fill_status || 'Empty') : renderLiquidTank(fillPercent);

  const cityState =
    device.city && device.state ? `${device.city}, ${device.state}` : device.city || device.state || '—';

  return (
    <Card
      sx={{
        position: 'relative',
        p: 3,
        borderRadius: 4,
        bgcolor: '#fff',
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        '&:hover': {
          boxShadow: '0px 8px 24px rgba(0,0,0,0.15)',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 4,
          bgcolor: isSeedBox ? '#4caf50' : '#1976d2',
        },
      }}
    >
      <StatusBadge status={device.fill_status || ''} />

      <Stack spacing={2} alignItems="center">
        {/* Header */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a1a1a' }}>
            {device.device_serial_number}
          </Typography>
          <Typography variant="body2" sx={{ color: '#666', textAlign: 'center' }}>
            {cityState}
          </Typography>
        </Box>

        {/* Tank/Seed Shape */}
        {shapeElement}

        {/* Bottom Info: Product / Icons / Last Seen */}
        <Box textAlign="center">
          <Typography
            variant="body2"
            sx={{
              fontStyle: 'italic',
              color: '#444',
              mb: 1,
              maxWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {device.product_profile?.product_name || 'No Product'}
          </Typography>

          {/* Icons */}
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mb: 1 }}>
            {getCellularIcon(device.cellular_strength)}
            {getBatteryIcon(device.battery_life_level)}
            {getSolarIcon(device.solar_volts)}
          </Stack>

          {/* Last Seen */}
          <Typography variant="caption" sx={{ color: '#888' }}>
            Last Seen: {formatLastSeen(device.last_seen_at)}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

/* ------------------------------------------------------------------
   Info Popup for Product
------------------------------------------------------------------ */
interface InfoPopupProps {
  productProfile: IProductProfile;
  isSeed: boolean;
}
const InfoPopup: React.FC<InfoPopupProps> = ({ productProfile, isSeed }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <IconButton onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} size="large" sx={{ ml: 1 }}>
        <InfoIcon fontSize="large" />
      </IconButton>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1.5, maxWidth: 250 }}>
          <Typography variant="body2" gutterBottom>
            <strong>Product Name:</strong> {productProfile.product_name || 'N/A'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Batch Number:</strong> {productProfile.batch_number || 'N/A'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Crop:</strong> {productProfile.crop || 'N/A'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Loaded At:</strong> {productProfile.loaded_at || 'N/A'}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Emptied At:</strong> {productProfile.emptied_at || 'N/A'}
          </Typography>
          {isSeed && (
            <>
              <Typography variant="body2" gutterBottom>
                <strong>Seed Units:</strong> {productProfile.seed_units || 'N/A'}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Seed Size:</strong> {productProfile.seed_size || 'N/A'}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Seed Treatment Package:</strong> {productProfile.seed_treatment_package || 'N/A'}
              </Typography>
            </>
          )}
          <Typography variant="body2" gutterBottom>
            <strong>Additional Notes:</strong> {productProfile.additional_notes || 'N/A'}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

/* ------------------------------------------------------------------
   Main Device Dashboard Component
------------------------------------------------------------------ */
const DeviceDashboard: React.FC = () => {
  const { edgelinkId } = useParams<{ edgelinkId: string }>();
  const history = useHistory();

  const [deviceData, setDeviceData] = useState<IDeviceData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<IGpsEvent | null>(null);

  useEffect(() => {
    if (!edgelinkId) {
      setError('Device ID is missing or invalid');
      return;
    }
    const fetchDeviceData = async () => {
      setLoading(true);
      try {
        const response = await axios.get<any, any>(`api/user/edgelink/devices/${edgelinkId}/dashboard`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        });
        if (response.data && response.data.success) {
          setDeviceData(response.data.results);
          setError('');
        } else {
          setDeviceData(null);
          setError('Failed to fetch device data');
        }
      } catch (e: any) {
        console.error(e);
        setError('Error fetching device data');
      }
      setLoading(false);
    };
    fetchDeviceData();
  }, [edgelinkId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh">
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box textAlign="center" mt={1}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  if (!deviceData) {
    return null;
  }

  // --- Format Date/Location ---
  const lastSeenLocal = deviceData.last_seen_at ? new Date(deviceData.last_seen_at).toLocaleString() : 'N/A';
  const locationString = [deviceData.city, deviceData.state, deviceData.country].filter(Boolean).join(', ');
  const googleMapsLink = `https://www.google.com/maps?q=${deviceData.gps_lat},${deviceData.gps_long}`;

  // We'll create an "enhanced" object to pass into DeviceCard
  const finalDeviceData: DeviceResult = {
    ...deviceData,
    cellular_strength: deviceData.connection_state,
    solar_volts: deviceData.battery_volts?.toString(),
  };

  /* ---------------------------
     Chart Data (Using sorted `events`)
  ----------------------------*/
  // Sort events from oldest to newest
  const sortedEvents = [...deviceData.events].sort(
    (a, b) => new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime(),
  );

  // Use a shorter date/time format for the labels
  const eventLabels = sortedEvents.map((evt) =>
    new Date(evt.time_stamp).toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }),
  );

  const lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        ticks: { autoSkip: true, maxRotation: 35, minRotation: 35 },
      },
      y: { beginAtZero: false },
    },
    plugins: {
      legend: { display: true, position: 'top' },
    },
  };

  // For Seed devices (id 2) we now trend empty_distance (mm) instead of amount_remaining (gal)
  const isSeedDevice = deviceData.device_type.device_type_id === 2;
  const amountRemainingData = {
    labels: eventLabels,
    datasets: [
      {
        label: isSeedDevice ? 'Empty Distance (mm)' : 'Amount Remaining (gal)',
        data: sortedEvents.map((evt) => {
          const sensorData = evt.sensor_event_data || [];
          const sensorReading = sensorData.find(
            (sed) => sed.sensor === (isSeedDevice ? 'empty_distance' : 'amount_remaining'),
          );
          if (!sensorReading || sensorReading.uoms.length === 0) return null;
          const numeric = sensorReading.uoms[0].value;
          return typeof numeric === 'number' ? numeric : null;
        }),
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.3,
      },
    ],
  };

  // Example: Temperature
  const temperatureData = {
    labels: eventLabels,
    datasets: [
      {
        label: 'Temperature (°F)',
        data: sortedEvents.map((evt) => {
          const sensorData = evt.sensor_event_data || [];
          const temp = sensorData.find((sed) => sed.sensor === 'temperature');
          if (!temp) return null;
          const val = temp.uoms.find((u) => u.uom_abbreviation === '°F')?.value;
          return typeof val === 'number' ? val : null;
        }),
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.3,
      },
    ],
  };

  // Example: Battery Voltage
  const batteryVoltageData = {
    labels: eventLabels,
    datasets: [
      {
        label: 'Battery Voltage (V)',
        data: sortedEvents.map((evt) => {
          const sensorData = evt.sensor_event_data || [];
          const battery = sensorData.find((sed) => sed.sensor === 'battery_life');
          if (!battery) return null;
          const volts = battery.uoms.find((u) => u.uom_abbreviation.toLowerCase() === 'v');
          return typeof volts?.value === 'number' ? volts.value : null;
        }),
        borderColor: 'rgba(153, 102, 255, 1)',
        tension: 0.3,
      },
    ],
  };

  // Example: Cellular Signal
  const cellularData = {
    labels: eventLabels,
    datasets: [
      {
        label: 'Cellular Signal (dBm)',
        data: sortedEvents.map((evt) => {
          const sensorData = evt.sensor_event_data || [];
          const signal = sensorData.find((sed) => sed.sensor === 'cellular_strength');
          const val = signal?.uoms.find((u) => u.uom_abbreviation === 'dBm')?.value || null;
          return typeof val === 'number' ? val : null;
        }),
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.3,
      },
    ],
  };

  /* ---------------------------
   Map Path (Using `gps_events`)
  ----------------------------*/
  const gpsEvents: IGpsEvent[] = deviceData.gps_events || [];

  // Filter out invalid lat/lng values
  const validGpsEvents = gpsEvents.filter((evt) => {
    const lat = Number(evt.gps_lat);
    const lng = Number(evt.gps_long);
    return !isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0 && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  });

  // Build a path from these valid GPS events
  const path = validGpsEvents.map((evt) => ({
    lat: Number(evt.gps_lat),
    lng: Number(evt.gps_long),
  }));

  const handleMarkerClick = (evt: IGpsEvent) => {
    setSelectedEvent(evt);
    if (map) {
      map.panTo({ lat: evt.gps_lat, lng: evt.gps_long });
    }
  };

  const productProfile = deviceData.product_profile;
  const links = [
    { onClick: () => history.push('/edgelink/devices/dashboard'), text: 'EdgeLink Devices' },
    { onClick: () => history.push('/edgelink/devices/dashboard'), text: `${edgelinkId}` },
  ];

  return (
    <Box sx={{ px: { xs: 1, sm: 2 }, py: 1.5, background: 'linear-gradient(to top, #fafafa, #f2f2f2)' }}>
      {/* Title & Breadcrumbs */}
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 0.5 }}>
        <Grid item>
          <Button
            variant="text"
            onClick={() => history.push('/edgelink/devices/map')}
            sx={{ textTransform: 'none', color: 'text.secondary', fontWeight: 500 }}
          >
            ← Return to Dashboard
          </Button>
          <Box sx={{ mt: 0.5 }}>
            <Breadcrumbs links={links} />
          </Box>
        </Grid>
      </Grid>

      {/* Main Info Cards */}
      <Grid container spacing={1}>
        {/* Device Info Card */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              p: 1.5,
              borderRadius: 2,
              bgcolor: '#fff',
              boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              height: '100%',
            }}
          >
            <CardContent sx={{ pb: 1 }}>
              <Typography variant="h5" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
                Device Info
              </Typography>
              <List dense disablePadding>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 28 }}>
                    <MemoryIcon sx={{ fontSize: '1.1rem', color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText primary="Device Type" secondary={deviceData.device_type.device_type_name} />
                </ListItem>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Location"
                    secondary={
                      locationString ? (
                        <Link
                          href={googleMapsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          color="primary"
                        >
                          {locationString}
                        </Link>
                      ) : (
                        <Link
                          href={googleMapsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          color="primary"
                        >
                          {`${deviceData.gps_lat}, ${deviceData.gps_long}`}
                        </Link>
                      )
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <AccessTimeIcon sx={{ color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText primary="Last Seen" secondary={lastSeenLocal} />
                </ListItem>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>{getBatteryIcon(deviceData.battery_life_level)}</ListItemIcon>
                  <ListItemText primary="Battery Life" secondary={deviceData.battery_life_level || 'Unknown'} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Product Info Card */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              p: 1.5,
              borderRadius: 2,
              bgcolor: '#fff',
              boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              height: '100%',
            }}
          >
            <CardContent sx={{ pb: 1 }}>
              <Typography variant="h5" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
                Product Info
              </Typography>
              <List dense disablePadding>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <ScienceIcon sx={{ color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Product"
                    secondary={productProfile ? productProfile.product_name : 'No product assigned'}
                  />
                  {productProfile && (
                    <InfoPopup productProfile={productProfile} isSeed={deviceData.device_type.device_type_id === 2} />
                  )}
                </ListItem>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <DescriptionIcon sx={{ color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Product Label"
                    secondary={
                      productProfile && productProfile.chemical_label_attachment ? (
                        <Link
                          href={productProfile.chemical_label_attachment}
                          target="_blank"
                          rel="noreferrer"
                          underline="none"
                          color="primary"
                        >
                          View Label
                        </Link>
                      ) : (
                        'No Label Available'
                      )
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 0.25 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <DescriptionIcon sx={{ color: 'text.secondary' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Safety Data Sheet"
                    secondary={
                      productProfile && productProfile.safety_data_sheet_attachment ? (
                        <Link
                          href={productProfile.safety_data_sheet_attachment}
                          target="_blank"
                          rel="noreferrer"
                          underline="none"
                          color="primary"
                        >
                          View Sheet
                        </Link>
                      ) : (
                        'No SDS Available'
                      )
                    }
                  />
                </ListItem>
                {deviceData.device_type.device_type_id === 1 && (
                  <ListItem sx={{ py: 0.25 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <OpacityIcon sx={{ color: 'text.secondary' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Current Amount"
                      secondary={
                        deviceData.remaining_liquid_gallons
                          ? `${deviceData.remaining_liquid_gallons.toFixed(2)} gal`
                          : 'N/A'
                      }
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Access Info Card */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              p: 1.5,
              borderRadius: 2,
              bgcolor: '#fff',
              boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              height: '100%',
            }}
          >
            <CardContent sx={{ pb: 1 }}>
              <Typography variant="h5" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
                Access Info
              </Typography>
              <List dense disablePadding>
                {deviceData.companies && deviceData.companies.length > 0 ? (
                  deviceData.companies.map((company, i) => {
                    let label = '';
                    let companyName = '';
                    if (company.primary_edgelink_company_id) {
                      label = 'Primary';
                      companyName = company.primary_edgelink_company_name || '';
                    } else if (company.secondary_edgelink_company_id) {
                      label = 'Secondary';
                      companyName = company.secondary_edgelink_company_name || '';
                    } else if (company.tertiary_edgelink_company_id) {
                      label = 'Tertiary';
                      companyName = company.tertiary_edgelink_company_name || '';
                    }
                    return (
                      <ListItem key={i} sx={{ py: 0.25 }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <LockOpenIcon sx={{ color: 'text.secondary' }} />
                        </ListItemIcon>
                        <ListItemText primary={`${label} Company`} secondary={companyName} />
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem sx={{ py: 0.25 }}>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <LockOpenIcon sx={{ color: 'text.secondary' }} />
                    </ListItemIcon>
                    <ListItemText primary="No company access data" />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second row: narrower Device Card (4 columns) + bigger Map (8 columns) */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={4}>
          <DeviceCard device={finalDeviceData} />
        </Grid>

        <Grid item xs={12} md={8} sx={{ display: 'flex' }}>
          <Card
            sx={{
              p: 1.5,
              borderRadius: 2,
              bgcolor: '#fff',
              boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              flex: 1,
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
                Device Path on Map
              </Typography>
              <LoadScript googleMapsApiKey="AIzaSyA2IxUu6GtCHq9DUTuLuLgaSoYwodgblyE">
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '320px' }}
                  center={{ lat: 0, lng: 0 }}
                  zoom={2}
                  onLoad={(mapInstance) => {
                    setMap(mapInstance);
                    if (validGpsEvents.length > 0) {
                      const bounds = new window.google.maps.LatLngBounds();
                      validGpsEvents.forEach((evt) => {
                        const lat = Number(evt.gps_lat);
                        const lng = Number(evt.gps_long);
                        if (!isNaN(lat) && !isNaN(lng) && (lat !== 0 || lng !== 0)) {
                          bounds.extend({ lat, lng });
                        }
                      });
                      if (!bounds.isEmpty()) {
                        mapInstance.fitBounds(bounds);
                      }
                    }
                  }}
                >
                  {validGpsEvents.map((evt) => (
                    <Marker
                      key={evt.event_id}
                      position={{ lat: evt.gps_lat, lng: evt.gps_long }}
                      onClick={() => handleMarkerClick(evt)}
                    />
                  ))}
                  <Polyline path={path} options={{ strokeColor: '#1976d2', strokeWeight: 4 }} />
                  {selectedEvent && (
                    <InfoWindow
                      position={{ lat: selectedEvent.gps_lat, lng: selectedEvent.gps_long }}
                      onCloseClick={() => setSelectedEvent(null)}
                    >
                      <Box sx={{ p: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Event Time: {new Date(selectedEvent.time_stamp).toLocaleString()}
                        </Typography>
                        <Typography variant="body2">
                          Lat: {selectedEvent.gps_lat}, Lng: {selectedEvent.gps_long}
                        </Typography>
                      </Box>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Sensor Data Section */}
      <Box sx={{ mt: 1.5 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
          Sensor Data
        </Typography>
        <Grid container spacing={1}>
          {/* Amount Remaining / Empty Distance Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                  {deviceData.device_type.device_type_id === 2 ? 'Empty Distance (mm)' : 'Amount Remaining (gal)'}
                </Typography>
                <Box height={300}>
                  <Line data={amountRemainingData} options={lineChartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Temperature Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Temperature (°F)
                </Typography>
                <Box height={300}>
                  <Line data={temperatureData} options={lineChartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ mt: 1 }}>
          {/* Battery Voltage Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Battery Voltage (V)
                </Typography>
                <Box height={300}>
                  <Line data={batteryVoltageData} options={lineChartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Cellular Signal Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Cellular Signal (dBm)
                </Typography>
                <Box height={300}>
                  <Line data={cellularData} options={lineChartOptions} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Event History Section (using deviceData.events) */}
      <Box sx={{ mt: 1.5 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 1, fontWeight: 'bold' }}>
          Event History
        </Typography>
        <Divider sx={{ mb: 1 }} />
        {deviceData.events.map((evt) => {
          const localTime = new Date(evt.time_stamp).toLocaleString();
          const evtMapsLink = `https://www.google.com/maps?q=${evt.gps_lat},${evt.gps_long}`;
          return (
            <Card
              key={evt.event_id}
              sx={{
                mb: 1,
                p: 1.5,
                borderRadius: 2,
                bgcolor: '#fff',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ mb: 0.5, fontWeight: 'bold' }}>
                  {localTime}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Location:</strong>{' '}
                  <Link href={evtMapsLink} target="_blank" rel="noopener noreferrer">
                    {evt.gps_lat}, {evt.gps_long}
                  </Link>{' '}
                  {evt.transmit_reason && (
                    <>
                      {' | '}
                      <strong>Transmit Reason:</strong> {evt.transmit_reason}
                    </>
                  )}
                </Typography>
                <Divider sx={{ mb: 0.5 }} />
                {evt.sensor_event_data && (
                  <Grid container spacing={0.5}>
                    {evt.sensor_event_data
                      ?.slice()
                      ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                      ?.map((sed, i) => {
                        const rawLabel = sed.sensor || 'Unknown Sensor';
                        const label = rawLabel
                          .split('_')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(' ');
                        const chips = sed.uoms.map((u) =>
                          typeof u.value === 'number'
                            ? `${u.value.toFixed(2)} ${u.uom_abbreviation}`
                            : `${u.value} ${u.uom_abbreviation}`,
                        );
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={2} key={rawLabel + i}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.25 }}>
                              {label}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {chips.map((text, index) => (
                                <Chip key={index} label={text} size="small" sx={{ fontSize: '0.75rem' }} />
                              ))}
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default DeviceDashboard;
