// databridge/DataBridgeManagement.tsx
import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import PartnersPage from './partners/pages/PartnersPage';
import AdaptersPage from './adapters/pages/AdaptersPage';
import IntegrationsPage from './integrations/pages/IntegrationsPage';

type TabType = 'partners' | 'adapters' | 'integrations';
type FormModeType = 'new' | 'edit' | null;

interface DataBridgeManagementProps {
  initialTab?: TabType;
  formMode?: FormModeType;
}

export default function DataBridgeManagement({ initialTab, formMode }: DataBridgeManagementProps) {
  const [currentTab, setCurrentTab] = useState<TabType>(initialTab || 'partners');
  const history = useHistory();
  const location = useLocation();
  const { integrationId } = useParams<{ integrationId: string }>();

  // Update URL when tab changes (without this, direct links would work but clicking tabs wouldn't update URL)
  useEffect(() => {
    if (initialTab && initialTab !== currentTab) {
      setCurrentTab(initialTab);
    }
  }, [initialTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setCurrentTab(newValue);
    // Update URL to reflect the current tab
    history.push(`/admin/databridge_management/${newValue}`);
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: '2rem' }}>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Partners" value="partners" />
        <Tab label="Adapters" value="adapters" />
        <Tab label="Integrations" value="integrations" />
      </Tabs>

      {currentTab === 'partners' && <PartnersPage />}
      {currentTab === 'adapters' && <AdaptersPage />}
      {currentTab === 'integrations' && (
        <IntegrationsPage formMode={formMode} integrationId={integrationId ? parseInt(integrationId, 10) : null} />
      )}
    </Container>
  );
}
