import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';
import { Box, Switch, Typography } from '@mui/material';

const formatDate = (dateString: string) => {
  if (!dateString) return '';
  // Handle UTC date and convert to local browser timezone
  // The API now returns ISO 8601 format with Z suffix
  try {
    // No need to append Z as it's already included in the API response
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (error) {
    console.error('Error parsing date:', error, dateString);
    return dateString || '';
  }
};

const columns: IDataTableColumn<any>[] = [
  {
    name: 'ID',
    selector: 'driver_id',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Name',
    selector: 'driver_name',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'driver_phone',
    sortable: true,
  },
  {
    name: 'Access Code',
    selector: 'driver_access_code',
    sortable: true,
    cell: (row: any) => (
      <div title={row.driver_access_code || ''} style={{ cursor: 'default' }}>
        {row.driver_access_code ? '********' : ''}
      </div>
    ),
  },
  {
    name: 'Comment',
    selector: 'driver_comment',
    sortable: true,
  },
  {
    name: 'RFID ID',
    selector: 'rfid_id',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'RFID Name',
    selector: 'rfid_name',
    sortable: true,
  },
  {
    name: 'RFID Code',
    selector: 'rfid_code',
    sortable: true,
    cell: (row: any) => <p>********</p>,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    grow: 0.6,
    cell: (row: any) => {
      const isActive = row.active === 'True';
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Switch
            checked={isActive}
            size="small"
            disabled
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#4caf50',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#4caf50',
                opacity: 0.8,
              },
              '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                color: '#4caf50',
              },
              '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#4caf50',
                opacity: 0.5,
              },
              '& .MuiSwitch-switchBase.Mui-disabled': {
                color: isActive ? '#4caf50' : '#bdbdbd',
              },
              '& .MuiSwitch-track': {
                backgroundColor: isActive ? '#4caf50' : '#bdbdbd',
                opacity: 0.5,
              },
            }}
          />
        </Box>
      );
    },
  },
  {
    name: 'Updated At',
    selector: 'date_and_time',
    sortable: true,
    cell: (row: any) => <p>{formatDate(row.date_and_time)}</p>,
  },
  {
    name: 'Updated By',
    selector: 'user_name',
    sortable: true,
  },
];

export default columns;
