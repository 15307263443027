import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Tabs, Tab, Typography, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import TabPanel from '../../shared/tabs/TabPanel';
import { ManufacturersContext } from '../contexts/ManufacturersContext';
import { MfrRecipesProvider, useMfrRecipesContext } from '../contexts/MfrRecipesContext';
import { MfrLiquidsProvider, useMfrLiquidsContext } from '../contexts/MfrLiquidsContext';
import { MfrSeedsProvider, useMfrSeedsContext } from '../contexts/MfrSeedsContext';
import { MfrGTINPrefixesProvider, useMfrGTINPrefixesContext } from '../contexts/MfrGTINPrefixesContext';
import manufacturerColumns from '../constants/manufacturerColumns';
import mfrRecipeColumns from '../constants/mfrRecipeColumns';
import liquidColumns from '../constants/mfrLiquidColumns';
import seedColumns from '../constants/mfrSeedColumns';
import gtinPrefixColumns from '../constants/mfrGTINPrefixColumns';

const AdminManufacturerListPage = () => {
  const { fetchManufacturers, manufacturers, manufacturersLoading } = useContext(ManufacturersContext);
  const { fetchRecipes, recipes, recipesLoading } = useMfrRecipesContext();
  const { liquids, liquidsLoading, fetchLiquids } = useMfrLiquidsContext();
  const { seeds, seedsLoading, fetchSeeds } = useMfrSeedsContext();
  const { gtinPrefixes, gtinPrefixesLoading, fetchGTINPrefixes } = useMfrGTINPrefixesContext();

  useEffect(() => {
    fetchSeeds();
    fetchGTINPrefixes();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = parseInt(searchParams.get('activeTab') || '0', 10);
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    if (activeTab === 0 && !manufacturers.length) fetchManufacturers();
    else if (activeTab === 1 && !recipes.length) fetchRecipes();
    else if (activeTab === 2 && !seeds.length) fetchSeeds();
    else if (activeTab === 3 && !liquids.length) fetchLiquids();
    else if (activeTab === 4 && !gtinPrefixes.length) fetchGTINPrefixes();
  }, [activeTab]);

  const handleTabChange = (_event, newValue) => {
    setActiveTab(newValue);
    const newParams = new URLSearchParams(location.search);
    newParams.set('activeTab', String(newValue));
    window.history.replaceState({}, '', `${location.pathname}?${newParams.toString()}`);
  };

  const handleAddButtonClick = () => {
    const routes = [
      '/admin/manufacturers/new',
      '/admin/recipes/new',
      '/admin/seeds/new',
      '/admin/liquids/new',
      '/admin/gtin-prefixes/new',
    ];
    window.location.href = routes[activeTab];
  };

  return (
    <MfrRecipesProvider>
      <MfrLiquidsProvider>
        <MfrSeedsProvider>
          <MfrGTINPrefixesProvider>
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h1" component="h1">
                  Manufacturers
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleAddButtonClick}>
                  New {['Manufacturer', 'Treatment Recipe', 'Seed', 'Treatment Product', 'GTIN Prefix'][activeTab]}
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="tabs">
                {['Manufacturers', 'Treatment Recipes', 'Seeds', 'Treatment Products', 'GTIN Prefixes'].map(
                  (name, index) => (
                    <Tab key={index} label={name} />
                  ),
                )}
              </Tabs>
            </Box>
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              <Grid item xs={12}>
                {['Manufacturers', 'Recipes', 'Liquids', 'Seeds', 'GTIN Prefixes'].map((name, index) => (
                  <TabPanel key={index} value={activeTab} index={index}>
                    <Card>
                      <CardContent>
                        <DataTable
                          title={<Typography variant="h5">{name} List</Typography>}
                          columns={
                            [manufacturerColumns, mfrRecipeColumns, seedColumns, liquidColumns, gtinPrefixColumns][
                              index
                            ]
                          }
                          data={[manufacturers, recipes, seeds, liquids, gtinPrefixes][index]}
                          defaultSortAsc={false}
                          striped
                          highlightOnHover
                          pointerOnHover
                          progressPending={
                            [manufacturersLoading, recipesLoading, seedsLoading, liquidsLoading, gtinPrefixesLoading][
                              index
                            ]
                          }
                          pagination
                          onRowClicked={(row) => {
                            const urls = [
                              `/admin/autotreat_mfr/mfrs/${row.autotreat_mfr_cloud_id}`,
                              `/admin/autotreat_mfr/mfr_recipes/${row.autotreat_mfr_recipe_cloud_id}`,
                              `/admin/autotreat_mfr/mfr_seeds/${row.autotreat_mfr_seed_cloud_id}`,
                              `/admin/autotreat_mfr/mfr_liquids/${row.autotreat_mfr_liquid_cloud_id}`,
                              `/admin/autotreat_mfr/gtin_prefixes/${row.autotreat_gtin_prefix_cloud_id}`,
                            ];
                            window.location.href = urls[index];
                          }}
                        />
                      </CardContent>
                    </Card>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </MfrGTINPrefixesProvider>
        </MfrSeedsProvider>
      </MfrLiquidsProvider>
    </MfrRecipesProvider>
  );
};

export default AdminManufacturerListPage;
