import React from 'react';
import { Grid, Stack, TextField, Switch, FormControlLabel, Button, Divider, Typography, Box } from '@mui/material';

interface CompanyFormData {
  name: string;
  main_contact: string;
  main_contact_phone: string;
  main_contact_email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  default_temp_sensor_uom: string; // might store ID as string
  default_volume_sensor_uom: string; // might store ID as string
  disable_public_access: number; // 0 or 1
}

interface CompanyFormContentProps {
  formData: CompanyFormData;
  setFormData: (data: CompanyFormData) => void;
  onSave: () => void;
  onCancel: () => void;
}

const CompanyFormContent: React.FC<CompanyFormContentProps> = ({ formData, setFormData, onSave, onCancel }) => {
  /** Generic field handler */
  const handleChange = (field: keyof CompanyFormData, value: string | number) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      {/* 1) Company Name Section */}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <TextField
            label="Company Name"
            value={formData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* 2) Contact Details Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Main Contact"
            value={formData.main_contact}
            onChange={(e) => handleChange('main_contact', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone"
            value={formData.main_contact_phone}
            onChange={(e) => handleChange('main_contact_phone', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={formData.main_contact_email}
            onChange={(e) => handleChange('main_contact_email', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* 3) Address Details Section */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Address"
            value={formData.address}
            onChange={(e) => handleChange('address', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="City"
            value={formData.city}
            onChange={(e) => handleChange('city', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="State"
            value={formData.state}
            onChange={(e) => handleChange('state', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Zip" value={formData.zip} onChange={(e) => handleChange('zip', e.target.value)} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Country"
            value={formData.country}
            onChange={(e) => handleChange('country', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* 4) Default Values Section (UOM + Public Access) */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Default Temp Sensor UOM (ID)"
            value={formData.default_temp_sensor_uom}
            onChange={(e) => handleChange('default_temp_sensor_uom', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Default Volume Sensor UOM (ID)"
            value={formData.default_volume_sensor_uom}
            onChange={(e) => handleChange('default_volume_sensor_uom', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={formData.disable_public_access === 1}
                onChange={(e) => handleChange('disable_public_access', e.target.checked ? 1 : 0)}
              />
            }
            label="Disable Public Access?"
          />
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default CompanyFormContent;
