// src/devices/components/DeviceSidebar.tsx

import React from 'react';
import { Drawer, SwipeableDrawer, Box, useTheme, useMediaQuery } from '@mui/material';
import DeviceCardContentPrimary from '../pages/DeviceContentCard';
import { DeviceResult } from '../../model';

export interface DeviceSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  /**
   * Optional: Only used by SwipeableDrawer on mobile if you
   * want to allow swiping in from the left edge to open.
   */
  onOpen?: () => void;
  selectedDevice: DeviceResult | null;

  /**
   * Pass through to DeviceCardContentPrimary
   * so we can optionally show the popout icon
   */
  popoutEnabled?: boolean;

  /**
   * Pass through to DeviceCardContentPrimary
   * so we can optionally show the map inside the card
   */
  showMap?: boolean;

  /**
   * Callback when device data is updated
   */
  onDeviceUpdated?: () => Promise<void>;
}

const DeviceSidebar: React.FC<DeviceSidebarProps> = ({
  isOpen,
  onClose,
  onOpen,
  selectedDevice,
  popoutEnabled,
  showMap,
  onDeviceUpdated,
}) => {
  const theme = useTheme();
  // Determine if screen is below "sm" => mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Decide which Drawer component to use based on screen size
  const DrawerComponent = isMobile ? SwipeableDrawer : Drawer;

  return (
    <DrawerComponent
      anchor="left"
      open={isOpen}
      onClose={onClose}
      /**
       * Only used by SwipeableDrawer (mobile):
       * If you want to allow "swipe from left edge to open",
       * you could implement that here. Otherwise, you can
       * leave onOpen as a no-op or remove it.
       */
      onOpen={onOpen || (() => {})}
      /**
       * For SwipeableDrawer, these help avoid accidental
       * swipes on non-touch devices:
       */
      disableBackdropTransition={!isMobile}
      disableDiscovery={!isMobile}
      /**
       * We use "temporary" for both mobile and desktop now,
       * so that:
       * - On mobile, user can swipe to dismiss.
       * - On desktop, user can click outside to close.
       */
      variant="temporary"
      /**
       * Style the drawer's paper. We make it full width and
       * full height on mobile, while using your original
       * styling on larger screens.
       */
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 460 },
          height: { xs: '100%', sm: '97%' },
          mt: { xs: 0, sm: 2 },
          mb: { xs: 0, sm: 2 },
          ml: { xs: 0, sm: 2 },
          borderRadius: { xs: 0, sm: 2 },
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      }}
    >
      {/* 
        Give the content some padding and make it scrollable 
        if there's a lot of data.
      */}
      <Box sx={{ p: 2, overflowY: 'auto', flexGrow: 1 }}>
        <DeviceCardContentPrimary
          selectedDevice={selectedDevice}
          popoutEnabled={popoutEnabled}
          showMap={showMap}
          onDeviceUpdated={onDeviceUpdated}
        />
      </Box>
    </DrawerComponent>
  );
};

export default DeviceSidebar;
