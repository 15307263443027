import React, { createContext, useState, useContext } from 'react';
import axios from '../../utils/axios.utils';

// Define the shape of the context
interface ManufacturersNewContextType {
  createManufacturer: (manufacturerData: Record<string, any>) => Promise<any>;
  formSubmitting: boolean;
  formErrors: Record<string, any>;
}

// Initialize the context with an empty default value
export const ManufacturersNewContext = createContext<ManufacturersNewContextType | undefined>(undefined);

export const ManufacturersNewProvider: React.FC = ({ children }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});

  const createManufacturer = async (manufacturerData: Record<string, any>) => {
    setFormSubmitting(true);
    try {
      const response = await axios.post('/api/admin/manufacturers/', manufacturerData, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      if (response.data.success) {
        setFormErrors({});
        return response.data;
      }
      setFormErrors(response.data.errors || {});
    } catch (error) {
      console.error('Error creating manufacturer:', error);
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <ManufacturersNewContext.Provider value={{ createManufacturer, formSubmitting, formErrors }}>
      {children}
    </ManufacturersNewContext.Provider>
  );
};

// Custom hook for convenience
export const useManufacturersNewContext = () => {
  const context = useContext(ManufacturersNewContext);
  if (!context) {
    throw new Error('useManufacturersNewContext must be used within a ManufacturersNewProvider');
  }
  return context;
};
