import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Typography, LinearProgress, Stack, Divider, Button, Link } from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from '../../../utils/axios.utils'; // Adjust import path as needed

// --- REACT GOOGLE MAPS IMPORTS ---

/**
 * Helper function to convert a date string into a relative time (e.g., "3 days 4h ago").
 */
function timeSince(dateString: string | null): string {
  if (!dateString) return 'Unknown';
  const now = new Date();
  const past = new Date(dateString);
  const diffMs = now.getTime() - past.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    const remainderHours = diffHours % 24;
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ${remainderHours}h ago`;
  }
  if (diffHours > 0) {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  }
  if (diffMinutes > 0) {
    return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
  }
  return 'Just now';
}

/**
 * Interfaces for API response. Adjust as needed.
 */
interface EdgelinkDeviceType {
  device_type_id: number; // e.g. 1 => Liquid, 2 => Seed
  device_type_name: string; // "Liquid" or "Seed"
}

interface ProductProfile {
  product_profile_id: number;
  product_name: string | null;
  lot_number: string | null;
  batch_number: string | null;
  crop: string | null;
  safety_data_sheet_attachment: string | null;
  chemical_label_attachment: string | null;
  // For both liquid and seed
  loaded_at: string | null;
  emptied_at?: string | null;
  // Seed-specific fields
  seed_units?: string | null;
  seed_treatment_package?: string | null;
  additional_notes?: string | null;
  seed_size?: string | null;
  status?: boolean;
}

interface DeviceResult {
  edgelink_device_id: number;
  device_serial_number: string;
  microcontroller_serial: string;
  last_seen_at: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  battery_life_level: string | null;
  fill_status: string | null;
  status_message?: string | null;
  device_type: EdgelinkDeviceType | null;
  product_profile: ProductProfile | null;

  // Assume your API returns these for GPS location:
  gps_lat?: string | number | null;
  gps_long?: string | number | null;
}

interface DeviceResponse {
  success: boolean;
  results: DeviceResult[];
}

const DeviceDetailsTertiary: React.FC = () => {
  const history = useHistory();
  const { deviceSerial } = useParams<{ deviceSerial: string }>();

  /** State for fetching device info */
  const [device, setDevice] = React.useState<DeviceResult | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    let ignore = false;

    const fetchDeviceData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Make the API call using axios
        const response = await axios.get<any, any>(
          `/api/user/edgelink/devices/dashboard?device_serial_number=${deviceSerial}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            },
          },
        );

        if (!ignore) {
          if (response.data.success && Array.isArray(response.data.results)) {
            const record = response.data.results[0] || null;
            if (!record) {
              setError('No device found for this serial.');
            }
            setDevice(record);
          } else {
            setError('Invalid response from server.');
          }
        }
      } catch (err: any) {
        if (!ignore) {
          console.error('Error fetching device data:', err);
          setError('Failed to fetch device data.');
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
      }
    };

    fetchDeviceData();

    return () => {
      ignore = true;
    };
  }, [deviceSerial]);

  /** Determine if the device is "Seed" or "Liquid". */
  const isSeedDevice = device?.device_type?.device_type_name.toLowerCase() === 'seed';

  /** Show "S" or "L" in the circular icon. */
  const deviceTypeAbbrev = isSeedDevice ? 'S' : 'L';

  /** Helper to display "N/A" if a string is null/empty. */
  const displayOrNothing = (val?: string | number | null) =>
    val !== null && val !== undefined && val !== '' ? String(val) : '';

  /** Navigate to the device dashboard. */
  const goToDeviceDashboard = () => {
    history.push(`/edgelink/device/${deviceSerial}/dashboard`);
  };

  // Prepare lat/long as numbers, if available
  const lat = device?.gps_lat ? Number(device.gps_lat) : null;
  const lng = device?.gps_long ? Number(device.gps_long) : null;

  return (
    <Box sx={{ maxWidth: 900, margin: 'auto', p: 2 }}>
      {loading && <LinearProgress />}

      {error && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography color="error">{error}</Typography>
        </Paper>
      )}

      {/* If no error and device is loaded, show content */}
      {!loading && !error && device && (
        <Box sx={{ borderRadius: 2, boxShadow: 3, overflow: 'hidden' }}>
          {/* Header Section */}
          <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Device Info
            </Typography>
          </Box>

          {/* Top Card: Basic Device Info */}
          <Paper variant="outlined" sx={{ p: 2, m: 2, borderRadius: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              {/* Circular Icon with "S" or "L" */}
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  bgcolor: '#ccc',
                  color: '#fff',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 20,
                }}
              >
                {deviceTypeAbbrev}
              </Box>

              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#3f51b5' }}>
                  Device Serial: {device.device_serial_number}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Device Type: {device.device_type?.device_type_name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Last Check-In: {timeSince(device.last_seen_at)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Location: {displayOrNothing(device.city)}
                  {device.state && `, ${device.state}`}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>Status:</strong>{' '}
                  <Box component="span" sx={{ color: 'orange' }}>
                    {displayOrNothing(device.fill_status)}
                  </Box>
                </Typography>
              </Box>
            </Stack>
          </Paper>

          {/* Details Section */}
          <Paper variant="outlined" sx={{ p: 2, m: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Details
            </Typography>
            <Stack spacing={0.5}>
              <Typography variant="body2">
                <strong>Serial:</strong> {device.device_serial_number}
              </Typography>
              <Typography variant="body2">
                <strong>Address:</strong> {displayOrNothing(device.address)}
              </Typography>
              <Typography variant="body2">
                <strong>City/State/Zip:</strong> {displayOrNothing(device.city)}
                {device.state ? `, ${device.state}` : ''} {displayOrNothing(device.zip_code)}
              </Typography>
              <Typography variant="body2">
                <strong>Country:</strong> {displayOrNothing(device.country)}
              </Typography>
            </Stack>
          </Paper>

          {/* Product Profile Section */}
          <Paper variant="outlined" sx={{ p: 2, m: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Product Profile
            </Typography>
            {device.product_profile ? (
              <Stack spacing={0.5}>
                <Typography variant="body2">
                  <strong>Product:</strong> {displayOrNothing(device.product_profile.product_name)}
                </Typography>
                <Typography variant="body2">
                  <strong>Lot Number:</strong> {displayOrNothing(device.product_profile.lot_number)}
                </Typography>
                <Typography variant="body2">
                  <strong>Batch Number:</strong> {displayOrNothing(device.product_profile.batch_number)}
                </Typography>
                <Typography variant="body2">
                  <strong>Crop:</strong> {displayOrNothing(device.product_profile.crop)}
                </Typography>
                {/* Loaded and Emptied Dates */}
                <Typography variant="body2">
                  <strong>Loaded At:</strong>{' '}
                  {device.product_profile.loaded_at
                    ? new Date(device.product_profile.loaded_at).toLocaleString()
                    : 'N/A'}
                </Typography>
                <Typography variant="body2">
                  <strong>Emptied At:</strong>{' '}
                  {device.product_profile.emptied_at
                    ? new Date(device.product_profile.emptied_at).toLocaleString()
                    : 'N/A'}
                </Typography>
                {/* Seed vs. Liquid extra fields */}
                {isSeedDevice ? (
                  <>
                    <Typography variant="body2">
                      <strong>Seed Size:</strong> {displayOrNothing(device.product_profile.seed_size)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Seed Units:</strong> {displayOrNothing(device.product_profile.seed_units)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Treatment Package:</strong>{' '}
                      {displayOrNothing(device.product_profile.seed_treatment_package)}
                    </Typography>
                  </>
                ) : null}
                <Typography variant="body2">
                  <strong>Additional Notes:</strong> {displayOrNothing(device.product_profile.additional_notes)}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                No product associated
              </Typography>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Attachments */}
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Product Attachments
            </Typography>
            <Stack spacing={1}>
              {device.product_profile?.safety_data_sheet_attachment ? (
                <Box
                  component="a"
                  href={device.product_profile.safety_data_sheet_attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <DescriptionIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Safety Sheet
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    opacity: 0.5,
                  }}
                >
                  <DescriptionIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Safety Sheet Not Available
                  </Typography>
                </Box>
              )}

              {device.product_profile?.chemical_label_attachment ? (
                <Box
                  component="a"
                  href={device.product_profile.chemical_label_attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                  }}
                >
                  <DescriptionIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Product Label
                  </Typography>
                  <ChevronRightIcon sx={{ ml: 'auto' }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    borderRadius: 1,
                    opacity: 0.5,
                  }}
                >
                  <DescriptionIcon />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    Product Label Not Available
                  </Typography>
                </Box>
              )}
            </Stack>
          </Paper>

          {/* Actions Section */}
          <Paper variant="outlined" sx={{ p: 2, m: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
              Actions
            </Typography>
            <Stack spacing={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f5f5f5' },
                }}
                onClick={goToDeviceDashboard}
              >
                <DashboardIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Device Dashboard
                </Typography>
                <ChevronRightIcon sx={{ ml: 'auto' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <LocalShippingIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Create Delivery Ticket
                </Typography>
                <ChevronRightIcon sx={{ ml: 'auto' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <SwapHorizIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Mark For Pickup
                </Typography>
                <ChevronRightIcon sx={{ ml: 'auto' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: 1,
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <InventoryIcon />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Receive
                </Typography>
                <ChevronRightIcon sx={{ ml: 'auto' }} />
              </Box>
            </Stack>
          </Paper>

          {/* Device Location (single marker) */}
          {lat !== null && lng !== null && (
            <Paper variant="outlined" sx={{ p: 2, m: 2, borderRadius: 2 }}>
              <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
                Device Location
              </Typography>
              <Box sx={{ width: '100%', height: 300, mb: 1 }}>
                <LoadScript googleMapsApiKey="AIzaSyA2IxUu6GtCHq9DUTuLuLgaSoYwodgblyE">
                  <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={{ lat, lng }} zoom={12}>
                    <Marker position={{ lat, lng }} />
                  </GoogleMap>
                </LoadScript>
              </Box>
              <Typography variant="body2">
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open in Maps
                </Link>
              </Typography>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DeviceDetailsTertiary;
