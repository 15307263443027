/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Machine } from '../../machinesAdminManagement/model';
import DataTable from '../../dataTable/DataTable/DataTable';
import { MachineDeploymentContext } from '../contexts/DeploymentsContext';
import deploymentColumns from '../constants/deploymentColumns';
import DeploymentsFilterBar from '../components/DeploymentFilterBar';
import defaultFilterOptions from '../constants/defaultFilterOptions';

const DeploymentsList: React.FunctionComponent = () => {
  const history = useHistory();

  const { machineDeployments, sendMachineDelpoyments } = React.useContext(MachineDeploymentContext);
  const { fetchMachineDeployments, machineDeploymentsLoading, fetchMachineDeploymentProgressDetails } =
    React.useContext(MachineDeploymentContext);

  const [tableFilters, setTableFilters] = React.useState(defaultFilterOptions);

  React.useEffect(() => {
    fetchMachineDeployments();
  }, []);

  const filterDeployments = () => {
    if (
      tableFilters.machine_serial ||
      tableFilters.machine_name ||
      tableFilters.company ||
      tableFilters.system_version !== 'All' ||
      tableFilters.software_type !== 'All' ||
      tableFilters.completed !== 'All'
    ) {
      let filteredDeployments = machineDeployments;

      if (tableFilters.machine_serial) {
        filteredDeployments = filteredDeployments.filter((deployment) =>
          deployment.serial_number?.toLowerCase().includes(tableFilters.machine_serial.toLowerCase()),
        );
      }

      if (tableFilters.machine_name) {
        filteredDeployments = filteredDeployments.filter((deployment) =>
          deployment.machine_name_with_serial?.toLowerCase().includes(tableFilters.machine_name.toLowerCase()),
        );
      }

      if (tableFilters.company) {
        filteredDeployments = filteredDeployments.filter((deployment) =>
          deployment.company_name?.toLowerCase().includes(tableFilters.company.toLowerCase()),
        );
      }

      if (tableFilters.system_version !== 'All') {
        filteredDeployments = filteredDeployments.filter((deployment) =>
          deployment.system_version?.toLowerCase().includes(tableFilters.system_version.toLowerCase()),
        );
      }
      if (tableFilters.software_type !== 'All') {
        filteredDeployments = filteredDeployments.filter((deployment) =>
          deployment.machine_software_type?.toLowerCase().includes(tableFilters.software_type.toLowerCase()),
        );
      }

      if (tableFilters.completed !== 'All') {
        if (tableFilters.completed === 'True') {
          filteredDeployments = filteredDeployments.filter((deployment) => {
            return deployment.event_completed === true;
          });
        } else if (tableFilters.completed === 'False') {
          filteredDeployments = filteredDeployments.filter((deployment) => {
            return deployment.event_completed === null;
          });
        }
      }

      return filteredDeployments;
    }

    return machineDeployments;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Deployments
          </Typography>
          <br />
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'left' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ color: '#fff' }}
            disableElevation
            onClick={() => {
              history.push(`/admin/software_versions`);
            }}
          >
            Manage Software Versions
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            style={{ color: '#fff' }}
            disableElevation
            onClick={() => {
              fetchMachineDeploymentProgressDetails('8-8043-8888', '4545')
            }}
          >
            Fetch Info From Machine
          </Button> */}
        </Grid>
      </Grid>
      <DeploymentsFilterBar
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        fetchFunction={fetchMachineDeployments}
      />
      <br />
      <Card>
        <CardContent>
          <Grid justifyContent="space-between" container spacing={0}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                Deployments
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  sendMachineDelpoyments('');
                }}
              >
                Send Deployments
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  history.push(`/admin/machine_deployments/create`);
                }}
              >
                Create Deployment
              </Button>
            </Grid>
          </Grid>
          <br />
          <DataTable
            noHeader
            columns={deploymentColumns}
            data={filterDeployments()}
            defaultSortField="order_complete_date_and_time_utc"
            defaultSortAsc={false}
            striped
            highlightOnHover
            pointerOnHover
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50]}
            progressPending={machineDeploymentsLoading}
          />
        </CardContent>
        <br />
      </Card>
      <br />
    </>
  );
};

export default DeploymentsList;
