import { format, parseISO } from 'date-fns';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import SoftwareVersionRowMenu from '../components/SoftwareVersionRowMenu';

const softwareVersionColumns: IDataTableColumn<any>[] = [
  {
    name: 'Sofrware Version Id',
    selector: 'machine_software_version_id',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'Software Type',
    selector: 'machine_software_type',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Machine Type',
    selector: 'machine_type',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Software Version Name',
    selector: 'machine_software_version_name',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'S3 Path',
    selector: 'installer_s3_path',
    sortable: true,
  },
  {
    name: 'Release Notes',
    selector: 'release_notes',
    sortable: true,
  },
  {
    name: 'Created By User',
    selector: 'created_by_user',
    sortable: true,
  },
  {
    name: `Created At (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'created_date_time',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.created_date_time) {
        try {
          const date = parseISO(row.created_date_time);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    name: `Modified On (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'last_modified_date_time',
    sortable: true,
    cell: (row: Record<string, string>): JSX.Element => {
      let formattedDate = '';
      if (row.last_modified_date_time) {
        try {
          const date = parseISO(row.last_modified_date_time);

          formattedDate = format(date, 'MM/dd/yyyy hh:mm aaa');
        } catch (error) {
          console.error(`Error parsing date: ${error}`);
        }
      }
      return <p>{formattedDate}</p>;
    },
  },
  {
    cell: (row) => <SoftwareVersionRowMenu softwareVersion={row} />,
    name: 'Actions',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
  // {
  //   name: 'Status',
  //   selector: 'status',
  //   sortable: true,
  // },
  // {
  //   name: 'Obsolete',
  //   selector: 'obsolete',
  //   sortable: true,
  // },
];

export default softwareVersionColumns;
