import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { MenuItem, Grid } from '@mui/material';
import { format, parse, parseISO } from 'date-fns';

const DateSelectFilter: React.FC = () => {
  const storedTimePeriod = localStorage.getItem('timePeriod') || '';
  const storedStartDate = localStorage.getItem('startDate') || '';
  const storedEndDate = localStorage.getItem('endDate') || '';

  const initialStartDate = storedStartDate
    ? (() => {
        try {
          // If stored date contains T12:00:00 or timestamp format, handle accordingly
          if (storedStartDate.includes('T12:00:00') || storedStartDate.includes(' 12:00:00')) {
            const datePart = storedStartDate.split('T')[0].split(' ')[0];
            // Try to parse the date part as MM/dd/yyyy
            const parsed = parse(datePart, 'MM/dd/yyyy', new Date());
            return format(parsed, 'yyyy-MM-dd');
          } else {
            return format(parse(storedStartDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd');
          }
        } catch {
          return '';
        }
      })()
    : '';
  const initialEndDate = storedEndDate
    ? (() => {
        try {
          // If stored date contains T12:00:00 or timestamp format, handle accordingly
          if (storedEndDate.includes('T12:00:00') || storedEndDate.includes(' 12:00:00')) {
            const datePart = storedEndDate.split('T')[0].split(' ')[0];
            // Try to parse the date part as MM/dd/yyyy
            const parsed = parse(datePart, 'MM/dd/yyyy', new Date());
            return format(parsed, 'yyyy-MM-dd');
          } else {
            return format(parse(storedEndDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd');
          }
        } catch {
          return '';
        }
      })()
    : '';

  const [timePeriod, setTimePeriod] = React.useState<string>(storedTimePeriod);
  const [startDate, setStartDate] = React.useState<string>(initialStartDate);
  const [endDate, setEndDate] = React.useState<string>(initialEndDate);

  const handleTimePeriodChange = (event: any) => {
    const newTimePeriod = event.target.value;
    localStorage.setItem('timePeriod', newTimePeriod);
    localStorage.setItem('startDate', '');
    localStorage.setItem('endDate', '');
    setTimePeriod(newTimePeriod);
    setStartDate('');
    setEndDate('');
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsoValue = event.target.value;
    try {
      // Create a date object with the selected date at exactly 12:00:00 in local time
      // This ensures the date remains the same regardless of timezone conversions
      const dateOnly = newIsoValue.split('T')[0]; // Get just the date part YYYY-MM-DD
      const dateParts = dateOnly.split('-').map(Number);

      // Create the formatted date string (using selected date but fixed time)
      // Month is 0-based in Date constructor but 1-based in our output format
      const formattedDate = `${String(dateParts[1]).padStart(2, '0')}/${String(dateParts[2]).padStart(2, '0')}/${
        dateParts[0]
      }`;

      // Store explicitly as MM/DD/YYYY format with fixed noon time to prevent any timezone issues
      localStorage.setItem('startDate', formattedDate);
      setStartDate(newIsoValue);
    } catch {
      localStorage.setItem('startDate', '');
      setStartDate('');
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsoValue = event.target.value;
    try {
      // Create a date object with the selected date at exactly 12:00:00 in local time
      // This ensures the date remains the same regardless of timezone conversions
      const dateOnly = newIsoValue.split('T')[0]; // Get just the date part YYYY-MM-DD
      const dateParts = dateOnly.split('-').map(Number);

      // Create the formatted date string (using selected date but fixed time)
      // Month is 0-based in Date constructor but 1-based in our output format
      const formattedDate = `${String(dateParts[1]).padStart(2, '0')}/${String(dateParts[2]).padStart(2, '0')}/${
        dateParts[0]
      }`;

      // Store explicitly as MM/DD/YYYY format with fixed noon time to prevent any timezone issues
      localStorage.setItem('endDate', formattedDate);
      setEndDate(newIsoValue);
    } catch {
      localStorage.setItem('endDate', '');
      setEndDate('');
    }
  };

  const formattedStartDate = startDate ? format(new Date(startDate), 'MM/dd/yyyy') : '';
  const formattedEndDate = endDate ? format(new Date(endDate), 'MM/dd/yyyy') : '';

  return (
    <FormControl sx={{ minWidth: 120, marginTop: '7px' }}>
      <>
        <div style={{ margin: '-7px 0 9px' }}>
          <div>
            <span>When {timePeriod}</span>
          </div>
        </div>
        <Select
          variant="outlined"
          placeholder="When"
          style={{ marginTop: '-4px', height: '32px', fontSize: '12px' }}
          value={timePeriod}
          onChange={handleTimePeriodChange}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="yesterday">Yesterday</MenuItem>
          <MenuItem value="last 7 days">Last 7 Days</MenuItem>
          <MenuItem value="last 30 days">Last 30 Days</MenuItem>
          <MenuItem value="last 6 months">Last 6 Months</MenuItem>
          <MenuItem value="last 12 months">Last 12 Months</MenuItem>
          <MenuItem value="ytd">Year To Date</MenuItem>
          <MenuItem value="Custom">Custom</MenuItem>
          <MenuItem
            value="preset"
            style={{ display: 'none' }}
          >{`${formattedStartDate} to ${formattedEndDate}`}</MenuItem>
        </Select>
        {timePeriod === 'Custom' && (
          <>
            <div style={{ margin: '-7px 0 24px' }} />
            <Grid container>
              <Grid item>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                />
              </Grid>
              <Grid item style={{ marginLeft: 8 }}>
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{ shrink: true }}
                  sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    </FormControl>
  );
};

export default DateSelectFilter;
