// databridge/adapters/pages/AdaptersPage.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from '../../../utils/axios.utils';
import { DataBridgeAdapter, AdaptersResponse } from '../../models/dataBridgeModels';

export default function AdaptersPage() {
  const [adapters, setAdapters] = useState<DataBridgeAdapter[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchAdapters = () => {
    setLoading(true);
    setError('');
    axios
      .get<AdaptersResponse>('api/databridge_adapters/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        setAdapters(res.data.results || []);
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to fetch DataBridge Adapters');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAdapters();
  }, []);

  return (
    <Box mt={2}>
      <Typography variant="h5" mb={2}>
        DataBridge Adapters
      </Typography>

      {loading && <Typography>Loading...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && adapters.length > 0 && (
        <Box>
          {adapters.map((adapter) => (
            <Accordion key={adapter.databridge_adapter_id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  {/* Left side: Name + optional Description */}
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="subtitle1">{adapter.name}</Typography>
                    {adapter.description && (
                      <Typography variant="body2" color="text.secondary">
                        {adapter.description}
                      </Typography>
                    )}
                  </Box>

                  {/* Right side: Enabled, integration count, machine count */}
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="body2" color="text.secondary">
                      {adapter.enabled ? 'Enabled' : 'Disabled'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Integrations: {adapter.integration_count}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Machines: {adapter.machine_count}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Key Definitions:
                </Typography>
                {adapter.key_definitions && adapter.key_definitions.length > 0 ? (
                  <Paper elevation={0} sx={{ p: 1 }}>
                    <List dense>
                      {adapter.key_definitions.map((def) => (
                        <ListItem key={def.databridge_adapter_key_definition_id}>
                          <ListItemText
                            primary={def.name}
                            secondary={
                              <>
                                <div>
                                  Key: {def.key} | ID: {def.databridge_adapter_key_definition_id}
                                </div>
                                <div>Default Value: {def.default_value}</div>
                                <div>Data Type: {def.data_type}</div>
                                {def.description && <div>{def.description}</div>}
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                ) : (
                  <Typography>(No key definitions)</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
}
