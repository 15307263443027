// src/devices/components/DeviceSearchDialog.tsx

import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import { DeviceResult } from '../../model';

interface DeviceSearchDialogProps {
  open: boolean;
  onClose: () => void;
  searchTerm: string; // from parent
  setSearchTerm: (value: string) => void; // from parent
  visibleDevices: DeviceResult[]; // from parent - already fully filtered
  handleSelectDevice: (device: DeviceResult) => void;
}

export default function DeviceSearchDialog({
  open,
  onClose,
  searchTerm,
  setSearchTerm,
  visibleDevices,
  handleSelectDevice,
}: DeviceSearchDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Search Devices</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search by serial, city, state, product, etc."
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {visibleDevices.length > 0 ? (
          <List dense sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
            {visibleDevices.map((dev) => (
              <ListItem
                key={dev.edgelink_device_id}
                button
                onClick={() => {
                  handleSelectDevice(dev);
                  onClose();
                }}
              >
                <DevicesIcon sx={{ mr: 1, color: 'text.secondary' }} />
                <ListItemText
                  primary={`Serial: ${dev.device_serial_number} | ${dev.microcontroller_serial || ''}`}
                  secondary={
                    <>
                      <Typography variant="body2" component="span">
                        {dev.city ? `${dev.city}, ${dev.state || ''}` : `Lat: ${dev.gps_lat}, Lng: ${dev.gps_long}`}
                      </Typography>
                      <br />
                      <Typography variant="body2" component="span">
                        {dev.product_profile?.product_name || 'No product'} | Battery: {dev.battery_life_level || 'N/A'}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" sx={{ mt: 2 }}>
            No devices match the current filters.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}
