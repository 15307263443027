import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { MachineCertificate } from '../model';

interface MachineCertificatesContextInterface {
  fetchMachineCertificates?: (page?: number, perPage?: number) => Promise<void>;
  machineCertificates?: MachineCertificate[];
  machineCertificatesLoading?: boolean;

  fetchMachineCertificate?: (machineCertificateId: string) => Promise<void>;
  machineCertificate?: MachineCertificate;
  machineCertificateLoading?: boolean;

  approveMachineCertificate?: (machineCertificateId: string) => Promise<void>;
  rejectMachineCertificate?: (machineCertificateId: string) => Promise<void>;
  machineCertificateSubmitting?: boolean;
  formErrors?: any;
}

const MachineCertificatesContext = React.createContext<MachineCertificatesContextInterface>({});

const MachineCertificatesProvider = ({ children }) => {
  const history = useHistory();

  const [machineCertificates, setMachineCertificates] = React.useState<MachineCertificate[]>([]);
  const [machineCertificatesLoading, setMachineCertificatesLoading] = React.useState<boolean>(false);

  const [machineCertificate, setMachineCertificate] = React.useState<MachineCertificate | null>(null);
  const [machineCertificateLoading, setMachineCertificateLoading] = React.useState<boolean>(false);
  const [machineCertificateSubmitting, setMachineCertificateSubmitting] = React.useState<boolean>(false);

  const [formErrors, setFormErrors] = React.useState<any>({});

  const fetchMachineCertificates = async (page = 1, perPage = 10) => {
    setMachineCertificatesLoading(true);

    try {
      const response = await axios.get<any, any>(`api/admin/machine_certificates/?page=${page}&per_page=${perPage}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      if (response.data && response.data.results) {
        setMachineCertificates(response.data.results);
      }
    } catch (error) {
      console.error('Error fetching machine certificates:', error);
    }

    setMachineCertificatesLoading(false);
  };

  const approveMachineCertificate = async (machineCertificateId: string) => {
    setMachineCertificateSubmitting(true);

    try {
      const response = await axios.post<any, any>(
        `api/admin/machine_certificates/${machineCertificateId}/approve/`,
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      );

      if (response.data && response.data.success) {
        // Refresh the machine certificate data
        setFormErrors({});
      } else {
        setFormErrors(response.data);
      }
    } catch (error) {
      console.error('Error approving machine certificate:', error);
    }

    setMachineCertificateSubmitting(false);
  };

  const rejectMachineCertificate = async (machineCertificateId: string) => {
    setMachineCertificateSubmitting(true);

    try {
      const response = await axios.post<any, any>(
        `api/admin/machine_certificates/${machineCertificateId}/reject/`,
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      );

      if (response.data && response.data.success) {
        // Refresh the machine certificate data
        setFormErrors({});
      } else {
        setFormErrors(response.data);
      }
    } catch (error) {
      console.error('Error rejecting machine certificate:', error);
    }

    setMachineCertificateSubmitting(false);
  };

  return (
    <MachineCertificatesContext.Provider
      value={{
        fetchMachineCertificates,
        machineCertificates,
        machineCertificatesLoading,

        approveMachineCertificate,
        rejectMachineCertificate,
        machineCertificateSubmitting,
        formErrors,
      }}
    >
      {children}
    </MachineCertificatesContext.Provider>
  );
};

export { MachineCertificatesProvider, MachineCertificatesContext };
