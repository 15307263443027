/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { DriversAutoBulkContext } from '../contexts/DriversAutoBulkContext';
import { DriverAutoBulk } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import DriverAutoBulkFilterBar from '../components/DriversAutoBulkFilterBar';
import DriversAutoBulkFormDialog from '../components/DriversAutoBulkFormDialog';

const DriversAutoBulkList: React.FunctionComponent = () => {
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const [isFormDialogOpen, setIsFormDialogOpen] = React.useState(false);
  const [selectedDriver, setSelectedDriver] = React.useState<any>({});

  const history = useHistory();
  const location = useLocation();
  const { machineSerial, driverId } = useParams<{ machineSerial?: string; driverId?: string }>();

  const { fetchDriversAutoBulk, drivers, activeMachine, setActiveMachine, fetchDriverOptions } =
    React.useContext(DriversAutoBulkContext);
  const { fetchAutoBulkMachines, autoBulkMachines, machinesLoading } = React.useContext(MachinesContext);

  React.useEffect(() => {
    const getMachines = async () => {
      if (!initialDataLoaded) {
        setDataLoaded(true);
        fetchAutoBulkMachines();
      }
    };

    getMachines();
  }, [initialDataLoaded, setDataLoaded, fetchDriversAutoBulk]);

  // Track if we've processed machine selection to avoid infinite loops
  const hasProcessedMachineRef = React.useRef(false);

  // Handle machine selection - prioritize URL param, then fall back to localStorage
  React.useEffect(() => {
    // Skip if we've already run this effect or if we're still loading machines
    if (hasProcessedMachineRef.current || !autoBulkMachines?.length || machinesLoading) return;

    // Mark as processed so we don't run again
    hasProcessedMachineRef.current = true;

    // Try to get machine from localStorage
    const storedMachineSerial = localStorage.getItem('driversMachineSerial');

    if (machineSerial) {
      // If URL has a machine serial, check if it exists in the available machines
      const machine = autoBulkMachines.find((m) => m.value === machineSerial);
      if (machine) {
        // If the machine exists, set it as active, update localStorage, and fetch its data
        setActiveMachine(machine);
        localStorage.setItem('driversMachineSerial', machineSerial);
        fetchDriverOptions(machineSerial);
        // Refresh drivers data for this machine
        fetchDriversAutoBulk(machineSerial, ''); // Pass empty string as genericFilter
      } else if (storedMachineSerial) {
        // Machine in URL doesn't exist, fall back to localStorage if available
        const storedMachine = autoBulkMachines.find((m) => m.value === storedMachineSerial);
        if (storedMachine) {
          setActiveMachine(storedMachine);
          fetchDriverOptions(storedMachineSerial);
          fetchDriversAutoBulk(storedMachineSerial, '');
          // Update URL to match localStorage machine
          history.replace(`/autobulk/machines/${storedMachineSerial}/drivers`);
        }
      }
    } else if (storedMachineSerial) {
      // No machine in URL, check localStorage
      const storedMachine = autoBulkMachines.find((m) => m.value === storedMachineSerial);
      if (storedMachine) {
        setActiveMachine(storedMachine);
        fetchDriverOptions(storedMachineSerial);
        fetchDriversAutoBulk(storedMachineSerial, '');
        // Update URL to match localStorage machine
        history.replace(`/autobulk/machines/${storedMachineSerial}/drivers`);
      }
    }
  }, [machineSerial, autoBulkMachines, machinesLoading]);

  // Track previous active machine to prevent unnecessary fetches
  const prevMachineRef = React.useRef<string | undefined>();

  // When active machine changes (via dropdown selection)
  React.useEffect(() => {
    // Skip invalid machine values or no change cases
    if (!activeMachine?.value || activeMachine.value === 'Select A Machine') return;
    if (prevMachineRef.current === activeMachine.value) return;

    // Update ref to current value
    prevMachineRef.current = activeMachine.value;

    // Save selected machine to localStorage
    localStorage.setItem('driversMachineSerial', activeMachine.value);

    // Fetch driver options for the selected machine
    fetchDriverOptions(activeMachine.value);

    // Only update URL if the change didn't come from URL parameter
    // This prevents redirect loops and preserves URL as source of truth
    if (!machineSerial || machineSerial !== activeMachine.value) {
      history.replace(`/autobulk/machines/${activeMachine.value}/drivers`);
    }
  }, [activeMachine?.value]);

  // Handle URL-based driver ID after drivers are loaded
  React.useEffect(() => {
    if (driverId && drivers?.length > 0) {
      const driver = drivers.find((d) => d.driver_id === driverId);
      if (driver) {
        setSelectedDriver(driver);
        setIsFormDialogOpen(true);
      }
    }
  }, [driverId, drivers]);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Driver Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <DriverAutoBulkFilterBar machineChoices={autoBulkMachines} machineChoicesLoading={machinesLoading} />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Driver List
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  activeMachine?.value === 'Select A Machine' ||
                  activeMachine?.value === undefined ||
                  activeMachine?.online_status === 'offline'
                }
                onClick={() => {
                  setSelectedDriver({});
                  setIsFormDialogOpen(true);
                }}
              >
                Create Driver
              </Button>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={drivers}
                defaultSortField=""
                defaultSortAsc
                striped
                onRowClicked={(row: DriverAutoBulk) => {
                  setSelectedDriver(row);
                  setIsFormDialogOpen(true);
                  // Update URL with driver ID
                  history.replace(`/autobulk/machines/${activeMachine?.value}/drivers/${row.driver_id}`);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
      <DriversAutoBulkFormDialog
        open={isFormDialogOpen}
        onClose={() => {
          setIsFormDialogOpen(false);
          // Reset URL to just show machine when dialog is closed
          history.replace(`/autobulk/machines/${activeMachine?.value}/drivers`);
        }}
        driver={selectedDriver}
      />
    </>
  );
};

export default DriversAutoBulkList;
