import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../utils/env';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';

interface MachineDeploymentContextInterface {
  fetchMachineDeployments?: () => Promise<void>;
  fetchMachineDeploymentProgressDetails?: (machineSerial: string, pid: string) => Promise<void>;
  fetchMachineDeploymentOptions?: () => Promise<void>;
  createMachineDelpoyment?: (deployments: any, onSuccess?: () => void) => Promise<void>;
  sendMachineDelpoyments?: (machine: any) => Promise<void>;
  deleteMachineDelpoyment?: (deployments: any, onSuccess?: () => void) => Promise<void>;
  machineDeploymentsDeleting?: boolean;

  machineDeployment?: any[];

  machineDeployments?: any;
  machineDeploymentsLoading?: boolean;

  machineDeploymentsProgressDetails?: any;
  machineDeploymentsProgressDetailsLoading?: boolean;

  setShowResponsePage?: any;

  jsonResponse?: any;

  machineDeploymentOptions?: any;
  machineDeploymentOptionsLoading?: boolean;
  machineDeploymentsSubmitting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
}

const MachineDeploymentContext = React.createContext<MachineDeploymentContextInterface>({});

const MachineDeploymentContextConsumer = MachineDeploymentContext.Consumer;
const MachineDeploymentContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { showSuccessSnackBar, showErrorSnackBar } = React.useContext(SnackBarContext);

  const [machineDeployment, setmachineDeployment] = React.useState([]);
  const [machineDeployments, setMachineDeployments] = React.useState([]);
  const [machineDeploymentsLoading, setMachineDeploymentsLoading] = React.useState(true);
  const [machineDeploymentOptions, setMachineDeploymentOptions] = React.useState({});
  const [machineDeploymentOptionsLoading, setMachineDeploymentOptionsLoading] = React.useState(true);
  const [machineDeploymentsSubmitting, setMachineDeploymentsSubmitting] = React.useState(true);
  const [machineDeploymentsProgressDetails, setMachineDeploymentsProgressDetails] = React.useState({});
  const [machineDeploymentsProgressDetailsLoading, setMachineDeploymentsProgressDetailsLoading] = React.useState(false);
  const [machineDeploymentsDeleting, setMachineDeploymentsDeleting] = React.useState(true);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [showResponsePage, setShowResponsePage] = React.useState<boolean>(false);
  const [jsonResponse, setJsonResponse] = React.useState([]);

  const fetchMachineDeployments = async () => {
    setMachineDeploymentsLoading(true);

    axios
      .get<string, any>(`api/admin/machine_deployments/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineDeployments(response.data.results);
        setMachineDeploymentsLoading(false);
      });
  };

  const fetchMachineDeploymentOptions = async () => {
    setMachineDeploymentOptionsLoading(true);

    axios
      .get<string, any>(`api/admin/machine_deployment_options/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineDeploymentOptions(response.data.results);
        setMachineDeploymentOptionsLoading(false);
      });
  };

  const createMachineDelpoyment = async (deployments: any, onSuccess?: () => void) => {
    setMachineDeploymentsSubmitting(true);
    showSuccessSnackBar('Creating deployments');

    if (!showResponsePage) {
      setShowResponsePage(true);
      history.push(`/admin/machine_deployments/temporary_response`);
    }

    axios
      .post<string, any>(`api/admin/machine_deployments/`, deployments, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineDeploymentsSubmitting(false);
        setJsonResponse(response.data.results);
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.error('Error creating machine deployment:', error);
        showErrorSnackBar('Error creating deployments');
        setMachineDeploymentsSubmitting(false);
      });
  };

  const deleteMachineDelpoyment = async (deployment_id: any, onSuccess?: () => void) => {
    setMachineDeploymentsDeleting(true);
    showSuccessSnackBar('Deleting deployment');

    axios
      .delete<string, any>(`api/admin/machine_deployments/${deployment_id}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setMachineDeploymentsDeleting(false);
        showSuccessSnackBar('Successfully deleted deployment');

        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.error('Error creating machine deployment:', error);
        showErrorSnackBar('Error deleting deployments');
        setMachineDeploymentsDeleting(false);
      });
  };

  // Temporary trigger endpoint that triggers the background worker that sends the sqs messages to the machines
  const sendMachineDelpoyments = async (machine: any) => {
    axios
      .post<string, any>(
        `api/admin/trigger_deployments/`,
        { ...machine },
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )
      .then((response) => {})
      .catch((error) => {
        console.error('Error creating machine:', error);
      });
  };

  const fetchMachineDeploymentProgressDetails = async (machineSerial: string, pid: string) => {
    setMachineDeploymentsProgressDetailsLoading(true);
    let retryCount = 0;

    axios
      .get<string, any>(`api/admin/machine_deployments/progress_detail/?serial-number=${machineSerial}&pid=${pid}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/admin/machine_deployments/progress_detail/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setMachineDeploymentsProgressDetailsLoading(false);
              setMachineDeploymentsProgressDetails(response.data.results);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setMachineDeploymentsProgressDetailsLoading(false);
                setMachineDeploymentsProgressDetails([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  return (
    <MachineDeploymentContext.Provider
      value={{
        fetchMachineDeployments,
        fetchMachineDeploymentOptions,
        createMachineDelpoyment,
        sendMachineDelpoyments,
        deleteMachineDelpoyment,
        machineDeploymentsDeleting,
        machineDeployment,

        fetchMachineDeploymentProgressDetails,

        machineDeployments,
        machineDeploymentsLoading,

        machineDeploymentsProgressDetails,
        machineDeploymentsProgressDetailsLoading,

        setShowResponsePage,

        jsonResponse,

        machineDeploymentOptions,
        machineDeploymentOptionsLoading,

        machineDeploymentsSubmitting,

        creatingPage,
        setCreatingPage,
      }}
    >
      {children}
    </MachineDeploymentContext.Provider>
  );
};

export { MachineDeploymentContextProvider, MachineDeploymentContextConsumer, MachineDeploymentContext };
