/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@mui/material';
import { Importer, ImporterField } from '../../../modules/csvImporter';
import 'react-csv-importer/dist/index.css';
import { ProductsAutoBulkValidationsContext } from '../contexts/ProductsAutoBulkValidationsContext';
import { ProductsAutoBulkImportContext } from '../contexts/ProductsAutoBulkImportContext';
import ProductImportStepper from './ProductsImportStepper';
import { sanitizeInputs } from '../../../modules/shared/utils/sanitizeInputs';

interface ProductsAutoBulkcsvImportProps {
  apiPartner?: string;
  machineSerialNumber: any;
}

const ProductsAutoBulkcsvImport: React.FunctionComponent<ProductsAutoBulkcsvImportProps> = (props) => {
  const { apiPartner, machineSerialNumber } = props;

  const [activeStep, setActiveStep] = React.useState(0);

  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [triggerUnAcceptFile, setTriggerUnAcceptFile] = React.useState<number>(0);

  const { validatecsvImportRow } = React.useContext(ProductsAutoBulkValidationsContext);
  const { setPassedRecords, passedRecords, setFailedRecords, sendProductsBulkAutoBulk, failedRecords } =
    React.useContext(ProductsAutoBulkImportContext);

  const handleEnableNext = (value) => {
    setNextButtonDisabled(value);
  };

  const handleSubmit = () => {
    sendProductsBulkAutoBulk(machineSerialNumber, passedRecords, apiPartner);
  };

  const triggerNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUnAcceptFile = () => {
    setTriggerUnAcceptFile((triggerUnAcceptFile) => triggerUnAcceptFile + 1);
  };

  const triggerBackStep = () => {
    setPassedRecords([]);
    setFailedRecords([]);
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if (activeStep === 1) {
      handleUnAcceptFile();
    }
  };

  const handleValidateRows = (rows) => {
    const tempPassdRecords = [];
    const tempFailedRecords = [];
    const compoundIdCounts = {};

    const tempSanitizedRows = sanitizeInputs(rows);

    tempSanitizedRows.forEach((row) => {
      if (row.active == null || row.active === '') {
        row.active = '1';
      }
    });

    tempSanitizedRows.forEach((row) => {
      const compoundId =
        (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');
      compoundIdCounts[compoundId] = (compoundIdCounts[compoundId] || 0) + 1;
    });

    tempSanitizedRows.forEach((row, index) => {
      const rowValidation = validatecsvImportRow(row);

      const validValues = ['0', '1', 'true', 'false'];

      if (!validValues.includes(row.active.toLowerCase())) {
        rowValidation.error = true;
        rowValidation.errorMessage = '"Active" must be "0", "1", "true", or "false".';
      }

      if (apiPartner) {
        const compoundId =
          (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');

        if (row.partner_record_id_1 === null || row.partner_record_id_1 === '') {
          rowValidation.error = true;
          rowValidation.errorMessage = 'partner_record_id_1 cannot be blank';
        } else if (compoundIdCounts[compoundId] > 1) {
          rowValidation.error = true;
          rowValidation.errorMessage = 'Duplicate partner id found';
        }
      }

      if (rowValidation.error) {
        tempFailedRecords.push({ ...row, errorMessage: rowValidation.errorMessage });
      } else {
        tempPassdRecords.push(row);
      }
    });
    setPassedRecords((prevPassedRecords) => [...prevPassedRecords, ...tempPassdRecords]);
    setFailedRecords((prevFailedRecords) => [...prevFailedRecords, ...tempFailedRecords]);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item marginTop={1} xs={12}>
          <Importer
            activeStep={activeStep}
            enableNext={handleEnableNext}
            dataHandler={async (rows, { startIndex }) => {
              handleValidateRows(rows);
            }}
            passedRecords={passedRecords}
            failedRecords={failedRecords}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            stepperComponent={
              <ProductImportStepper
                triggerNextStepProp={triggerNextStep}
                triggerBackStepProp={triggerBackStep}
                activeStep={activeStep}
                nextDisabled={nextButtonDisabled}
              />
            }
            columnsTitle="AutoBulk Product Columns"
            unAcceptFile={triggerUnAcceptFile}
            sendToMachine={handleSubmit}
            apiPartner={apiPartner}
          >
            <ImporterField name="product_name" label="product_name" />
            <ImporterField name="product_description" label="product_description" optional />
            <ImporterField name="product_shipment" label="product_shipment" optional />
            <ImporterField name="product_type" label="product_type" optional description="Default: Liquid" />
            <ImporterField
              name="product_inventory_unit"
              label="product_inventory_unit"
              optional
              description="Default: gallon"
            />
            <ImporterField name="product_inventory" label="product_inventory" optional description="Default: 0" />
            <ImporterField
              name="product_density_type"
              label="product_density_type"
              optional
              description="Default: lb/gal"
            />
            <ImporterField name="product_density" label="product_density" optional description="Default: 8.345" />
            <ImporterField name="product_epa" label="product_epa" optional />
            <ImporterField name="product_msds" label="product_msds" optional />
            <ImporterField name="product_comment" label="product_comment" optional />
            <ImporterField name="product_priority" label="product_priority" optional description="Default: 999" />
            <ImporterField name="isolated" label="isolated" optional description="Default: False" />
            <ImporterField name="isolated_load_last" label="isolated_load_last" optional description="Default: False" />
            <ImporterField name="active" label="active" optional description="Default: True" />
            {apiPartner && (
              <>
                {/* <ImporterField name="api_partner" label="api_partner" optional /> */}
                <ImporterField name="partner_record_id_1" label="partner_record_id_1" />
                <ImporterField name="partner_record_id_2" label="partner_record_id_2" optional />
                <ImporterField name="partner_record_id_3" label="partner_record_id_3" optional />
              </>
            )}
          </Importer>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductsAutoBulkcsvImport;
