import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../../utils/axios.utils';

interface Seed {
  autotreat_mfr_seed_cloud_id: number;
  global_mfr_id: string;
  gtin: string;
  seed_variety_name_mfr: string;
  seed_variety_name_short: string;
  seed_variety_desc: string;
  package: string;
  crop_id: number;
  crop_name?: string;
  locked: boolean;
  active: boolean;
  obsolete: boolean;
  date_and_time_created: string;
}

interface SeedsContextType {
  seeds: Seed[];
  seedsLoading: boolean;
  fetchSeeds: () => void;
}

const SeedsContext = createContext<SeedsContextType | undefined>(undefined);

export const MfrSeedsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [seeds, setSeeds] = useState<Seed[]>([]);
  const [seedsLoading, setSeedsLoading] = useState<boolean>(false);

  const fetchSeeds = async () => {
    setSeedsLoading(true);
    try {
      const response = await axios.get(`api/admin/autotreat_mfr/mfr_seeds/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });
      setSeeds(response.data);
    } catch (error) {
      console.error('Error fetching seeds:', error);
    }
    setSeedsLoading(false);
  };

  return <SeedsContext.Provider value={{ seeds, seedsLoading, fetchSeeds }}>{children}</SeedsContext.Provider>;
};

export const useMfrSeedsContext = () => {
  const context = useContext(SeedsContext);
  if (!context) {
    throw new Error('useMfrSeedsContext must be used within a SeedsProvider');
  }
  return context;
};

export {};
