import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  LinearProgress,
  Alert,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import axios from '../../../utils/axios.utils';
import { CROP_DEFAULTS } from '../../sharedConstants/CropDefaults';
import { ProductProfile } from '../../model';

interface DeviceProductFormContentProps {
  deviceSerial: string;
  deviceTypeId?: number; // e.g., 1 => Liquid, 2 => Seed
  existingProfile?: ProductProfile;
  /** Called after a successful save so the parent can refresh or close the dialog, etc. */
  onSaved?: () => void;
  /** If you want parent to handle success messages or error */
  onChangeSuccessMessage?: (msg: string | null) => void;
  onChangeError?: (msg: string | null) => void;
  /** Called when user clicks "Cancel" */
  onClose: () => void;
}

const DeviceProductFormContent: React.FC<DeviceProductFormContentProps> = ({
  deviceSerial,
  deviceTypeId,
  existingProfile,
  onSaved,
  onChangeSuccessMessage,
  onChangeError,
  onClose,
}) => {
  // Loading/Success/Error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  // Basic fields
  const [productName, setProductName] = useState('');
  const [lotNumber, setLotNumber] = useState('');
  const [batchNumber, setBatchNumber] = useState('');
  const [crop, setCrop] = useState('');

  // Liquid fields
  const [safetySheetUrl, setSafetySheetUrl] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  // Seed fields
  const [seedUnits, setSeedUnits] = useState('');
  const [seedUnitSize, setSeedUnitSize] = useState('');
  const [seedSize, setSeedSize] = useState('');
  const [showSeedSizeField, setShowSeedSizeField] = useState(false);
  const [seedTreatment, setSeedTreatment] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');

  // Read-only info
  const [lastModifiedBy, setUpdatedBy] = useState('');
  const [lastModifiedAt, setUpdatedAt] = useState('');
  const [loadedAt, setLoadedAt] = useState('');
  const [emptiedAt, setEmptiedAt] = useState('');

  // Determine if this is an edit or brand-new
  const isSeedDevice = deviceTypeId === 2;
  const isLiquidDevice = deviceTypeId === 1;
  const isEdit = Boolean(existingProfile?.edgelink_product_profile_id);

  // -------------------------------------------
  // Helper to clear the form fields
  // -------------------------------------------
  const clearForm = () => {
    setProductName('');
    setLotNumber('');
    setBatchNumber('');
    setCrop('');
    setSafetySheetUrl('');
    setLabelUrl('');
    setSeedUnits('');
    setSeedUnitSize('');
    setSeedSize('');
    setSeedTreatment('');
    setAdditionalNotes('');
    setShowSeedSizeField(false);

    setUpdatedBy('');
    setUpdatedAt('');
    setLoadedAt('');
    setEmptiedAt('');

    setSuccessMsg(null);
    setError(null);
  };

  // -------------------------------------------
  // Initialize form from existingProfile OR blank
  // -------------------------------------------
  useEffect(() => {
    if (existingProfile) {
      // Fill with existing data
      setProductName(existingProfile.product_name || '');
      setLotNumber(existingProfile.lot_number || '');
      setBatchNumber(existingProfile.batch_number || '');
      setCrop(existingProfile.crop || '');
      setSafetySheetUrl(existingProfile.safety_data_sheet_attachment || '');
      setLabelUrl(existingProfile.chemical_label_attachment || '');
      setSeedUnits(existingProfile.seed_units || '');
      setSeedUnitSize(existingProfile.seed_unit_size || '');
      setSeedSize(existingProfile.seed_size || '');
      setSeedTreatment(existingProfile.seed_treatment_package || '');
      setAdditionalNotes(existingProfile.additional_notes || '');

      setUpdatedBy(existingProfile.updated_by || '');
      setUpdatedAt(existingProfile.updated_at || '');
      setLoadedAt(existingProfile.loaded_at || '');
      setEmptiedAt(existingProfile.emptied_at || '');

      // Show/hide seed size field
      if (existingProfile.crop && CROP_DEFAULTS[existingProfile.crop]?.show_seed_size) {
        setShowSeedSizeField(true);
      } else {
        setShowSeedSizeField(false);
      }
    } else {
      // Brand-new product
      clearForm();
    }
  }, [existingProfile]);

  // -------------------------------------------
  // Handle Crop change (apply seed defaults if needed)
  // -------------------------------------------
  const handleCropChange = (newCrop: string) => {
    setCrop(newCrop);
    if (isSeedDevice) {
      const defaults = CROP_DEFAULTS[newCrop];
      if (defaults) {
        setSeedUnitSize(String(defaults.seed_unit_size ?? ''));
        if (defaults.seed_size !== null) {
          setSeedSize(String(defaults.seed_size));
        } else {
          setSeedSize('');
        }
        setShowSeedSizeField(Boolean(defaults.show_seed_size));
      } else {
        setSeedUnitSize('');
        setSeedSize('');
        setShowSeedSizeField(false);
      }
    }
  };

  // -------------------------------------------
  // Handle save (with confirmation)
  // -------------------------------------------
  const handleSave = async () => {
    // Different confirm messages for edit vs. create
    const confirmMessage = isEdit
      ? 'Changing these values will affect historical records.\n\nIf you are or have changed the product in this box, DO NOT change this product information but rather "Load Another Product." Updating should only be done if you are fixing an original entry error.\n\nAre you sure you want to proceed?'
      : 'Are you sure you want to create a new product profile for this device?';

    const userConfirmed = window.confirm(confirmMessage);
    if (!userConfirmed) return;

    setLoading(true);
    setError(null);
    setSuccessMsg(null);

    onChangeError?.(null);
    onChangeSuccessMessage?.(null);

    try {
      const payload: Partial<ProductProfile> = {
        product_name: productName,
        lot_number: lotNumber,
        batch_number: batchNumber,
        crop,
      };

      // Liquid fields
      if (isLiquidDevice) {
        payload.safety_data_sheet_attachment = safetySheetUrl;
        payload.chemical_label_attachment = labelUrl;
      }

      // Seed fields
      if (isSeedDevice) {
        payload.seed_units = seedUnits || null;
        payload.seed_unit_size = seedUnitSize || null;
        payload.seed_size = seedSize || null;
        payload.seed_treatment_package = seedTreatment || null;
        payload.additional_notes = additionalNotes || null;
      }

      // Construct the endpoint (POST vs PUT)
      let url = `/api/user/edgelink/devices/${deviceSerial}/product_profile/`;
      let method: 'post' | 'put' = 'post';
      if (isEdit && existingProfile?.edgelink_product_profile_id) {
        url += `${existingProfile.edgelink_product_profile_id}/`;
        method = 'put';
      }

      const resp = await axios[method](url, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      });

      if (resp.data) {
        const msg = isEdit ? 'Product profile updated successfully!' : 'Product profile created successfully!';
        setSuccessMsg(msg);
        onChangeSuccessMessage?.(msg);
        onSaved?.();
      } else {
        const msg = 'Failed to save product profile. Please try again.';
        setError(msg);
        onChangeError?.(msg);
      }
    } catch (err) {
      console.error(err);
      const msg = 'Server error. Could not save product profile.';
      setError(msg);
      onChangeError?.(msg);
    } finally {
      setLoading(false);
    }
  };

  // -------------------------------------------
  // Render
  // -------------------------------------------
  return (
    <Box>
      {loading && <LinearProgress />}
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
      {successMsg && (
        <Alert severity="success" sx={{ my: 2 }}>
          {successMsg}
        </Alert>
      )}

      {/* Show the Important Notice only if we are editing */}
      {isEdit && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Important Notice
          </Typography>
          <Typography variant="body2">
            Changing these values will affect any historical records regarding this product profile. If you are or have
            changed the product in this box, <strong>DO NOT update</strong> but rather "Load Another Product". Updating
            should only be done if you are fixing an original entry error.
          </Typography>
        </Alert>
      )}

      <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          {isEdit ? 'Edit Product Profile' : 'Load New Product'}
        </Typography>

        {/* Main Fields */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Product Name"
              variant="outlined"
              fullWidth
              required
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Lot Number"
              variant="outlined"
              fullWidth
              value={lotNumber}
              onChange={(e) => setLotNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Batch Number"
              variant="outlined"
              fullWidth
              value={batchNumber}
              onChange={(e) => setBatchNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Crop</InputLabel>
              <Select label="Crop" value={crop} onChange={(e) => handleCropChange(e.target.value as string)}>
                <MenuItem value="">-- Select Crop --</MenuItem>
                {Object.keys(CROP_DEFAULTS).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Liquid fields (always show if deviceTypeId=1) */}
          {isLiquidDevice && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Safety Data Sheet URL"
                  variant="outlined"
                  fullWidth
                  value={safetySheetUrl}
                  onChange={(e) => setSafetySheetUrl(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Product Label URL"
                  variant="outlined"
                  fullWidth
                  value={labelUrl}
                  onChange={(e) => setLabelUrl(e.target.value)}
                />
              </Grid>
            </>
          )}

          {/* Seed fields (always show if deviceTypeId=2) */}
          {isSeedDevice && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Total Seed Units"
                  variant="outlined"
                  fullWidth
                  value={seedUnits}
                  onChange={(e) => setSeedUnits(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Seed Unit Size"
                  variant="outlined"
                  fullWidth
                  value={seedUnitSize}
                  onChange={(e) => setSeedUnitSize(e.target.value)}
                />
              </Grid>

              {showSeedSizeField ? (
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Seed Size"
                    variant="outlined"
                    fullWidth
                    value={seedSize}
                    onChange={(e) => setSeedSize(e.target.value)}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={4}>
                  <Box />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="Seed Treatment"
                  variant="outlined"
                  fullWidth
                  value={seedTreatment}
                  onChange={(e) => setSeedTreatment(e.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              label="Additional Notes"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* Save/Cancel Buttons */}
        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default DeviceProductFormContent;
