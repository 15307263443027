// databridge/integrations/pages/IntegrationsPage.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import axios from '../../../utils/axios.utils';
import { useHistory } from 'react-router-dom';

import { IntegrationListItem, IntegrationsResponse } from '../../models/dataBridgeModels';

import IntegrationForm from '../forms/IntegrationForm';

interface IntegrationsPageProps {
  formMode?: 'new' | 'edit' | null;
  integrationId?: number | null;
}

export default function IntegrationsPage({ formMode, integrationId }: IntegrationsPageProps) {
  const history = useHistory();
  const [integrations, setIntegrations] = useState<IntegrationListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // edit
  const [editModalOpen, setEditModalOpen] = useState(formMode === 'edit' || formMode === 'new');
  const [editIntegrationId, setEditIntegrationId] = useState<number | null>(formMode === 'edit' ? integrationId : null);
  const [isCreateMode, setIsCreateMode] = useState(formMode === 'new');

  const fetchIntegrations = () => {
    setLoading(true);
    setError('');
    axios
      .get<IntegrationsResponse>('api/databridge_integrations/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((res) => {
        // Sort integrations by ID in ascending order
        const sortedIntegrations = [...(res.data.results || [])].sort(
          (a, b) => a.databridge_integration_id - b.databridge_integration_id,
        );
        setIntegrations(sortedIntegrations);
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to load DataBridge Integrations');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  // Handle URL-based form opening
  useEffect(() => {
    if (formMode === 'new') {
      setIsCreateMode(true);
      setEditIntegrationId(null);
      setEditModalOpen(true);
    } else if (formMode === 'edit' && integrationId) {
      setIsCreateMode(false);
      setEditIntegrationId(integrationId);
      setEditModalOpen(true);
    }
  }, [formMode, integrationId]);

  const handleRowClick = (id: number) => {
    handleEdit(id);
  };

  const handleEdit = (id: number, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    // Update URL without triggering any router events
    window.history.replaceState(null, '', `/admin/databridge_management/integrations/${id}`);
    setEditIntegrationId(id);
    setIsCreateMode(false);
    setEditModalOpen(true);
  };

  const handleCreateIntegration = () => {
    // Update URL without triggering any router events
    window.history.replaceState(null, '', '/admin/databridge_management/integrations/new');
    setIsCreateMode(true);
    setEditIntegrationId(null);
    setEditModalOpen(true);
  };

  const handleEditModalClose = (reloadNeeded?: boolean) => {
    // Update URL back to main integrations page without triggering any router events
    window.history.replaceState(null, '', '/admin/databridge_management/integrations');
    setEditModalOpen(false);
    setEditIntegrationId(null);
    setIsCreateMode(false);
    if (reloadNeeded) {
      fetchIntegrations();
    }
  };

  return (
    <Box mt={2}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">DataBridge Integrations</Typography>
        <Button variant="contained" onClick={handleCreateIntegration}>
          Create New
        </Button>
      </Box>

      {loading && <Typography>Loading...</Typography>}
      {error && <Typography color="error">{error}</Typography>}

      {!loading && integrations.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Enabled</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Adapter Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Partner Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Machine Count</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {integrations.map((item) => (
                <TableRow
                  key={item.databridge_integration_id}
                  hover
                  onClick={() => handleRowClick(item.databridge_integration_id)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{item.databridge_integration_id}</TableCell>
                  <TableCell>{item.enabled ? 'TRUE' : 'FALSE'}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.adapter_name}</TableCell>
                  <TableCell>{item.partner_name}</TableCell>
                  <TableCell>{item.machine_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {editModalOpen && (
        <IntegrationForm
          open={editModalOpen}
          onClose={handleEditModalClose}
          integrationId={editIntegrationId}
          isCreateMode={isCreateMode}
        />
      )}
    </Box>
  );
}
