import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Manufacturer } from '../model';

interface ManufacturersContextInterface {
  fetchManufacturers?: (page?: number, perPage?: number) => Promise<void>;
  manufacturers?: Manufacturer[];
  manufacturersLoading?: boolean;

  fetchManufacturer?: (manufacturerId: string) => Promise<void>;
  manufacturer?: Manufacturer;
  manufacturerLoading?: boolean;

  updateManufacturer?: (manufacturerId: string) => Promise<void>;
  rejectManufacturer?: (manufacturerId: string) => Promise<void>;
  manufacturerSubmitting?: boolean;
  formErrors?: any;
}

const ManufacturersContext = React.createContext<ManufacturersContextInterface>({});

const ManufacturersContextProvider = ({ children }) => {
  const history = useHistory();

  const [manufacturers, setManufacturers] = React.useState<Manufacturer[]>([]);
  const [manufacturersLoading, setManufacturersLoading] = React.useState<boolean>(false);

  const [manufacturer, setManufacturer] = React.useState<Manufacturer | null>(null);
  const [manufacturerLoading, setManufacturerLoading] = React.useState<boolean>(false);
  const [manufacturerSubmitting, setManufacturerSubmitting] = React.useState<boolean>(false);

  const [formErrors, setFormErrors] = React.useState<any>({});

  const fetchManufacturers = async (page = 1, perPage = 10) => {
    setManufacturersLoading(true);

    try {
      const response = await axios.get<any, any>(`api/admin/autotreat_mfr/mfrs/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      });

      if (response.data && response.data) {
        setManufacturers(response.data);
      }
    } catch (error) {
      console.error('Error fetching machine certificates:', error);
    }

    setManufacturersLoading(false);
  };

  const updateManufacturer = async (manufacturerId: string) => {
    setManufacturerSubmitting(true);

    try {
      const response = await axios.post<any, any>(
        `api/admin/manufacturers/${manufacturerId}/approve/`,
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      );

      if (response.data && response.data.success) {
        setFormErrors({});
      } else {
        setFormErrors(response.data);
      }
    } catch (error) {
      console.error('Error approving machine certificate:', error);
    }

    setManufacturerSubmitting(false);
  };

  return (
    <ManufacturersContext.Provider
      value={{
        fetchManufacturers,
        manufacturers,
        manufacturersLoading,

        updateManufacturer,
        manufacturerSubmitting,
        formErrors,
      }}
    >
      {children}
    </ManufacturersContext.Provider>
  );
};

export { ManufacturersContextProvider, ManufacturersContext };
