// src/devices/forms/DeviceDeliveryTicketFormContent.tsx
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, LinearProgress, Stack, TextField, Grid, Button, Alert, Divider } from '@mui/material';
import axios from '../../../utils/axios.utils';

interface Device {
  edgelink_device_id: number;
  device_serial_number: string;
}

interface DeviceDeliveryTicketFormContentProps {
  deviceSerial: string;
  onSaved?: () => void;
  onChangeError?: (msg: string | null) => void;
  onChangeSuccessMessage?: (msg: string | null) => void;
  onClose?: any;
}

const DeviceDeliveryTicketFormContent: React.FC<DeviceDeliveryTicketFormContentProps> = ({
  deviceSerial,
  onSaved,
  onChangeError,
  onChangeSuccessMessage,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<Device | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    let ignore = false;

    async function fetchDevice() {
      try {
        setLoading(true);
        const response = await axios.get<any, any>(
          `/api/user/edgelink/devices/dashboard?device_serial_number=${deviceSerial}`,
          {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
          },
        );
        if (!ignore) {
          if (response.data.success && response.data.results.length > 0) {
            setDevice(response.data.results[0]);
            setError(null);
          } else {
            setError('No device found.');
          }
        }
      } catch (err: any) {
        if (!ignore) {
          console.error(err);
          setError('Failed to load device data.');
        }
      } finally {
        if (!ignore) setLoading(false);
      }
    }

    fetchDevice();
    return () => {
      ignore = true;
    };
  }, [deviceSerial]);

  /**
   * Called by parent "onSave" in the GenericFormDialog
   */
  const handleSubmit = () => {
    // For now, just show a success message
    setSuccessMessage('Delivery ticket created!');
    onChangeSuccessMessage?.('Delivery ticket created!');
    onSaved?.();
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 1 }}>
      {loading && <LinearProgress sx={{ mb: 2 }} />}
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" sx={{ my: 2 }}>
          {successMessage}
        </Alert>
      )}

      {!loading && device && (
        <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Create Delivery Ticket
          </Typography>

          <Alert severity="info" sx={{ mb: 2 }}>
            This is a placeholder for future functionality. Fields below are read-only to show how a delivery ticket
            might look.
          </Alert>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField label="Ticket Number" variant="outlined" fullWidth disabled value="TKT-000123" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Delivered/Pickup Date" variant="outlined" fullWidth disabled value="2025-03-08" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Ship To (Name)" variant="outlined" fullWidth disabled value="John Doe" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ship To (Address)"
                variant="outlined"
                fullWidth
                disabled
                value="1234 Example St, Hometown, USA"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Contact Phone" variant="outlined" fullWidth disabled value="+1 (555) 123-4567" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Carrier" variant="outlined" fullWidth disabled value="Customer Pickup" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Signature" variant="outlined" fullWidth disabled value="John Doe" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Notes"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                disabled
                value="Placeholder notes section. Could integrate with shipping partner or internal system."
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />
          <Alert severity="info" sx={{ mb: 2 }}>
            In a future implementation, assigning a delivery ticket could also auto-set the
            <strong> tertiary company</strong> on the device to the chosen shipping address.
          </Alert>

          {/* In normal usage, the parent's "onSave" calls handleSubmit */}
          <Button variant="contained" onClick={handleSubmit} disabled sx={{ mt: 2 }}>
            Generate Ticket
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default DeviceDeliveryTicketFormContent;
